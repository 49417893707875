import apiClient from './apiService';

const schemeServices = {
  getScheme(params: any) {
    return apiClient.request({
      method: 'GET',
      params: params,
      url: '/vesting-svc/api/v1.0.0/schemes',
    });
  },

  createScheme(params: any) {
    return apiClient.request({
      method: 'POST',
      data: params,
      url: '/vesting-svc/api/v1.0.0/schemes',
    });
  },

  getTokenScheme() {
    return apiClient.request({
      method: 'GET',
      url: 'defi-pawn-crypto-service/public-api/v1.0.0/crypto-asset/vesting',
    });
  },

  changeStatusScheme(id) {
    return apiClient.request({
      method: 'PATCH',
      url: `/vesting-svc/api/v1.0.0/schemes/${id}`,
    });
  },
};

export default schemeServices;
