import { Box, styled } from '@mui/system';
import { Container, Grid, Typography } from '@mui/material';

export const WrapperDuration = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  'div:nth-child(2)': {
    minWidth: '30%',
    marginLeft: '10px',
  },

  'div>img': {
    display: 'none',
  },

  'input::placeholder': {
    color: theme.palette.grey[500],
  },
}));

export const WrapperStartDate = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: '20px',
  '.labelInput': {
    position: 'absolute',
    top: '34px',
    right: '10px',
    overflow: 'hidden',
  },

  'div>img': {
    display: 'none',
  },

  'input::placeholder': {
    color: theme.palette.grey[500],
  },
}));

export const WrapperInput = styled(Box)(({ theme }) => ({
  'input::placeholder': {
    color: theme.palette.grey[500],
  },
}));
