import apiClient from './apiService';

const auctionServices = {
  getAuction(params: any) {
    return apiClient.request({
      method: 'GET',
      params: params,
      url: '/api-svc/api/auction',
    });
  },

  postGetAuction(params: any) {
    return apiClient.request({
      method: 'POST',
      data: params,
      url: '/api-svc/api/auction',
    });
  },

  hideAuction(params: any) {
    return apiClient.request({
      method: 'POST',
      data: params,
      url: '/api-svc/api/auction/hide-nft',
    });
  },
};

export default auctionServices;
