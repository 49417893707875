import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useSlice } from '../../slice';
import { selectVesting } from '../../slice/selectors';
import { Box } from '@mui/system';
import { renderStatus, renderTime } from '../VestingListTable';
import moment from 'moment';
import {
  CircularProgress,
  LinearProgress,
  Link,
  Modal,
  Stack,
} from '@mui/material';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import { selectGlocalState } from 'app/pages/GlobalStateWrapper/slice/selectors';
import { style } from '../DialogConfirmVesting';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { FunctionFormatNum } from 'utils/formatNumber';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogActivePlan() {
  const dispatch = useDispatch();
  const { actions } = useSlice();
  const {
    isSwitchPopUpActive,
    detailDataVesting,
    receiptActive,
    isPopUpActiveSuccess,
    isPopUpActiveFailure,
    isLoadingButtonActive,
  } = useSelector(selectVesting);

  const wallet = useSelector(selectWallet).wallet;
  const { contracts } = useSelector(selectGlocalState);

  const getBalance = token => {
    if (localStorage.getItem('StoreWallet')) {
      const listMoney = JSON.parse(
        localStorage.getItem('StoreWallet') as string,
      ).money;
      const res = listMoney.filter(i => i.symbol === token)[0]?.balance;

      return res;
    }
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={isSwitchPopUpActive}
        sx={{
          '.MuiDialog-paper': {
            maxWidth: '800px !important',
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            dispatch(actions.turnOffPopupActive(false));
          }}
        >
          Activate vesting plan
        </BootstrapDialogTitle>
        <Box
          sx={{
            display: 'flex',
            width: '800px',
            padding: '0 0 0 40px',
            gap: '30px',
          }}
        >
          <Box>
            <Typography>Wallet address:</Typography>
            <Typography>Scheme:</Typography>
            <Typography>Time:</Typography>
            <Typography>Total amount:</Typography>
            <Typography>Vested mount:</Typography>
          </Box>
          <Box>
            <Typography>{detailDataVesting.walletAddress}</Typography>
            <Typography>{detailDataVesting.scheme.schemeName}</Typography>
            <Typography>
              {renderTime(
                detailDataVesting.status,
                detailDataVesting.scheme.durationType,
                detailDataVesting.scheme.durationTime,
                detailDataVesting.startedTime,
                detailDataVesting.endedTime,
              )
                ? renderTime(
                    detailDataVesting.status,
                    detailDataVesting.scheme.durationType,
                    detailDataVesting.scheme.durationTime,
                    detailDataVesting.startedTime,
                    detailDataVesting.endedTime,
                  )
                : 'null'}{' '}
              duration, from{' '}
              {moment
                .unix(detailDataVesting.startedTime / 1000)
                .format('DD/MM/YYYY')}
            </Typography>
            <Typography
              sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            >
              <img
                src={
                  require(`app/assets/${
                    detailDataVesting.scheme.cryptoAssetSymbol
                      ? detailDataVesting.scheme.cryptoAssetSymbol
                      : 'DFY'
                  }.png`).default
                }
                alt=""
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
              <FunctionFormatNum>
                {detailDataVesting.totalAmountVesting}
              </FunctionFormatNum>{' '}
              {detailDataVesting.scheme.cryptoAssetSymbol}
            </Typography>
            <Typography
              sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            >
              <img
                src={
                  require(`app/assets/${
                    detailDataVesting.scheme.cryptoAssetSymbol
                      ? detailDataVesting.scheme.cryptoAssetSymbol
                      : 'DFY'
                  }.png`).default
                }
                alt=""
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
              <FunctionFormatNum>
                {detailDataVesting.vestedAmount}
              </FunctionFormatNum>{' '}
              {detailDataVesting.scheme.cryptoAssetSymbol}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '90%',
            border: '1px solid',
            margin: '12px auto',
            padding: '0 40px 0 40px',
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 40px 0 40px',
          }}
        >
          <Typography>Required amount:</Typography>
          <Typography
            sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            <img
              src={
                require(`app/assets/${
                  detailDataVesting.scheme.cryptoAssetSymbol
                    ? detailDataVesting.scheme.cryptoAssetSymbol
                    : 'DFY'
                }.png`).default
              }
              alt=""
              style={{
                width: '20px',
                height: '20px',
              }}
            />
            <FunctionFormatNum>
              {detailDataVesting.totalAmountVesting -
                detailDataVesting.vestedAmount}
            </FunctionFormatNum>{' '}
            {detailDataVesting.scheme.cryptoAssetSymbol}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 40px 0 40px',
          }}
        >
          <Typography>
            Balance:{' '}
            <img
              src={
                require(`app/assets/${
                  detailDataVesting.scheme.cryptoAssetSymbol
                    ? detailDataVesting.scheme.cryptoAssetSymbol
                    : 'DFY'
                }.png`).default
              }
              alt=""
              style={{
                width: '20px',
                height: '20px',
              }}
            />{' '}
            <FunctionFormatNum>
              {getBalance(detailDataVesting.scheme.cryptoAssetSymbol)}
            </FunctionFormatNum>{' '}
            {detailDataVesting.scheme.cryptoAssetSymbol}
          </Typography>
        </Box>
        <DialogActions
          sx={{ justifyContent: 'center', margin: '12px 0 12px 0' }}
        >
          {isLoadingButtonActive ? (
            <Button variant="contained">
              <CircularProgress color="inherit" size={30} />
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={!wallet}
              onClick={() => {
                const param = {
                  vestingBcId: detailDataVesting.vestingBcId,
                  amount:
                    detailDataVesting.totalAmountVesting -
                    detailDataVesting.vestedAmount,
                  from: wallet,
                  spender: contracts.Vesting,
                  id: detailDataVesting.id,
                };

                dispatch(actions.callActiveBCRequest(param));
              }}
            >
              Active vesting plan
            </Button>
          )}
        </DialogActions>
        {!wallet && (
          <Typography
            sx={{
              fontSize: '12px',
              color: '#d32f2f',
              marginLeft: '13px',
              textAlign: 'center',
            }}
          >
            Please connect wallet before active plan
          </Typography>
        )}
      </BootstrapDialog>
      <Modal
        open={isPopUpActiveSuccess}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            <CheckCircleIcon color="info" sx={{ fontSize: '70px' }} />
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              margin: '12px 0 10px 0',
            }}
          >
            Vesting request has been sent. Transaction is being processed
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            TXN hash:{' '}
            <Link
              target="_blank"
              href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/tx/${receiptActive.transactionHash}`}
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              {receiptActive.transactionHash}
            </Link>
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{ padding: '8px 40px 8px 40px' }}
              onClick={() => {
                dispatch(actions.closePopUpActiveSuccess(false));
                dispatch(actions.backupDataListVestingTable());
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={isPopUpActiveFailure}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            <ErrorIcon color="info" sx={{ fontSize: '70px' }} />
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              margin: '12px 0 10px 0',
            }}
          >
            Something went wrong
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{ padding: '8px 40px 8px 40px' }}
              onClick={() => {
                dispatch(actions.closePopUpActiveError(false));
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
