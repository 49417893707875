import { call, put, select, takeLatest } from 'redux-saga/effects';
import { auctionActions as actions } from '../../AuctionPage/slice';
import auctionServices from 'services/auctionServices';
import { RootState } from '../../../../types';
import { AuctionError } from './types';

function* getActiveAuctionHandler(action) {
  try {
    const response = yield call(auctionServices.postGetAuction, action.payload);
    yield put(actions.fetchActiveAuctionSuccess(response?.data));
  } catch (err: any) {
    yield put(actions.fetchActiveAuctionError(err.response.data));
  }
}

function* getEndedAuctionHandler(action) {
  try {
    const response = yield call(auctionServices.postGetAuction, action.payload);
    yield put(actions.fetchEndedAuctionSuccess(response?.data));
  } catch (err: any) {
    yield put(actions.fetchEndedAuctionError(err.response.data));
  }
}

function* getWaitingAuctionHandler(action) {
  try {
    const response = yield call(auctionServices.postGetAuction, action.payload);
    yield put(actions.fetchWaitingAuctionSuccess(response?.data));
  } catch (err: any) {
    let e: AuctionError = {
      rc: -1,
      rd: 'Error: get auction waiting failed',
    };
    yield put(actions.fetchWaitingAuctionError(e));
  }
}

// hide auction
function* hideAuctionHandler(action) {
  console.log('action: ' + JSON.stringify(action));
  try {
    const response = yield call(auctionServices.hideAuction, action.payload);
    console.log('response: ' + JSON.stringify(response.data));
    if (response.data.rc === 0) {
      yield put(actions.showFormHidden(false));
      const params = yield select(
        (state: RootState) => state.auction.active.params,
      );
      yield put(actions.fetchActiveAuction(params));
    } else {
      console.log('do smt');
    }
  } catch (e: any) {
    yield put(actions.hideAuctionError(e.response.data));
  }
}

export function* auctionSaga() {
  yield takeLatest(actions.fetchActiveAuction.type, getActiveAuctionHandler);
  yield takeLatest(actions.fetchEndedAuction.type, getEndedAuctionHandler);
  yield takeLatest(actions.fetchWaitingAuction.type, getWaitingAuctionHandler);
  yield takeLatest(actions.hideAuction.type, hideAuctionHandler);
}
