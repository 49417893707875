import {
  PawnShopError,
  PawnShopGetParams,
  PawnShopResponse,
  PawnShopState,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../../utils/redux-injectors';
import { pawnShopSaga } from './saga';

export const initState: PawnShopState = {
  isLoading: false,
  params: {
    name: '',
    pawnShop_type: '',
    owner_account: '',
    page: 0,
    size: 50,
  },
  pawnShops: [],
  total: 0,
};

const slice = createSlice({
  name: 'pawnShops',
  initialState: initState,
  reducers: {
    fetchPawnShop(state, action: PayloadAction<PawnShopGetParams>) {
      state.isLoading = true;
      state.params = action.payload;
    },
    fetchPawnShopSuccess(state, action: PayloadAction<PawnShopResponse>) {
      state.isLoading = false;
      state.pawnShops = action.payload.data.content;
      state.total = action.payload.data.total_elements;
    },
    fetchPawnShopError(state, action: PayloadAction<PawnShopError>) {
      state.isLoading = false;
      state.pawnShops = [];
      state.total = 0;
    },
  },
});

export const { actions: pawnShopActions } = slice;

export const usePawnShopSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: pawnShopSaga });
  return { actions: slice.actions };
};
