import { store } from 'index';
import { walletAction } from 'store/globalReducer';
import { walletActions as actions } from '../../../app/components/Wallet/slice/index';

declare global {
  interface Window {
    ethereum: any;
  }
}

//change account function
const Moralis = require('moralis');
export const accountsChanged = Moralis.onAccountsChanged(async function (
  accounts,
) {
  const storage = store.getState();
  const currentAddress: any = storage.wallet?.wallet;
  console.log('change account network', accounts, currentAddress);

  if (currentAddress && accounts) {
    if (currentAddress?.toLowerCase() !== accounts[0]?.toLowerCase()) {
      store.dispatch(walletAction(null));
      store.dispatch(actions.emptyWallet());
      localStorage.removeItem('StoreWallet');
      localStorage.removeItem('extensionName');
      localStorage.removeItem('lastToken');
      localStorage.removeItem('wallet_address');

      console.log('here !');
    }
  }
});
