/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  Router,
} from 'react-router-dom';
import { HomePage } from './pages/HomePage/Loadable';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import defaultTheme from 'themes/default';
import ProtectedRoute from './components/ProtectedRoute';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { AuctionPage } from './pages/AuctionPage';
import { CollectionPage } from './pages/CollectionPage';
import { PawnShopPage } from './pages/PawnShopPage';
import { LoginPage } from './pages/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../services/authService';
import { useAuthSlice } from './pages/LoginPage/slice';
import { AddNewToken } from './pages/AddNewTokenPage';
import { getContractBySignature } from 'services/walletService/supportService/contractHub';
import GlobalStateWrapper from './pages/GlobalStateWrapper';
import { TokenListPage } from './pages/TokenListPage';
import VestingPages from './pages/VestingPage/Vesting';
import Scheme from './pages/Scheme';
import CreateScheme from './pages/Scheme/components/CreateScheme';
import { createBrowserHistory } from 'history';
import homePageServices from 'services/homePageServices';
import HomePagePawn from './pages/HomePagePawn';
import { EditVoting } from './pages/EditVoting';
import { selectGlocalState } from './pages/GlobalStateWrapper/slice/selectors';
import Web3 from 'services/walletService/initWeb3';

export const history = createBrowserHistory();

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  const intanceValue = Web3.getInstance;

  useEffect(() => {
    if (authService.getAccessToken()) {
      dispatch(actions.fetchUserProfile());
    }
  }, [dispatch, actions]);

  useEffect(() => {
    const contracts = async () => await getContractBySignature();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('extensionName')) {
      (async () => {
        await intanceValue.setWeb3();
      })();
    }
  }, []);

  return (
    <Router history={history}>
      <Helmet
        titleTemplate="%s - React Boilerplate"
        defaultTitle="React Boilerplate"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>

      <ThemeProvider theme={defaultTheme}>
        <GlobalStateWrapper>
          <CssBaseline />
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <Route exact path="/login" component={LoginPage} />
            <ProtectedRoute path="/home/:slug">
              <HomePage />
            </ProtectedRoute>
            <Redirect from="/home" to="/home/nft-homepage" />
            <ProtectedRoute path="/auction">
              <AuctionPage />
            </ProtectedRoute>
            <ProtectedRoute path="/collection">
              <CollectionPage />
            </ProtectedRoute>
            <ProtectedRoute path="/pawn-shop">
              <PawnShopPage />
            </ProtectedRoute>
            <ProtectedRoute path="/token-list">
              <TokenListPage />
            </ProtectedRoute>
            <ProtectedRoute path="/vesting">
              <VestingPages />
            </ProtectedRoute>
            <ProtectedRoute exact path="/scheme">
              <Scheme />
            </ProtectedRoute>
            <ProtectedRoute exact path="/scheme/create">
              <CreateScheme />
            </ProtectedRoute>
            <ProtectedRoute path="/add-new-token">
              <AddNewToken />
            </ProtectedRoute>
            <ProtectedRoute path="/vesting">
              <VestingPages />
            </ProtectedRoute>
            <ProtectedRoute path="/homepage-pawn">
              <HomePagePawn />
            </ProtectedRoute>
            <ProtectedRoute path="/edit-voting">
              <EditVoting />
            </ProtectedRoute>
            <Route component={NotFoundPage} />
          </Switch>
        </GlobalStateWrapper>
      </ThemeProvider>
    </Router>
  );
}
