import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import * as auctionBlockchain from 'services/walletService/auctionService';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import { NotifyInfo } from '../../../../../types/model';
import { Alert, Snackbar } from '@mui/material';
import { useAuctionSlice } from '../../slice';
import { selectWaitingAuctions } from '../../slice/selectors';

import apiClient from 'services/apiService';

interface Props {
  show: boolean;
  item: any;
  callback: (value: boolean) => void;
}

export default function ApproveDialog({ show, item, callback }: Props) {
  const { actions } = useAuctionSlice();
  const dispatch = useDispatch();
  const walletState = useSelector(selectWallet);
  const waitingAuction = useSelector(selectWaitingAuctions);
  const [nft, setNft] = useState<NotifyInfo>({
    show: false,
    content: '',
    severity: 'success',
  });

  const notify = (content: string, success: boolean) => {
    let n: NotifyInfo = {
      ...nft,
      show: true,
      content: content,
      severity: success ? 'success' : 'error',
    };
    setNft(n);
  };

  const handleCloseNotify = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    let n: NotifyInfo = { ...nft, show: false };
    setNft(n);
  };

  const onApproveAuctionClicked = async () => {
    console.log('walletState: ' + JSON.stringify(walletState));

    if (isEmpty(walletState.wallet)) {
      console.log('wallet: ' + walletState.wallet);
      notify('Please connect wallet before approve auction', false);
      return;
    }

    let auctionTx;
    try {
      auctionTx = await auctionBlockchain.approveAuction(
        walletState.wallet,
        item.auction_id,
        1,
      );
    } catch (e) {
      console.log(e);
      console.log('mLog: ' + e);
    }

    console.log('auctionTx: ' + auctionTx);
    if (auctionTx === undefined || auctionTx === null) {
      notify('Approve auction failed, please try again', false);
      return;
    }

    if (auctionTx.hasError) {
      let s = auctionTx.msgError;
      if (s === undefined) {
        s = 'Call approve in blockchain failure';
      }
      notify(s, false);
      return;
    }

    dispatch(actions.setWaitingLoading(true));
    try {
      const receipt = await signAndSendTx(auctionTx);
      console.log('receipt: ' + JSON.stringify(receipt));
      if (receipt) {
        let status = receipt.status;
        if (status) {
          notify(
            'Approved auction in processing, please wait a moment and refresh page',
            true,
          );
          handleClose();
          let params = {
            txn_hash: receipt.transactionHash,
            from: receipt.from,
            to: receipt.to,
            id: item.id,
          };
          sendApproveToBE(params);
          return;
        } else {
          notify('Approved auction failed, please try again', false);
        }
      } else {
        notify('Approved auction failed, please try again', false);
      }
      dispatch(actions.setWaitingLoading(false));
    } catch (e) {
      console.log('e: ' + e.code + ', ' + e.message);
      let msg = 'Approve auction error';
      let dt = e.data;
      if (dt !== undefined) {
        console.log('data: ' + JSON.stringify(dt));
      }
      if (e.message) {
        msg += ': ' + e.message;
      }
      notify(msg, false);
      dispatch(actions.setWaitingLoading(false));
    }
  };

  const sendApproveToBE = (params: any) => {
    apiClient
      .request({
        method: 'POST',
        data: params,
        url: '/api-svc/api/auction/approve-auction',
      })
      .then(data => {})
      .catch(e => {})
      .finally(() => {
        dispatch(actions.fetchWaitingAuction(waitingAuction.params));
      });
  };

  const handleClose = () => {
    callback(false);
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Approve NFT auction'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Approve NFT auction will allow user search and send bid for this NFT
            <br />
            <strong>{item ? item.name : ''}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onApproveAuctionClicked} autoFocus>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={nft.show}
        autoHideDuration={6000}
        onClose={handleCloseNotify}
      >
        <Alert
          onClose={handleCloseNotify}
          severity={nft.severity}
          sx={{ width: '100%' }}
        >
          {nft.content}
        </Alert>
      </Snackbar>
    </>
  );
}
