import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  Typography,
} from '@mui/material';
import { DurationType, SchemeTypeEnum } from '../../slice/types';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  show: boolean;
  item?: any;
  callback: (value: boolean) => void;
  data: any;
}

export default function ActiveSchemeDialog({ show, callback, data }: Props) {
  const handleClose = () => {
    callback(false);
  };

  const status =
    data.status === SchemeTypeEnum.IN_ACTIVE
      ? 'In active'
      : data.status === SchemeTypeEnum.ACTIVE
      ? 'Active'
      : data.status === SchemeTypeEnum.COMPLETED
      ? 'Completed'
      : data.status === SchemeTypeEnum.PROCESSING
      ? 'Processing'
      : 'Hidden';

  const durationType =
    data.durationType === DurationType.WEEKS
      ? 'Week'
      : data.durationType === DurationType.MONTHS
      ? 'Month'
      : 'Quarter';
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Scheme detail'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid
            sx={{
              width: '500px',
            }}
          >
            <Grid mt={1} container>
              <Grid xs={4}>Scheme Name:</Grid>
              <Grid xs={4}>{data.schemeName}</Grid>
            </Grid>
            <Grid mt={1} container>
              <Grid xs={4}>Token vesting:</Grid>
              <Grid xs={4}>
                <img
                  src={
                    require(`app/assets/${
                      data.cryptoAssetSymbol ? data.cryptoAssetSymbol : 'DFY'
                    }.png`).default
                  }
                  alt=""
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '4px',
                  }}
                />
                {data.cryptoAssetSymbol}
              </Grid>
            </Grid>
            <Grid mt={1} container>
              <Grid xs={4}>Vesting duration:</Grid>
              <Grid xs={4}>{`${data.durationTime} ${durationType}`}</Grid>
            </Grid>
            <Grid mt={1} container>
              <Grid xs={4}>Cliff:</Grid>
              <Grid xs={4}>{`${data.cliffTime} ${durationType}`}</Grid>
            </Grid>
            <Grid mt={1} container>
              <Grid xs={4}>Status:</Grid>
              <Grid xs={4}>{status}</Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center !important',
        }}
      >
        <Button variant="contained" onClick={handleClose}>
          Oke
        </Button>
      </DialogActions>
    </Dialog>
  );
}
