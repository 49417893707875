import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { tokenList } from './saga';
import { FormSearch } from './types';

export const initialState: FormSearch = {
  data: [],
  total: 0,
  loadingStatus: false,
  popupStatus: false,
  successStatus: false,
  errorStatus: false,
  loadingUI: false,
};

interface formDataSearch {
  from_date: number;
  to_date: number;
  name?: string;
  status?: number | string;
  page?: number;
  size?: number;
}

const slice = createSlice({
  name: 'tokenList',
  initialState,
  reducers: {
    searchTokenList(state, action: PayloadAction<formDataSearch>) {},

    getTokenListData(state, action: PayloadAction<FormSearch>) {
      state.data = action.payload;
    },

    setLoadingUI(state, action) {
      state.loadingUI = action.payload;
    },

    callBcCancell(state, action) {},

    callBcCancellScc(state) {},

    callBcCancellFail(state) {},

    setLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },

    setPopupStatus(state, action) {
      state.popupStatus = action.payload;
    },

    setAlertStatus(state, action) {
      state.successStatus = action.payload;
    },

    setErrorStatus(state, action) {
      state.errorStatus = action.payload;
    },

    setTotalPage(state, action) {
      state.total = action.payload;
    },
  },
});

export const { actions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: tokenList });
  return { actions: slice.actions };
};
