/* --- STATE --- */

interface Data {
  id: number;
  schemeName: string;
  durationTime: number | null;
  durationType: number | null;
  afterStartTime: number | null;
  afterStartType: number | null;
  status: number | null;
  isDeleted: boolean;
  crypto_asset: {
    id: number;
    symbol: string;
  };
}
export interface SchemeState {
  filterScheme: {
    name: string;
    status: string | null;
    token: string | null;
  };

  dataScheme: any;
  params: {
    page: number;
    size: number;
  };

  isFalse: boolean;

  listTokens: [
    {
      symbol: string;
      tokenAddress: string;
    },
  ];

  reRender: boolean;
}

export interface params {
  page: number;
  size: number;
}
export interface schemeError {
  rc: number;
  rd: string;
}

export enum SchemeTypeEnum {
  IN_ACTIVE, //0
  ACTIVE, //1
  COMPLETED, //2
  PROCESSING, //3
  HIDDEN,
}

export enum DurationType {
  DAY,
  WEEKS,
  MONTHS,
  QUARTER,
}
