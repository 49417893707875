import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useSlice } from '../../slice';
import { selectVesting } from '../../slice/selectors';
import { Box } from '@mui/system';
import moment from 'moment';
import { CircularProgress, Link, Modal } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { FunctionFormatNum } from 'utils/formatNumber';

export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

interface DataConfirm {
  data: Data;
}

interface Data {
  name: string;
  addressWallet: string;
  scheme: string;
  totalAmount: string;
  vestedAmount: string;
  startTime: number;
  tokenRelease: number;
  from: string;
  spender: string;
  schemeName: string;
  token: string;
  amountDeposit: number;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const dispatch = useDispatch();
  const { actions } = useSlice();
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}

      <IconButton
        aria-label="close"
        onClick={() => dispatch(actions.closecfvsting(false))}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default function DialogConfirmPopUp(props: DataConfirm) {
  const dispatch = useDispatch();
  const { actions } = useSlice();
  const {
    isPopupConfirm,
    isTurnOnButtonCreate,
    isLoadingApprove,
    isLockedApprove,
    resDiv,
    receiptApprove,
    isLoadingCallBC,
    receiptBC,
    turnOnPopupSuccess,
    isFailureCallBC,
    isFailureApprove,
  } = useSelector(selectVesting);

  const renderToken = token => {
    switch (token) {
      case 0:
        return 'Daily';
      case 1:
        return 'Weekly';
      case 2:
        return 'Monthly';
      case 3:
        return 'Quarterly';

      default:
        break;
    }
  };

  React.useEffect(() => {
    const paramApprove = {
      curAddress: props.data.from,
      tokenSymbol: props.data.token,
      toAddress: props.data.spender,
      amount: props.data.amountDeposit,
    };

    dispatch(actions.callCheckApproveRequest(paramApprove));
    if (resDiv > props.data.amountDeposit) {
      dispatch(actions.setButton(false));
    } else {
      dispatch(actions.setButton(true));
    }
  }, [props.data.from, receiptApprove, props.data.amountDeposit]);

  React.useEffect(() => {
    if (resDiv > props.data.amountDeposit) {
      dispatch(actions.setButton(false));
    } else {
      dispatch(actions.setButton(true));
    }
  }, [resDiv]);

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={isPopupConfirm}
        sx={{
          '.MuiDialog-paper': {
            maxWidth: '800px !important',
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            dispatch(actions.closeDialogConfirm(false));
          }}
        >
          Confirm vesting
        </BootstrapDialogTitle>
        <Box
          sx={{
            display: 'flex',
            width: '800px',
            justifyContent: 'center',
            gap: '30px',
          }}
        >
          <Box>
            <Typography>Vesting Name:</Typography>
            <Typography>Wallet address:</Typography>
            <Typography>Scheme:</Typography>
            <Typography>Total amount:</Typography>
            <Typography>Vested amount:</Typography>
            <Typography>Start time:</Typography>
            <Typography>Token release:</Typography>
          </Box>
          <Box>
            <Typography>{props.data.name}</Typography>
            <Typography>{props.data.addressWallet}</Typography>
            <Typography>{props.data.schemeName}</Typography>
            <Typography>
              <FunctionFormatNum>{props.data.totalAmount}</FunctionFormatNum>{' '}
              <img
                src={
                  require(`app/assets/${
                    props.data.token ? props.data.token : 'DFY'
                  }.png`).default
                }
                alt=""
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '4px',
                }}
              />
              {props.data.token}
            </Typography>
            <Typography>
              <FunctionFormatNum>{props.data.vestedAmount}</FunctionFormatNum>{' '}
              <img
                src={
                  require(`app/assets/${
                    props.data.token ? props.data.token : 'DFY'
                  }.png`).default
                }
                alt=""
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '4px',
                }}
              />
              {props.data.token}
            </Typography>
            <Typography>
              {moment(props.data.startTime * 1000).format('DD/MM/YYYY')}
            </Typography>
            <Typography>{renderToken(props.data.tokenRelease)}</Typography>
          </Box>
        </Box>
        <DialogActions
          sx={{
            justifyContent: 'center',
            margin: '12px 0 12px 0',
            gap: '30px',
          }}
        >
          {resDiv <= props.data.amountDeposit ? (
            <Button
              variant="contained"
              sx={{ width: '150px' }}
              onClick={() => {
                const paramApprove = {
                  curAddress: props.data.from,
                  tokenSymbol: props.data.token,
                  toAddress: props.data.spender,
                  amount: props.data.amountDeposit,
                };

                dispatch(actions.callApproveRequest(paramApprove));
              }}
            >
              {isLoadingApprove ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                'Approve'
              )}
            </Button>
          ) : (
            <>
              {receiptApprove ? (
                <Button variant="contained" disabled={isLockedApprove}>
                  Approved
                </Button>
              ) : (
                ''
              )}
            </>
          )}
          {isLoadingCallBC ? (
            <Button variant="contained">
              <CircularProgress color="inherit" size={30} />
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                dispatch(actions.callBCRequest(props.data));
              }}
              disabled={isTurnOnButtonCreate}
            >
              Create vesting plan
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
      <Modal
        open={turnOnPopupSuccess}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            <CheckCircleIcon color="info" sx={{ fontSize: '70px' }} />
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              margin: '12px 0 10px 0',
            }}
          >
            Vesting request has been sent. Transaction is being processed
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            TXN hash:{' '}
            <Link
              target="_blank"
              href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/tx/${receiptBC.transactionHash}`}
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              {receiptBC.transactionHash}
            </Link>
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{ padding: '8px 40px 8px 40px' }}
              onClick={() => {
                dispatch(actions.resetRedux());
                setTimeout(() => {
                  dispatch(actions.backupDataListVestingTable());
                }, 200);
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={isFailureApprove || isFailureCallBC}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            <ErrorIcon color="info" sx={{ fontSize: '70px' }} />
          </Box>
          <Typography
            sx={{
              textAlign: 'center',
              margin: '12px 0 10px 0',
            }}
          >
            Something wrong
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{ padding: '8px 40px 8px 40px' }}
              onClick={() => {
                dispatch(actions.setDataWhenError(false));
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
