import * as React from 'react';
import NumberFormat from 'react-number-format';
interface DfrNumberProps {
  DfyNumber?: number;
  DfyString?: string;
  children: any;
}

export const FunctionFormatNum = (props: DfrNumberProps) => {
  return (
    <>
      <NumberFormat
        thousandsGroupStyle="thousand"
        value={props.DfyNumber || props.children}
        prefix=""
        decimalSeparator="."
        displayType="text"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        suffix={props.DfyString}
      />
    </>
  );
};

export const formatNumberText = (num: number, many: number = 5) => {
  return num.toLocaleString('en-US', { maximumFractionDigits: many });
};
