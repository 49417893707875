import apiClient from './apiService';

const CancellTokenServices = {
  cancell(data: any) {
    return apiClient.request({
      method: 'PUT',
      url: `voting-svc/api/tokens/${data.id}/cancel?txn_hash=${data.txn_hash}`,
      data: data,
    });
  },
};

export default CancellTokenServices;
