import { PayloadAction } from '@reduxjs/toolkit';
import { history } from 'app';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { schemeAddSaga } from './saga';
import { SchemeAddState } from './types';

export const initialState: SchemeAddState = {
  Progress: null,
  alertInfor: false,
  status: false,
  dataBC: {},
};

const slice = createSlice({
  name: 'schemeAdd',
  initialState,
  reducers: {
    addNewScheme(state, action: PayloadAction<any>) {
      state.Progress = true;
      state.alertInfor = false;
    },

    addNewSchemeSuccess(state, action: PayloadAction<any>) {
      state.Progress = false;
      state.alertInfor = true;
      state.status = true;
      state.dataBC = action.payload;
    },

    addNewSchemeFail(state) {
      state.Progress = false;
      state.alertInfor = true;
      state.status = false;
    },

    addNewSchemeReset(state) {
      return initialState;
    },

    alertInforReset(state) {
      state.alertInfor = false;
      history.push('/scheme');
    },
  },
});

export const { actions: schemeAddActions } = slice;

export const useSchemeAddSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: schemeAddSaga });
  return { actions: slice.actions };
};
