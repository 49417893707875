import React from 'react';
import closeIcon from './closeIcon.svg';
import checkIconInputFrom from './checkIconInputFrom.svg';

interface iconProps {
  type: string;
}

export const IconInputField = (props: iconProps) => {
  return (
    <img
      src={props.type === 'success' ? checkIconInputFrom : closeIcon}
      alt=""
    />
  );
};
