import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  TextField,
  Typography,
  FormControl,
  Input,
  IconButton,
  FormHelperText,
} from '@mui/material';

import Paper from '@mui/material/Paper';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useDropzone } from 'react-dropzone';

import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface arrProps {
  label: string;
  year: number;
}

export const ModalOffcailPawn = props => {
  const MyCom = ({ children }) => {
    return <></>;
  };

  const [valuePawnshop, setValuePawnshop] = React.useState<any>();
  const [valueToken, setValueToken] = React.useState<any>();

  const [arr, setArr] = React.useState<arrProps[]>(top100Films.slice(0, 20));

  // const handleFileChange = e => {
  //   let url = URL.createObjectURL(e.target.files[0]);

  //   const preloadImage = (src: string) => {
  //     return new Promise(resolve => {
  //       const img = new Image();

  //       img.onload = function () {
  //         if (img.height === 64 && img.width === 64) {
  //           resolve({
  //             url: url,
  //             data: e.target.files[0],
  //           });
  //         } else {
  //           resolve(null);
  //         }
  //       };

  //       img.src = src;
  //     });
  //   };

  //   return preloadImage(url);
  // };

  const [files, setFiles] = React.useState<any>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const thumbs = files.map(file => (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        img: {
          width: '100%',
          height: '100%',
        },
      }}
      key={file.name}
    >
      {file.preview && <img src={file.preview} alt="" />}
    </Box>
  ));

  React.useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <Dialog
      open={props.isModalOffcialPawn}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '700px',
          width: '100%',
          height: '470px',
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {'Select a Pawnshop'}
        <Button
          variant="text"
          sx={{ color: '#000', padding: '0', minWidth: 'auto' }}
          onClick={() => props.setIsModalOffcialPawn(false)}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box
            sx={{
              width: '50%',
            }}
          >
            <Typography
              component={'label'}
              sx={{ width: '100%' }}
              htmlFor="pawnshop"
            >
              Pawnshop
            </Typography>
            <Autocomplete
              disablePortal
              id="pawnshop"
              options={arr}
              onChange={(event: any, newValue: any) => {
                setValuePawnshop(newValue);
              }}
              onInputChange={() => {}}
              PaperComponent={props => {
                return (
                  <InfiniteScroll
                    dataLength={top100Films.length}
                    next={() => console.log('Test')}
                    hasMore={true}
                    loader={
                      <Typography component="span" sx={{ padding: '5px 20px' }}>
                        ...
                      </Typography>
                    }
                  >
                    {' '}
                    <Paper sx={{ maxHeight: '200px', overflow: 'auto' }}>
                      {props.children}
                    </Paper>
                  </InfiniteScroll>
                );
              }}
              value={valuePawnshop}
              sx={{
                width: '250px',
                height: '30px',
                marginBottom: '50px',

                '.MuiOutlinedInput-root,.MuiOutlinedInput-input': {
                  padding: '0',
                  height: '30px',
                  boxSizing: 'border-box',
                },
                '.MuiOutlinedInput-input': {
                  fontSize: '14px',
                },
              }}
              renderInput={params => <TextField {...params} />}
            />{' '}
            <Typography sx={{ marginBottom: '20px' }}>
              Name: {'Shopeee Mai'}
            </Typography>
            <Typography>Email: {'lemai1003@gmail.com'}</Typography>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Typography
              component={'label'}
              sx={{ width: '100%' }}
              htmlFor="token"
            >
              token
            </Typography>
            <Autocomplete
              disablePortal
              id="token"
              options={top100Films}
              onChange={(event: any, newValue: any) => {
                setValueToken(newValue);
              }}
              sx={{
                width: '250px',
                height: '30px',
                marginBottom: '50px',

                '.MuiOutlinedInput-root,.MuiOutlinedInput-input': {
                  padding: '0',
                  height: '30px',
                  boxSizing: 'border-box',
                },
                '.MuiOutlinedInput-input': {
                  fontSize: '14px',
                },
              }}
              value={valueToken}
              renderInput={params => <TextField {...params} />}
            />
            <Typography sx={{ marginBottom: '20px' }}>
              Name: {'Uniswap'}
            </Typography>
            <Typography sx={{ marginBottom: '20px' }}>
              Symbol: {'UNI'}
            </Typography>
            <Box display={'flex'} sx={{ alignItems: 'baseline' }}>
              <Typography>Logo (300x300)</Typography>
              <Box className="container" display={'flex'}>
                <Box
                  sx={{
                    svg: {
                      fontSize: '40px',
                    },
                  }}
                  {...getRootProps({ className: 'dropzone' })}
                >
                  <input {...getInputProps()} />
                  <FileUploadIcon />
                </Box>
                <Box sx={{ width: '100px', height: '100px' }}>{thumbs}</Box>
              </Box>

              {/* <FormHelperText
                sx={{
                  position: 'absolute',
                  top: '54px',
                  left: '0px',
                  margin: '0px',
                  color: '#d32f2f',
                }}
              >
                {hasError?.message}
              </FormHelperText> */}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '30px' }}>
        <Button
          variant="outlined"
          sx={{
            borderColor: '#000',
            backgroundColor: '#C3c3c3',
            color: '#000',
            textTransform: 'capitalize',
            padding: '3px 13px',
            '&:hover': {
              backgroundColor: '#ccc',
              color: '#000',
              borderColor: '#000',
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'Sunset Boulevard', year: 1950 },
];
