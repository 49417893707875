import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { DfyAlert } from 'app/components/DfyAlert';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import moment from 'moment';
import queryString from 'query-string';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { selectGlocalState } from 'app/pages/GlobalStateWrapper/slice/selectors';
import { ReactComponent as Cancell } from './components/assets/x-circle.svg';
import { Date } from './components/date';
import { InforToken } from './components/inforToken';
import { Properties } from './components/properties';
import { UploadFile } from './components/uploadFile';
import { useAddNewTokenSlice } from './slice';
import { selectEditVotingState } from './slice/selectors';
import DfyButtonApprove from './components/buttonApprove';
import BigNumber from 'bignumber.js';

export function EditVoting() {
  const [properties, setProperties] = React.useState<any>();

  const [statusReset, setStatusReset] = React.useState<boolean>(false);

  const [FileImg, setFileImg] = React.useState<any>();

  const [statusDialog, setStatusDialog] = React.useState<any>(false);

  const [dataSum, setDataSum] = React.useState<any>();

  const [resetDate, setResetDate] = React.useState(false);

  const [approveStatus, setApproveStatus] = React.useState(false);

  const schema = yup.object({
    ticker: yup
      .string()
      .required('Invalid token ticker')
      .max(50, 'Invalid token ticker'),
    tokenName: yup
      .string()
      .required('Invalid token name')
      .max(50, 'Invalid token name'),
    contract: yup
      .string()
      .required('Invalid Token contract')
      .max(100, 'Invalid Token contract'),
    project: yup
      .string()
      .required('Invalid Project name')
      .max(100, 'Invalid Project name'),
    telegram: yup
      .string()
      .required('Invalid Telegram')
      .max(100, 'Invalid Telegram'),
    twitter: yup
      .string()
      .required('Invalid Twitter')
      .max(100, 'Invalid Twitter'),
    website: yup
      .string()
      .required('Invalid Website')
      .max(100, 'Invalid Website'),
    voting: yup
      .string()
      .required('Invalid Target voting')
      .test('voting', 'Max 5 digits after decimal point', (value: any) =>
        /^\d+(\.\d{1,5})?$/.test(value),
      )
      .typeError('Total is required'),
    reward: yup
      .string()
      .required('Invalid Reward pool')
      .test('reward', 'Max 5 digits after decimal point', (value: any) =>
        /^\d+(\.\d{1,5})?$/.test(value),
      )
      .typeError('Total is required'),
    dateForm: yup
      .string()
      .required('Invalid From date')
      .nullable()
      .required('Invalid From date'),
    dateTo: yup
      .string()
      .required('Invalid To date')
      .nullable()
      .required('Invalid To date'),
    // .when('dateForm', (dateForm, schema) =>
    //   schema.test(
    //     'dateTo',
    //     'The To date must be greater than the From date',
    //     value => moment(value).valueOf() > moment(dateForm).valueOf(),
    //   ),
    // ),
    upLoadFileImg: yup
      .object()
      .required('This field is required.')
      .shape({
        url: yup.string().required('This field is required.'),
        data: yup.mixed().required('This field is required.'),
      })
      .nullable()
      .required('This field is required.'),
  }); // validation inputfield

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  }); //type

  const handleGetProperties = data => {
    setProperties(data);
  };

  const upLoadFileImg = form.watch('upLoadFileImg');

  const tickerSymbol = form.watch('ticker');

  React.useEffect(() => {
    setFileImg(upLoadFileImg);
  }, [upLoadFileImg]);

  // hard code spender edit

  const spender = useSelector(selectGlocalState);

  const editVotingState = useSelector(selectEditVotingState);

  const Wallet = useSelector(selectWallet);

  const onSubmit = (data: any) => {
    setStatusDialog(true);
    setDataSum({
      ...data,
      dateForm: !!Number(data.dateForm)
        ? data.dateForm
        : moment(data.dateForm).utc().valueOf(),
      dateTo: !!Number(data.dateTo)
        ? data.dateTo
        : moment(data.dateTo).utc().valueOf(),
      spender:
        spender.contracts.Vote || '0x8DfaD019E51FADE0a8D31021122153C9b01de4a3',
      wallet: Wallet.wallet,
      votingId: queryString.parse(history.location.search).bcId,
      additional_info: properties,
    });
  }; //get data

  const dispatch = useDispatch();

  const history = useHistory();

  const { actions } = useAddNewTokenSlice();

  const handleConfirm = () => {
    dispatch(actions.callBlockChain(dataSum));
  };

  React.useEffect(() => {
    if (editVotingState.Progress !== null) {
      setStatusDialog(false);
    }
  }, [editVotingState.Progress]);

  const handleReset = () => {
    dispatch(actions.reset());
    form.reset({
      ticker: '',
      tokenName: '',
      contract: '',
      project: '',
      telegram: '',
      twitter: '',
      website: '',
      voting: '',
      reward: '',
      dateForm: '',
      dateTo: '',
      upLoadFileImg: '',
    });
    setStatusReset(!statusReset);
    setResetDate(!resetDate);
  };

  React.useEffect(() => {
    dispatch(
      actions.editVoting(queryString.parse(history.location.search).tokenId),
    );
  }, []);

  React.useEffect(() => {
    if (editVotingState.tokenDetail) {
      form.setValue('ticker', editVotingState.tokenDetail.symbol);
      form.setValue('tokenName', editVotingState.tokenDetail.name);
      form.setValue('contract', editVotingState.tokenDetail.contract);
      form.setValue('project', editVotingState.tokenDetail.project_name);
      form.setValue('telegram', editVotingState.tokenDetail.telegram);
      form.setValue('twitter', editVotingState.tokenDetail.twitter);
      form.setValue('voting', editVotingState.tokenDetail.target_voting);
      form.setValue('reward', editVotingState.tokenDetail.reward_pool);
      form.setValue('dateForm', editVotingState.tokenDetail.from_date);
      form.setValue('dateTo', editVotingState.tokenDetail.to_date);
    }
  }, [editVotingState.tokenDetail]);

  return (
    <>
      <Helmet>
        <title>Auction Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>

      <OneColumnLayout>
        <Grid container>
          <Grid item xs={12}>
            <Button variant="text" onClick={() => history.push('/token-list')}>
              <ArrowBackIosIcon />
              Back
            </Button>
          </Grid>
        </Grid>

        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            {/* ticker start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box>
                <Typography>Token Ticker *</Typography>
              </Box>
              <Box>
                <InforToken
                  form={form}
                  name="ticker"
                  style={{ width: { xs: '300px', xl: '400px' } }}
                />
              </Box>
            </Grid>
            {/* ticker end */}

            {/* name start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                <Typography>Token name *</Typography>
              </Box>
              <Box>
                <InforToken
                  form={form}
                  name="tokenName"
                  style={{ width: { xs: '300px', xl: '400px' } }}
                />
              </Box>
            </Grid>
            {/* name end */}

            {/* contract start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box>
                <Typography>Token contract *</Typography>
              </Box>
              <Box>
                <InforToken
                  form={form}
                  name="contract"
                  style={{ width: { xs: '300px', xl: '400px' } }}
                />
              </Box>
            </Grid>
            {/* contract end */}

            {/* uploadfile start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                <Typography component="p">Token logo *</Typography>
                <Typography component="p">(64x64px)</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '300px' }}>
                  <UploadFile form={form} name="upLoadFileImg" />
                </Box>
                {Boolean(FileImg) && (
                  <Box>
                    <img
                      src={Boolean(FileImg) ? FileImg.url : ''}
                      style={{ width: '32px', height: '32px' }}
                      alt=""
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            {/* uploadfile end */}

            <>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box>
                  <Typography>From *</Typography>
                </Box>
                <Box>
                  <Date
                    form={form}
                    name="dateForm"
                    type="from"
                    reset={resetDate}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                  <Typography>To *</Typography>
                </Box>
                <Box>
                  <Date form={form} name="dateTo" type="to" reset={resetDate} />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box>
                  <Typography>Project name *</Typography>
                </Box>
                <Box>
                  <InforToken
                    form={form}
                    name="project"
                    style={{ width: { xs: '300px', xl: '400px' } }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                  <Typography>Telegram</Typography>
                </Box>
                <Box>
                  <InforToken
                    form={form}
                    name="telegram"
                    style={{ width: { xs: '300px', xl: '400px' } }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box>
                  <Typography>Website</Typography>
                </Box>
                <Box>
                  <InforToken
                    form={form}
                    name="website"
                    style={{ width: { xs: '300px', xl: '400px' } }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                  <Typography>Twitter</Typography>
                </Box>
                <Box>
                  <InforToken
                    form={form}
                    name="twitter"
                    style={{ width: { xs: '300px', xl: '400px' } }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box>
                  <Typography>Target voting (DFY)</Typography>
                </Box>
                <Box>
                  <InforToken
                    form={form}
                    name="voting"
                    style={{ width: { xs: '300px', xl: '400px' } }}
                    type="number"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                columnGap="10px"
              >
                <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                  <Typography>
                    Reward pool{' '}
                    {Boolean(tickerSymbol) ? `(${tickerSymbol})` : ''}
                  </Typography>
                </Box>
                <Box>
                  <InforToken
                    form={form}
                    name="reward"
                    style={{ width: { xs: '300px', xl: '400px' } }}
                    type="number"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box>
                  <Typography>Properties</Typography>
                </Box>
                <Box sx={{ margin: '30px 0px' }}>
                  <Properties
                    handleAddMoreList={handleGetProperties}
                    status={statusReset}
                  />
                </Box>
              </Grid>
            </>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" sx={{ marginRight: '100px' }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </OneColumnLayout>
      {/* statusDialog */}
      <Dialog open={statusDialog}>
        <Box sx={{ padding: '24px 30px 10px 30px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              columnGap: '20px',
            }}
          >
            <Box>
              <Typography>Do you want to edit this voting ?</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setStatusDialog(false)}>
                <Cancell style={{ width: '30px', height: '30px' }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography>Token:</Typography>

            <Typography>{dataSum?.ticker}</Typography>
          </Box>
          {/* 
          <>
            <Box sx={{ display: 'flex' }}>
              <Typography>From:</Typography>

              <Typography>
                {moment(
                  dataSum?.dateForm,
                  'ddd MMM DD YYYY HH:mm:ss GMTZZ',
                ).format('DD/MM/yyyy')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Typography>To:</Typography>

              <Typography>
                {moment(
                  dataSum?.dateTo,
                  'ddd MMM DD YYYY HH:mm:ss GMTZZ',
                ).format('DD/MM/yyyy')}
              </Typography>
            </Box>
          </> */}

          <Box sx={{ margin: '30px 0px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <DfyButtonApprove
                curAddress={Wallet.wallet}
                tokenSymbol={dataSum?.contract}
                toAddress="0x8DfaD019E51FADE0a8D31021122153C9b01de4a3"
                amount={new BigNumber(Number(dataSum?.reward))
                  .multipliedBy(10 ** 18)
                  .toFixed()}
                handleAction={status => {
                  setApproveStatus(status);
                }}
              />
              <Button
                variant="contained"
                onClick={handleConfirm}
                disabled={!approveStatus || editVotingState.votingStatus}
                sx={{ height: '48px', fontSize: '16px', marginLeft: '5px' }}
              >
                {editVotingState.votingStatus ? (
                  <Box sx={{ padding: '20px  40px 10px 40px' }}>
                    <CircularProgress size={30} />
                  </Box>
                ) : (
                  'confirm'
                )}
              </Button>
            </Box>
            {!Boolean(Wallet.wallet) && (
              <Box>
                <Typography sx={{ color: 'red', margin: '10px 0px' }}>
                  let's connect the wallet
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
      <Dialog
        open={
          editVotingState.Progress === null ? false : editVotingState.Progress
        }
      ></Dialog>

      <DfyAlert
        type={editVotingState.status ? 'success' : 'danger'}
        alertText={
          <Typography sx={{ color: 'black' }}>
            {editVotingState.status ? 'Something when wrong' : 'error occurred'}
          </Typography>
        }
        handle={handleReset}
        isOpen={
          editVotingState.alertInfor === null
            ? false
            : editVotingState.alertInfor
        }
        onClose={() => {}}
      />
    </>
  );
}
