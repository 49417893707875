import { yupResolver } from '@hookform/resolvers/yup';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DfyAlert } from 'app/components/DfyAlert';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import { selectGlocalState } from 'app/pages/GlobalStateWrapper/slice/selectors';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useAuthSlice } from '../slice/index';
import { selectTokenList } from '../slice/selectors';
import { TokenListEnum, TokenListStatusEnum } from '../slice/types';
import { Wrapper } from '../styled';
import DialogAddANewToken from './addNewToken';

export const TokenListComponent = () => {
  const [fromTime, setFromTime] = useState<any>(
    moment().subtract(TokenListEnum.DAYS, 'days').toDate(),
  );
  const [toTime, setToTime] = useState<any>(moment().toDate());
  const [status, setStatus] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [page, setPage] = useState(1);
  const [idToken, setIdToken] = useState();
  const [idTokenBE, setIdTokenBE] = useState<string>('');

  const dispatch = useDispatch();
  const { actions } = useAuthSlice();

  const handleOpen = (idBC, idBE) => {
    dispatch(actions.setPopupStatus(true));
    setIdToken(idBC);
    setIdTokenBE(idBE);
  };

  const handleClose = () => dispatch(actions.setPopupStatus(false));

  const schema = yup.object({
    fromTime: yup
      .string()
      .required('Invalid from date')
      .test(
        'fromTime',
        'Date range should not exceed 90 days',
        value =>
          moment(toTime).utc().valueOf() - moment(value).utc().valueOf() <=
          7776100000,
      ),
    toTime: yup
      .string()
      .required('Invalid to date')
      .test(
        'toTime',
        'The To date must be greater than the From date',
        value =>
          moment(value).utc().valueOf() > moment(fromTime).utc().valueOf(),
      ),
    token: yup.string(),
    status: yup.string(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fromTime: moment().subtract(TokenListEnum.DAYS, 'days').toDate(),
      toTime: moment().toDate(),
      token: '',
      status: '',
    },
  });

  const DEFAULT_SIZE_PAGE = 10;

  // handle redux

  const tokenListState = useSelector(selectTokenList);

  const handleSubmit = async formData => {
    dispatch(
      actions.searchTokenList({
        from_date: moment(formData.fromTime).utc().valueOf(),
        to_date: moment(formData.toTime).utc().valueOf(),
        name: formData.token.trim(),
        status: formData.status,
        page,
        size: DEFAULT_SIZE_PAGE,
      }),
    );

    setStatus(formData.status);
    setTokenName(formData.token);
    setFromTime(formData.fromTime);
    setToTime(formData.toTime);
  };

  // call api when change pagination and loading app

  useEffect(() => {
    dispatch(
      actions.searchTokenList({
        from_date: moment(fromTime).utc().valueOf(),
        to_date: moment(toTime).utc().valueOf(),
        name: tokenName.trim(),
        status: status,
        page,
        size: DEFAULT_SIZE_PAGE,
      }),
    );
  }, [page]);

  const convertStatus = status => {
    switch (status) {
      case TokenListStatusEnum.COMMING_SOON:
        return 'Comming soon';
      case TokenListStatusEnum.VOTING:
        return 'Voting';
      case TokenListStatusEnum.QUEUE:
        return 'Queue';
      case TokenListStatusEnum.FAILED:
        return 'Failled';
      case TokenListStatusEnum.EXECUTE:
        return 'Executed';
      case TokenListStatusEnum.CANCELLED:
        return 'Cancelled';
      default:
        break;
    }
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const Wallet = useSelector(selectWallet);

  const spender = useSelector(selectGlocalState);

  const handleCallBlockChain = () => {
    dispatch(
      actions.callBcCancell({
        id: idToken,
        spender: spender.contracts.Vote,
        from: Wallet.wallet,
        idBE: idTokenBE,
      }),
    );
  };

  const history = useHistory();

  const handleCloseAlert = () => {
    dispatch(actions.setAlertStatus(false));
  };

  const handleCloseError = () => {
    dispatch(actions.setErrorStatus(false));
  };

  // go to token detail

  const handleGoToDetail = tokenDetail => {
    window.location.replace(
      `${process.env.REACT_APP_URL_MARKETPLACE}voting-detail/${tokenDetail.id}`,
    );
  };

  const handleEditVoting = (tokenId, bcId) => {
    history.push(`/edit-voting?tokenId=${tokenId}&bcId=${bcId}`);
  };

  const [dataSum, setDataSum] = useState<any>();
  const [statusDialogAddToken, setStatusDialogAddToken] =
    useState<boolean>(false);

  const handleAddToken = data => {
    setDataSum(data);
    setStatusDialogAddToken(true);
  };
  const handleCloseDialog = data => {
    setStatusDialogAddToken(false);
  };

  return (
    <>
      <Wrapper onSubmit={form.handleSubmit(handleSubmit)}>
        <Box>
          <Box className="control">
            <Box className="fieldWrapper">
              <Box className="titleField">From</Box>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  control={form.control}
                  name="fromTime"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={fromTime}
                      maxDate={moment(moment().toDate()).utc().valueOf()}
                      onChange={e => {
                        onChange(e);
                        setFromTime(e);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={!!form.formState.errors.fromTime}
                          helperText={form.formState.errors.fromTime?.message}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box className="fieldWrapper">
              <Box className="titleField">To</Box>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  control={form.control}
                  name="toTime"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={toTime}
                      maxDate={moment(moment().toDate()).utc().valueOf()}
                      onChange={e => {
                        onChange(e);
                        setToTime(e);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={!!form.formState.errors.toTime}
                          helperText={form.formState.errors.toTime?.message}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Button
              variant="contained"
              onClick={() => {
                history.push('/add-new-token');
              }}
            >
              New token
            </Button>
          </Box>
          <Box className="control">
            <Box className="fieldWrapper">
              <Box className="titleField">Token</Box>

              <Controller
                control={form.control}
                name="token"
                render={({ field }) => (
                  <TextField {...field} variant="outlined" />
                )}
              />
            </Box>

            <Box className="fieldWrapper statusField">
              <Box className="titleField ">Status</Box>

              <Controller
                control={form.control}
                name="status"
                render={({ field }) => (
                  <Select {...field} displayEmpty>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value={TokenListStatusEnum.COMMING_SOON}>
                      Comming soon
                    </MenuItem>
                    <MenuItem value={TokenListStatusEnum.VOTING}>Vote</MenuItem>
                    <MenuItem value={TokenListStatusEnum.QUEUE}>Queue</MenuItem>
                    <MenuItem value={TokenListStatusEnum.FAILED}>
                      Failed
                    </MenuItem>
                    <MenuItem value={TokenListStatusEnum.EXECUTE}>
                      Executed
                    </MenuItem>
                    <MenuItem value={TokenListStatusEnum.CANCELLED}>
                      Canceled
                    </MenuItem>
                  </Select>
                )}
              />
            </Box>

            <Button variant="contained" type="submit">
              Search
            </Button>
          </Box>
          <Box className="listDataWrapper">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="left">No</TableCell>
                  <TableCell align="left">Token</TableCell>
                  <TableCell align="left">Created date</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">icon</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tokenListState.data.map((row, index) => (
                  <TableRow key={index} sx={{ cursor: 'pointer' }}>
                    <TableCell
                      align="left"
                      onClick={() => {
                        handleGoToDetail(row);
                      }}
                    >
                      {index}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        handleGoToDetail(row);
                      }}
                    >
                      {row.symbol}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        handleGoToDetail(row);
                      }}
                    >
                      {moment(row.created_at).format('hh:mm DD/MM/YYYY')}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => {
                        handleGoToDetail(row);
                      }}
                    >
                      {convertStatus(row.status)}
                    </TableCell>
                    <TableCell align="left" className="listIconStatus">
                      {(row.status === TokenListStatusEnum.COMMING_SOON ||
                        row.status === TokenListStatusEnum.VOTING) && (
                        <Box className="iconStatus">
                          <EditOutlinedIcon
                            onClick={() => {
                              handleEditVoting(row.id, row.bc_id);
                            }}
                          />
                        </Box>
                      )}

                      {(row.status === TokenListStatusEnum.COMMING_SOON ||
                        row.status === TokenListStatusEnum.VOTING) && (
                        <Box className="iconStatus">
                          <DeleteForeverOutlinedIcon
                            onClick={() => {
                              handleOpen(row.bc_id, row.id);
                            }}
                          />
                        </Box>
                      )}

                      {row.status === TokenListStatusEnum.QUEUE && (
                        <Box className="iconStatus">
                          <CheckBoxOutlinedIcon
                            onClick={() =>
                              handleAddToken({
                                type: 1,
                                symbol: row.symbol,
                                name: row.name,
                                contract: row.contract,
                                logo_cid: row.logo_cid,
                                bc_id: row.bc_id,
                              })
                            }
                          />
                        </Box>
                      )}

                      <p></p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
        <Box className="pagination">
          <Pagination
            count={Math.ceil(tokenListState.total / 10)}
            page={page}
            onChange={handleChangePage}
            sx={{ marginTop: '20px' }}
          />
        </Box>

        <div>
          <Modal
            open={tokenListState.popupStatus}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, marginBottom: '40px' }}
              >
                Do you want to cancel this voting
              </Typography>
              <Button
                onClick={handleCallBlockChain}
                variant="outlined"
                color="inherit"
              >
                {tokenListState.loadingStatus ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  'Confirm'
                )}
              </Button>
            </Box>
          </Modal>
        </div>

        <DfyAlert
          type="success"
          alertText={
            <Typography sx={{ color: 'green' }}>Cancel successfully</Typography>
          }
          handle={() => {
            dispatch(
              actions.searchTokenList({
                from_date: moment(fromTime).utc().valueOf(),
                to_date: moment(toTime).utc().valueOf(),
                name: tokenName,
                status: status,
                page,
                size: DEFAULT_SIZE_PAGE,
              }),
            );
          }}
          isOpen={tokenListState.successStatus}
          onClose={handleCloseAlert}
        />

        <DfyAlert
          type="danger"
          alertText={
            <Typography sx={{ color: 'red' }}>Something when wrong</Typography>
          }
          handle={() => {}}
          isOpen={tokenListState.errorStatus}
          onClose={handleCloseError}
        />

        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 9999 }}
          open={tokenListState.loadingUI}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Wrapper>
      <DialogAddANewToken
        open={statusDialogAddToken}
        handleClose={handleCloseDialog}
        data={dataSum}
      />
    </>
  );
};
