import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import schemeServices from 'services/schemeService';
import { createSchemeActions as actions } from '.';

function* getAllToken() {
  try {
    const data = yield call(schemeServices.getTokenScheme);
    const listToken = data.data.data.map(o => ({
      value: o.symbol,
      label: o.symbol,
      tokenAddress: o.tokenAddress,
    }));

    yield put(actions.getAllTokenSuccess(listToken));
  } catch (error: any) {
    console.log(error.response);
    yield put(actions.getAllTokenFail(error.response));
  }
}

export function* createSchemeSaga() {
  yield takeLatest(actions.getAllToken.type, getAllToken);
}
