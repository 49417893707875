import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import queryString from 'query-string';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BasicTable from './components/VestingListTable';
import { useSlice } from './slice';
import { selectVesting } from './slice/selectors';
import CustomizedDialogs from './components/DialogCreateVesting';

const filterParams = queryString.parse(window.location.search);

interface Props {}

const VestingPages = (props: Props) => {
  const { actions } = useSlice();
  const { listDataVesting, dataToken, isLoading } = useSelector(selectVesting);
  const wallet = useSelector(selectWallet).wallet;
  const dispatch = useDispatch();
  const history = useHistory();

  const [paramFilter, setDataFilter] = React.useState<any>({
    wallet: filterParams.wallet ? filterParams.wallet : '',
    token: filterParams.token ? filterParams.token : undefined,
    status: filterParams.status ? filterParams.status : undefined,
    page: 1,
    size: 10,
  });

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setDataFilter({
      ...paramFilter,
      status: event.target.value as string,
      page: 1,
    });
  };

  const handleChangeToken = (e: SelectChangeEvent) => {
    setDataFilter({ ...paramFilter, token: e.target.value as string, page: 1 });
  };

  const handleWallet = e => {
    setDataFilter({ ...paramFilter, wallet: e.target.value, page: 1 });
  };

  //fetch token select
  React.useEffect(() => {
    dispatch(actions.getToken());
  }, []);

  //push param on URL
  React.useEffect(() => {
    history.push(`?${queryString.stringify(paramFilter)}`);

    const param = {
      wallet,
      walletAddress: paramFilter.wallet,
      symbolToken: paramFilter.token !== undefined ? paramFilter.token : '',
      status: paramFilter.status !== undefined ? paramFilter.status : '',
      role_type: 4,
      page: paramFilter.page,
      size: paramFilter.size,
    };

    dispatch(actions.getDataListVestingRequest(param));
  }, [paramFilter]);

  //validate wallet input
  const [textError, setTextError] = React.useState(false);

  React.useEffect(() => {
    if (paramFilter.wallet.length > 100) {
      setTextError(true);
    } else if (paramFilter.wallet.length <= 100) {
      setTextError(false);
    }
  }, [paramFilter.wallet]);

  const getPages = e => {
    setDataFilter({ ...paramFilter, page: e });
  };
  const getSize = e => {
    setDataFilter({ ...paramFilter, size: e, page: 1 });
  };

  return (
    <>
      <Helmet>
        <title>Vesting Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <OneColumnLayout>
        <Paper
          elevation={3}
          sx={{
            padding: '16px',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '20px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', gap: '30px', width: '700px' }}>
              <TextField
                placeholder="Wallet"
                variant="outlined"
                value={paramFilter.wallet}
                sx={{ width: '350px' }}
                onChange={handleWallet}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <FormControl sx={{ width: '100%', maxWidth: '150px' }}>
                <Select
                  value={paramFilter.token}
                  onChange={handleChangeToken}
                  displayEmpty
                  renderValue={
                    paramFilter.token === undefined
                      ? () => <Box>Token</Box>
                      : undefined
                  }
                >
                  <MenuItem value={''}>All</MenuItem>
                  {dataToken.map((i, ix) => (
                    <MenuItem key={ix} value={i.symbol}>
                      {i.symbol}
                    </MenuItem>
                  ))}
                  <MenuItem disabled>
                    <em>Updating more...</em>
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: '100%', maxWidth: '150px' }}>
                <Select
                  value={paramFilter.status}
                  onChange={handleChangeStatus}
                  displayEmpty
                  renderValue={
                    paramFilter.status === undefined
                      ? () => <Box>Status</Box>
                      : undefined
                  }
                >
                  <MenuItem value={''}>All</MenuItem>
                  <MenuItem value={0}>Inactive</MenuItem>
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={2}>Completed</MenuItem>
                  <MenuItem value={3}>Processing transaction</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: 'flex',
                height: '50px',
                gap: '30px',
                width: 'auto',
              }}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(actions.setValuePopUp('without'));
                  dispatch(actions.setPopUp(true));
                  dispatch(actions.getSchemeCreateDataRequest(wallet));
                }}
              >
                Vesting without token
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(actions.setValuePopUp('with'));
                  dispatch(actions.setPopUp(true));
                  dispatch(actions.getSchemeCreateDataRequest(wallet));
                }}
              >
                Vesting with token
              </Button>
            </Box>
          </Box>
          {textError && (
            <Typography
              sx={{ color: 'red', fontSize: '12px', margin: '-18px 0 10px 0' }}
            >
              Maximum length is 100 characters
            </Typography>
          )}
          <BasicTable
            dataRows={listDataVesting}
            getPages={getPages}
            getSize={getSize}
            pages={paramFilter.page}
          />
        </Paper>
      </OneColumnLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 9999 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomizedDialogs />
    </>
  );
};

export default VestingPages;
