import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initState } from '.';

const selectSlice = (state: RootState) => state?.collections || initState;

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectCollections = createSelector(
  [selectSlice],
  state => state.collections,
);

export const selectCollectionParams = createSelector(
  [selectSlice],
  state => state.params,
);

export const selectSize = createSelector(
  [selectSlice],
  state => state.params?.size || 50,
);

export const selectTotal = createSelector([selectSlice], state => state.total);

export const selectCollection = createSelector([selectSlice], state => state);
