import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.importCollection || initialState;

export const selectImportCollection = createSelector(
  [selectSlice],
  state => state,
);

export const selectCheckImportCollection = createSelector(
  [selectSlice],
  state => state.checkCollection,
);

export const selectImportNewCollection = createSelector(
  [selectSlice],
  state => state.importCollection,
);
export const selectImportCollectionError = createSelector(
  [selectSlice],
  state => state.importCollectionEror,
);
