import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { createSchemeSaga } from './saga';
import { CreateSchemeState } from './types';
import { history } from 'app';

export const initialState: CreateSchemeState = {
  listToken: [],
  isLoading: true,
  status: false,
  alertInfor: false,
};

const slice = createSlice({
  name: 'createScheme',
  initialState,
  reducers: {
    getAllToken(state) {},

    getAllTokenSuccess(state, action: PayloadAction<any>) {
      state.listToken = action.payload;
      state.isLoading = false;
      state.status = true;
      state.alertInfor = true;
    },

    getAllTokenFail(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.status = false;
      state.alertInfor = true;
    },

    alertReset(state) {
      state.alertInfor = false;
      history.push('/scheme');
    },
  },
});

export const { actions: createSchemeActions } = slice;

export const useCreateSchemeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: createSchemeSaga });
  return { actions: slice.actions };
};
