import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addNewTokenPageListSaga } from './saga';
import { AddNewTokenPageListState } from './types';

export const initialState: AddNewTokenPageListState = {
  ProgressList: null,
  statusList: null,
  alertInforList: null,
};

const slice = createSlice({
  name: 'addNewTokenPageList',
  initialState,
  reducers: {
    addToken(state, action: PayloadAction<any>) {
      state.ProgressList = true;
      state.alertInforList = null;
      state.statusList = null;
    },
    addTokenScc(state) {
      state.ProgressList = false;
      state.alertInforList = true;
      state.statusList = true;
    },
    addTokenFail(state) {
      state.ProgressList = false;
      state.alertInforList = true;
      state.statusList = false;
    },
    addTokenReset(state) {
      state.ProgressList = null;
      state.alertInforList = null;
      state.statusList = null;
    },
  },
});

export const { actions: addNewTokenPageListActions } = slice;

export const useAddNewTokenPageListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addNewTokenPageListSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddNewTokenPageListSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
