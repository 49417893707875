import apiClient from 'services/apiService';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const authService = {
  login(username, password) {
    return apiClient.request({
      method: 'POST',
      url: 'defi-user-service/public-api/v1.0.0/admins/login',
      data: {
        username,
        password,
      },
    });
  },

  getProfile() {
    return apiClient.request({
      method: 'GET',
      url: '/defi-user-service/api/v1.0.0/admins/profile',
    });
  },

  logout() {
    return apiClient.request({
      baseURL: process.env.REACT_APP_BASE_API_URL_V1,
      method: 'PUT',
      url: '/api/v1.0.0/users/logout',
    });
  },

  refreshToken() {
    return apiClient.request({
      method: 'POST',
      url: '/defi-user-service/public-api/v1.0.0/admins/refresh_token',
      data: {
        refresh_token: this.getRefreshToken(),
      },
    });
  },

  getAccessToken() {
    return localStorage.getItem('access_token');
  },

  getDecodedAccessToken() {
    const token = this.getAccessToken();

    if (token) {
      try {
        return jwtDecode<JwtPayload>(token);
      } catch (err) {
        return null;
      }
    }

    return null;
  },

  setAccessToken(token: string) {
    return localStorage.setItem('access_token', token);
  },

  removeAccessToken() {
    localStorage.removeItem('lastToken');
    return localStorage.removeItem('access_token');
  },

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  },

  setRefreshToken(token: string) {
    return localStorage.setItem('refresh_token', token);
  },

  removeRefreshToken() {
    return localStorage.removeItem('refresh_token');
  },

  // fake
  getNonce(condition: object) {
    return apiClient.get('/defi-user-service/public-api/v1.0.0/users/nonce', {
      params: condition,
    });
  },

  checkValidate(params) {
    return apiClient.request({
      method: 'GET',
      url: `/defi-user-service/public-api/v1.0.0/users/check-validate?email=${params.email}&type=2&walletAddress=${params.walletAddress}`,
    });
  },

  connectWalletAddressWithEmail(params) {
    return apiClient.request({
      method: 'POST',
      url: '/defi-user-service/api/v1.0.0/users/reputation',
      data: params,
    });
  },
  loginWithSignature(params) {
    return apiClient.request({
      method: 'POST',
      url: '/defi-user-service/public-api/v1.0.0/users/login',
      data: params,
    });
  },
};

export default authService;
