/**
 *
 * LoginPage
 *
 */
import * as React from 'react';
import {
  Alert,
  Box,
  Container,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from './slice';
import {
  selectFailure,
  selectIsAuthenticated,
  selectSending,
} from './slice/selectors';
import { useHistory, useLocation } from 'react-router';

interface LoginProps {}

const schema = yup.object().shape({
  username: yup.string().required().email(),
  password: yup.string().required(),
});

const LoginWrapper = styled(Paper)({
  padding: '16px',
  width: '100%',
});

export function LoginPage(props: LoginProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isSending = useSelector(selectSending);
  const isFailure = useSelector(selectFailure);

  // Use the slice we created
  const { actions } = useAuthSlice();

  // Used to dispatch slice actions
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const onSubmit = data => {
    dispatch(actions.signInRequest(data));
  };

  React.useEffect(() => {
    let { from }: any = location.state || { from: { pathname: '/' } };
    if (isAuthenticated) {
      history.replace(from);
    }
  }, [isAuthenticated, history, location]);

  return (
    <>
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <LoginWrapper variant="elevation" elevation={3}>
          <Typography variant="h5">Login</Typography>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              width: '100%',
              maxWidth: '100%',
              '& > :not(style)': { my: 1 },
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              type="email"
              label="Username"
              variant="standard"
              fullWidth
              {...register('username')}
              helperText={errors.username?.message}
              error={!!errors.username}
            />

            <TextField
              label="Password"
              id="password"
              type="password"
              autoComplete="current-password"
              fullWidth
              variant="standard"
              {...register('password')}
              helperText={errors.password?.message}
              error={!!errors.password}
            />

            <LoadingButton
              type="submit"
              loading={isSending}
              variant="contained"
              disabled={isSending}
            >
              Login
            </LoadingButton>
          </Box>
        </LoginWrapper>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isFailure}
          autoHideDuration={6000}
        >
          <Alert severity="error">Incorrect username or password.</Alert>
        </Snackbar>
      </Container>
    </>
  );
}
