import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface DateProps {
  name: string;
  form: UseFormReturn;
  type?: string;
  reset: boolean;
}

export const Date = (props: DateProps) => {
  const { control } = props.form;

  const {
    formState: { errors },
    watch,
  } = props.form;

  const hasError = errors[props.name];

  const dateForm = watch('dateForm');

  const dateTo = watch('dateTo');

  const timeDate = moment();

  const minDate = moment().valueOf();

  const [valueFrom, setvalueFrom] = React.useState<any>(timeDate);

  const [valueTo, setvalueTo] = React.useState<any>(null);

  React.useEffect(() => {
    setvalueFrom(dateForm);
  }, [dateForm]);

  React.useEffect(() => {
    setvalueTo(dateTo);
  }, [dateTo]);

  React.useEffect(() => {
    setvalueFrom(timeDate);
    setvalueTo(null);
  }, [props.reset]);

  return (
    <>
      <Controller
        name={props.name}
        control={control}
        defaultValue={props.type === 'to' ? valueTo : valueFrom}
        render={({ field }) => (
          <FormControl sx={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                {...field}
                minDate={minDate}
                value={props.type === 'to' ? valueTo : valueFrom}
                onChange={e => {
                  field.onChange(e);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={hasError}
                    sx={{
                      width: { xs: '300px', xl: '400px' },
                    }}
                  />
                )}
              />
              <FormHelperText
                sx={{
                  position: 'absolute',
                  top: '54px',
                  left: '0px',
                  margin: '0px',
                  color: '#d32f2f',
                }}
              >
                {hasError?.message}
              </FormHelperText>
            </LocalizationProvider>
          </FormControl>
        )}
      />
    </>
  );
};
