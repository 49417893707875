import apiClient from './apiService';

const collectionServices = {
  getCollection(params: any) {
    return apiClient.request({
      method: 'GET',
      params: params,
      url: '/inventory-svc/public-api/collections',
    });
  },
};

export default collectionServices;
