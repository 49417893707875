const axios = require('axios');

export const pinJSONToIPFS = JSONBody => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
  return axios.post(url, JSONBody, {
    headers: {
      pinata_api_key: 'ac8828bff3bcd1c1b828',
      pinata_secret_api_key:
        'cd1b0dc4478a40abd0b80e127e1184697f6d2f23ed3452326fe92ff3e92324df',
    },
  });
};
