import { PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { call, put, takeLatest } from 'redux-saga/effects';
import vestingServices from 'services/vestingServices';
import {
  checkApprove,
  createApprove,
} from 'services/walletService/approveService/approve';
import abi from 'services/walletService/config/vesting.abi.json';
import Web3 from 'services/walletService/initWeb3';
import * as gasInfo from 'services/walletService/supportService/getGasInformation';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import { Actions as actions } from '.';

function* watchHandleGetToken() {
  try {
    const res = yield vestingServices.getToken();
    yield put(actions.getTokenSuccess(res.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* watchHandleGetDataListVesting(ac) {
  try {
    const res = yield vestingServices.getDataListVesting(
      ac.payload.page,
      ac.payload.size,
      ac.payload.status,
      ac.payload.symbolToken,
      ac.payload.walletAddress,
    );
    yield put(actions.getDataListVestingSuccess(res?.data.rows));
    yield put(actions.setTotalData(res?.data.total));
  } catch (error) {
    console.log(error);
  }
}

function* watchHandleBackUpGetDataListVesting(ac) {
  try {
    const res = yield vestingServices.getDataListVesting(1, 10, '', '', '');
    yield put(actions.getDataListVestingSuccess(res?.data.rows));
    yield put(actions.setTotalData(res?.data.total));
  } catch (error) {
    console.log(error);
  }
}

function* watchHandleGetDetailVesting(ac) {
  const { id } = ac.payload;
  try {
    const res = yield call(vestingServices.getDetailVesting, id);
    yield put(actions.getDetailVestingSuccess(res?.data.item));
  } catch (error) {
    console.log(error);
  }
}

function* watchHandleGetSchemeCreate(ac) {
  try {
    const res = yield call(vestingServices.getDataSchemeCreate, ac.payload);
    yield put(actions.getSchemeCreateDataSuccess(res?.data.rows));
  } catch (error) {
    console.log(error);
  }
}

function* watchHandleCallBC(ac) {
  const {
    addressWallet,
    amountDeposit,
    from,
    schemeId,
    spender,
    startTime,
    tokenRelease,
    totalAmount,
    totalClaimed,
  } = ac.payload;

  const initWeb3 = yield Web3.getInstance;
  const web3: any = initWeb3.getWeb3();
  const vesting = new web3.eth.Contract(abi, spender);

  try {
    const vestingData = vesting.methods.newVestingInformation(
      addressWallet,
      new BigNumber(totalAmount).multipliedBy(10 ** 18).toFixed(),
      new BigNumber(amountDeposit).multipliedBy(10 ** 18).toFixed(),
      new BigNumber(totalClaimed).multipliedBy(10 ** 18).toFixed(),
      Number(schemeId),
      startTime,
      tokenRelease,
      process.env.REACT_APP_ISTESTING,
    );

    const nonce = yield web3.eth.getTransactionCount(from, 'pending');

    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: vestingData.encodeABI(),
    };

    const gasData = yield gasInfo.getGasInformation(tx);

    const txVesting = {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };

    const receipt = yield signAndSendTx(txVesting);
    yield put(actions.callBCSuccess(receipt));
  } catch (error) {
    yield put(actions.failureCallBC(true));
    console.log(error);
  }
}

function* watchHandleCallApprove(ac) {
  const { curAddress, tokenSymbol, toAddress, amount } = ac.payload;

  try {
    const tx = yield createApprove(curAddress, tokenSymbol, toAddress, amount);
    const receipt = yield signAndSendTx(tx);
    yield put(actions.callApproveSuccess(receipt));
  } catch (error) {
    console.log(error);
    yield put(actions.callApproveFailure(false));
  }
}

function* watchHandleCallCheckApprove(ac) {
  const { curAddress, tokenSymbol, toAddress, amount } = ac.payload;

  try {
    const res = yield checkApprove(curAddress, tokenSymbol, toAddress, amount);
    const resDiv18: number = Number(
      new BigNumber(res).dividedBy(10 ** 18).toFixed(),
    );
    yield put(actions.callCheckApproveSuccess(resDiv18));
    yield put(actions.setPopUpConfirm(true));
  } catch (error) {}
}

function* watchPostDataVesting(a: PayloadAction<any>) {
  const {
    addressWallet,
    schemeId,
    totalAmount,
    vestedAmount,
    isHasToken,
    txnHash,
    name,
    startedTime,
    tokenReleaseType,
  } = a.payload;

  try {
    yield vestingServices.postDataVessting(
      addressWallet,
      schemeId,
      totalAmount,
      vestedAmount,
      isHasToken,
      txnHash,
      name,
      startedTime,
      tokenReleaseType,
    );
  } catch (error) {}
}

function* watchCallBCActive(ac: PayloadAction<any>) {
  const { amount, from, spender, vestingBcId, id } = ac.payload;
  const initWeb3 = yield Web3.getInstance;
  const web3: any = initWeb3.getWeb3();
  const active = new web3.eth.Contract(abi, spender);

  try {
    const activeData = active.methods.addToken(
      new BigNumber(amount).multipliedBy(10 ** 18).toFixed(),
      vestingBcId,
    );
    const nonce = yield web3.eth.getTransactionCount(from, 'pending');

    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: activeData.encodeABI(),
    };

    const gasData = yield gasInfo.getGasInformation(tx);

    const txActive = {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };

    const receipt = yield signAndSendTx(txActive);
    yield put(actions.callActiveBCSuccess(receipt));
    yield vestingServices.putIdActive(id);
  } catch (error) {
    yield put(actions.callActiveBCFailure(true));
  }
}

export function* vestingSaga() {
  yield takeLatest(actions.getToken.type, watchHandleGetToken);
  yield takeLatest(
    actions.getDataListVestingRequest.type,
    watchHandleGetDataListVesting,
  );
  yield takeLatest(
    actions.getDetailVestingRequest.type,
    watchHandleGetDetailVesting,
  );
  yield takeLatest(
    actions.getSchemeCreateDataRequest.type,
    watchHandleGetSchemeCreate,
  );
  yield takeLatest(actions.callBCRequest.type, watchHandleCallBC);
  yield takeLatest(actions.callApproveRequest.type, watchHandleCallApprove);
  yield takeLatest(
    actions.callCheckApproveRequest.type,
    watchHandleCallCheckApprove,
  );
  yield takeLatest(actions.postVesting.type, watchPostDataVesting);
  yield takeLatest(actions.callActiveBCRequest.type, watchCallBCActive);
  yield takeLatest(
    actions.backupDataListVestingTable.type,
    watchHandleBackUpGetDataListVesting,
  );
}
