import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { importCollectionSaga } from './saga';
import { ImportCollectionState } from './types';

export const initialState: ImportCollectionState = {
  checkCollection: null,
  importCollection: null,
  importCollectionEror: '',
};

const slice = createSlice({
  name: 'importCollection',
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) {},
    checkCollectionRequest(state, action: PayloadAction<any>) {},

    checkCollectionSuccess(state, action: PayloadAction<any>) {
      state.checkCollection = false;
    },

    checkCollectionFailure(state, action: PayloadAction<any>) {
      state.checkCollection = true;
    },
    importCollectionRequest(state, action: PayloadAction<any>) {
      state.importCollection = true;
    },

    importCollectionSuccess(state, action: PayloadAction<any>) {
      state.importCollection = false;
    },

    importCollectionFailure(state, action: PayloadAction<any>) {
      state.importCollectionEror = action.payload;
    },
    clearImportCollectionFailure(state) {
      state.importCollectionEror = '';
    },
  },
});

export const { actions: importCollectionActions } = slice;

export const useImportCollectionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: importCollectionSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useImportCollectionSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
