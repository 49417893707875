import { TextField } from '@mui/material';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

interface InforTokenProps {
  placeholder?: string;
  name: string;
  form: UseFormReturn;
  type?: string;
  style?: any;
  defaultValue?: string;
}

export const InforToken = (props: InforTokenProps) => {
  const { control } = props.form;

  const {
    formState: { errors },
    watch,
  } = props.form;

  const hasError = errors[props.name];

  const styleTF = {
    ...props.style,
    '& p': {
      position: 'absolute',
      top: '40px',
      left: 0,
      margin: 0,
    },
  };

  const watchs = watch(props.name);

  return (
    <>
      <Controller
        name={props.name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            type={props.type ? 'number' : 'text'}
            autoComplete="off"
            error={Boolean(hasError)}
            helperText={hasError?.message}
            placeholder={props.placeholder}
            variant="outlined"
            sx={styleTF}
            size="small"
          />
        )}
      />
    </>
  );
};
