import apiClient from './apiService';

const apiCheckCollection = {
  check(collection_address) {
    return apiClient.request({
      method: 'GET',
      url: `nft-market-svc/api/admin/collection/check-available/${collection_address}`,
    });
  },
  importNewCollection(collection_address) {
    return apiClient.request({
      method: 'POST',
      url: `/defi-import-service/api/collection`,
      data: {
        collection_address: collection_address,
      },
    });
  },
};

export default apiCheckCollection;
