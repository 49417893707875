import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import Web3 from 'services/walletService/initWeb3';
import { schemeAddActions as actions } from '.';
import abi from 'services/walletService/config/vesting.abi.json';
import * as gasInfo from 'services/walletService/supportService/getGasInformation';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import schemeServices from 'services/schemeService';
import { DurationType } from 'app/pages/Scheme/slice/types';
import { selectGlocalState } from 'app/pages/GlobalStateWrapper/slice/selectors';
import { useSelector } from 'react-redux';

function* handleAddNewScheme(action) {
  try {
    const typeDropDuration =
      action.payload.durationType === 'Week'
        ? 7 / Number(process.env.REACT_APP_SCHEME_DURATION)
        : action.payload.durationType === 'Month'
        ? 30 / Number(process.env.REACT_APP_SCHEME_DURATION)
        : 90 / Number(process.env.REACT_APP_SCHEME_DURATION);

    const data = {
      name: action.payload.name,
      vestTime: action.payload.vestTime,
      cliffTime: action.payload.cliffTime * 24 * 60 * 60 * typeDropDuration,
      durationTime:
        action.payload.durationTime * 24 * 60 * 60 * typeDropDuration,
      periodTime:
        (action.payload.periodTime * 24 * 60 * 60) /
        Number(process.env.REACT_APP_SCHEME_DURATION),
      tokenAddress: action.payload.tokenAddress,
    };

    const from = action.payload.wallet;

    const spender = action.payload.spender;

    //call BC start
    const initWeb3 = yield Web3.getInstance;

    const web3: any = initWeb3.getWeb3();

    const addNewSchemeBc = new web3.eth.Contract(abi, spender);

    const nonce = yield web3.eth.getTransactionCount(from, 'pending');

    const addNewSchemeData = addNewSchemeBc.methods.newSchemeInformation(
      data.name,
      data.vestTime,
      data.cliffTime,
      data.durationTime - data.cliffTime,
      data.periodTime,
      data.tokenAddress,
    );

    // return;
    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: addNewSchemeData.encodeABI(),
    };

    const gasData = yield gasInfo.getGasInformation(tx);

    const txAddNewScheme = {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };

    const receipt = yield signAndSendTx(txAddNewScheme);

    const dataApi = {
      cliffTime: action.payload.cliffTime,
      cryptoAssetSymbol: action.payload.cryptoAssetSymbol,
      durationTime: action.payload.durationTime,
      durationType:
        action.payload.durationType === 'Week'
          ? DurationType.WEEKS
          : action.payload.durationType === 'Month'
          ? DurationType.MONTHS
          : DurationType.QUARTER,
      schemeName: data.name,
      tokenAddress: action.payload.tokenAddress,
      txnHash: receipt.transactionHash,
    };

    yield call(schemeServices.createScheme, dataApi);

    //call BC end

    yield put(actions.addNewSchemeSuccess(dataApi));
  } catch (error) {
    yield put(actions.addNewSchemeFail());
    // yield put(actions.addNewSchemeReset());
  }
}

export function* schemeAddSaga() {
  yield takeLatest(actions.addNewScheme.type, handleAddNewScheme);
}
