export interface CollectionState {
  isLoading: boolean;
  collections: any[];
  total: number;
  params?: CollectionGetParams;
}

export interface CollectionGetParams {
  name: string;
  collection_type: string;
  owner_account: string;
  page: number;
  size: number;
}

export interface CollectionResponse {
  rc: number;
  rd: string;
  total: number;
  rows: any[];
}

export interface CollectionError {
  rc: number;
  rd: string;
}
export enum Chain {
  BinanceSmartChain,
}
