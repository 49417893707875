import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAuctions } from '../../slice/selectors';
import { useAuctionSlice } from '../../slice';
import * as React from 'react';
import { useEffect, useState } from 'react';
import DateTimeHelper from '../../../../../utils/date_time_helper';
import AppUtils from '../../../../../utils/app_utils';
import { Backdrop, CircularProgress, Pagination } from '@mui/material';
import HideDialog from './HideNFTDialog';
import ActiveAuctionDetailDialog from './ActiveAuctionDetailDialog';

export default function ActiveAuction() {
  const [page, setPage] = useState<number>(1);
  const activeAuction = useSelector(selectActiveAuctions);
  const { actions } = useAuctionSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchActiveAuction(activeAuction.params));
  }, []);

  const onDeleteAuctionClicked = (e: any) => {
    setItemHideDialog(e);
    dispatch(actions.showFormHidden(true));
  };

  const onViewAuctionClicked = (e: any) => {
    console.log('onViewAuctionClicked: ' + JSON.stringify(e));
    setItemDetailDialog(e);
    setShowDetailDialog(true);
  };

  const handlePageChange = (e: any, page: number) => {
    setPage(page);
    let p = JSON.parse(JSON.stringify(activeAuction.params));
    p.page = page;
    dispatch(actions.fetchActiveAuction(p));
  };

  /**
   * For hide
   */
  const [itemHideDialog, setItemHideDialog] = useState<any>(null);
  const closeHideDialog = (value: boolean) => {
    dispatch(actions.showFormHidden(value));
  };

  /**
   * For details
   */
  const [showDetailDialog, setShowDetailDialog] = useState<boolean>(false);
  const [itemDetailDialog, setItemDetailDialog] = useState<any>(null);
  const closeDetailDialog = (value: boolean) => {
    setShowDetailDialog(value);
  };

  const handleOpenDetailAuction = (id, auctionID, type, market_type) => {
    if (market_type === 0) {
      window.open(
        `${process.env.REACT_APP_URL_MARKETPLACE}nft/${id}?status=${market_type}&type=${type}`,
      );
    } else if (market_type === 1) {
      window.open(
        `${process.env.REACT_APP_URL_MARKETPLACE}nft/${id}?market_id=${auctionID}&status=${market_type}&type=${type}`,
      );
    } else if (market_type === 2) {
      window.open(
        `${process.env.REACT_APP_URL_MARKETPLACE}nft/${id}?auction_id=${auctionID}&status=${market_type}&type=${type}`,
      );
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: '30px' }}>
        <Table sx={{ width: '100%' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>NFT</TableCell>
              <TableCell align="left">Reserve price</TableCell>
              <TableCell align="left">Auction Duration</TableCell>
              <TableCell align="left">Current bid</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeAuction.rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                }}
              >
                <TableCell
                  onClick={() =>
                    handleOpenDetailAuction(
                      row.id_ref,
                      row.id,
                      row.type,
                      row.market_type,
                    )
                  }
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  onClick={() =>
                    handleOpenDetailAuction(
                      row.id_ref,
                      row.id,
                      row.type,
                      row.market_type,
                    )
                  }
                >
                  {row.name}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() =>
                    handleOpenDetailAuction(
                      row.id_ref,
                      row.id,
                      row.type,
                      row.market_type,
                    )
                  }
                >
                  {AppUtils.formatNumberEx(row.reserve_price)}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() =>
                    handleOpenDetailAuction(
                      row.id_ref,
                      row.id,
                      row.type,
                      row.market_type,
                    )
                  }
                >
                  {DateTimeHelper.calcRemainTime(row.start_time, row.end_time)}
                  <br />
                  From {DateTimeHelper.convertTime2(row.start_time)}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() =>
                    handleOpenDetailAuction(
                      row.id_ref,
                      row.id,
                      row.type,
                      row.market_type,
                    )
                  }
                >
                  {AppUtils.formatNumberEx(row.current_price)}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={() =>
                    handleOpenDetailAuction(
                      row.id_ref,
                      row.id,
                      row.type,
                      row.market_type,
                    )
                  }
                >
                  {row.type === 0 ? 'Soft NFT' : 'Hard NFT'}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => onViewAuctionClicked(row)}
                    aria-label="view"
                    size="small"
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => onDeleteAuctionClicked(row)}
                    aria-label="delete"
                    size="small"
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}
        count={AppUtils.calcTotalPage(
          activeAuction.params.size,
          activeAuction.total,
        )}
        variant="outlined"
        shape="rounded"
      />
      <HideDialog
        callback={closeHideDialog}
        show={activeAuction.showHiddenForm}
        item={itemHideDialog}
      />
      <ActiveAuctionDetailDialog
        callback={closeDetailDialog}
        show={showDetailDialog}
        item={itemDetailDialog}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 100000 }}
        open={activeAuction.isLoading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
