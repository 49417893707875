import { TextField } from '@mui/material';
import { styled } from '@mui/system';

interface DfyInputFieldPassWordStyleProps {
  styleDisabled: boolean;
}

export const DfyInputFieldStyle = styled(TextField, {
  shouldForwardProp: prop => prop !== 'styleDisabled',
})<DfyInputFieldPassWordStyleProps>(() => ({
  width: '100%',
  height: 46,
  fontStyle: 'normal',
  fontWeight: 'normal',
  borderRadius: '22px',
  input: {
    padding: '10.5px 16px 10.5px 20px',
    '&::placeholder': {
      opacity: 1,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
  },
  fieldset: {
    borderRadius: '5px',
    borderWidth: '1px !important',
  },
  p: {
    marginLeft: 0,
    marginTop: '2px',
    transition: 'all 1s ease',
  },

  'input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
  },

  'input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
  },

  'input[type=number] ': {
    '-moz-appearance': 'textfield',
  },
}));
