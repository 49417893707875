import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { vestingSaga } from './saga';
import { ParamDeatailVesting, RowDataVesting, VestingState } from './types';

export const initialState: VestingState = {
  listDataVesting: [
    {
      id: '61e657182fa85038f081820f',
      walletAddress: '0xc123',
      totalAmountVesting: 29000,
      currentAmountVesting: 0,
      claimedTotalAmount: 0,
      startedTime: 0,
      endedTime: 0,
      status: 0,
      txnHash: '0xc22',
      isHasToken: false,
      scheme: {
        id: '61e6193c2fa85031fc073266',
        schemeName: 'Default 4',
        cryptoAssetSymbol: '',
        durationTime: 1,
        durationType: 0,
        afterStartTime: 3,
        afterStartType: 0,
      },
      vestedAmount: 0,
      vestingBcId: '',
    },
  ],
  dataToken: [{ symbol: '' }],
  isLoading: false,
  detailDataVesting: {
    id: '',
    walletAddress: '',
    totalAmountVesting: 0,
    currentAmountVesting: 0,
    claimedTotalAmount: 0,
    startedTime: 0,
    endedTime: 0,
    status: 0,
    txnHash: '',
    isHasToken: false,
    scheme: {
      id: '',
      schemeName: '',
      cryptoAssetSymbol: '',
      durationTime: 0,
      durationType: 0,
      afterStartTime: 0,
      afterStartType: 0,
    },
    vestedAmount: 0,
    vestingBcId: '',
  },
  isLoadingDialog: false,
  totalData: 0,
  isPopupCreateVesting: false,
  namePopUpCreateVesting: '',
  dataSchemeCreate: [{}],
  receiptBC: '',
  isPopupConfirm: false,
  receiptApprove: '',
  isLoadingApprove: false,
  isLockedApprove: false,
  isTurnOnButtonCreate: true,
  resDiv: 0,
  isLoadingCallBC: false,
  turnOnPopupSuccess: false,
  isFailureCallBC: false,
  isFailureApprove: false,
  isSwitchPopUpActive: false,
  isLoadingButtonActive: false,
  isSwitchPopUpActiveSuccess: false,
  isSwitchPopUpActiveFailure: false,
  isStatusActive: false,
  receiptActive: '',
  isPopUpActiveSuccess: false,
  isPopUpActiveFailure: false,
};

const vestingSlice = createSlice({
  name: 'vestingSlice',
  initialState,
  reducers: {
    getToken(s) {},
    getTokenSuccess(st, ac: PayloadAction<any>) {
      st.dataToken = ac.payload;
    },

    setActive(s, a) {
      s.isStatusActive = a.payload;
    },

    turnOffPopupDetail(s, a) {
      s.isLoadingDialog = a.payload;
    },

    closecfvsting(s, a) {
      s.isPopupConfirm = a.payload;
    },

    getDataListVestingRequest(s, a) {
      s.isLoading = true;
    },
    getDataListVestingSuccess(s, a) {
      s.isLoading = false;
      s.listDataVesting = a.payload;
    },
    getDetailVestingRequest(s, a: PayloadAction<ParamDeatailVesting>) {
      s.isLoading = true;
    },

    getDetailVestingSuccess(s, a: PayloadAction<RowDataVesting>) {
      s.isLoading = false;
      s.detailDataVesting = a.payload;
      if (s.isStatusActive) {
        s.isLoadingDialog = false;
      } else {
        s.isLoadingDialog = true;
      }
    },

    closeDialog(s, a: PayloadAction<boolean>) {
      s.isLoadingDialog = a.payload;
    },

    setTotalData(s, a: PayloadAction<number>) {
      s.totalData = a.payload;
    },

    setPopUp(s, a: PayloadAction<boolean>) {
      s.isPopupCreateVesting = a.payload;
    },

    setPopUpConfirm(s, a: PayloadAction<boolean>) {
      s.isPopupConfirm = a.payload;
    },

    closeDialogConfirm(s, a: PayloadAction<boolean>) {
      s.isPopupConfirm = a.payload;
    },

    setValuePopUp(s, a: PayloadAction<string>) {
      s.namePopUpCreateVesting = a.payload;
    },

    getSchemeCreateDataRequest(s, a) {},

    getSchemeCreateDataSuccess(s, a) {
      s.dataSchemeCreate = a.payload;
    },

    callBCRequest(s, a) {
      s.isLoadingCallBC = true;
    },

    callBCSuccess(s, a) {
      s.isLoadingCallBC = false;
      s.isPopupCreateVesting = false;
      s.isPopupConfirm = false;
      s.turnOnPopupSuccess = true;
      s.receiptBC = a.payload;
    },

    callApproveRequest(s, a) {
      s.isLoadingApprove = true;
    },

    callApproveSuccess(s, a) {
      s.isLoadingApprove = false;
      s.isTurnOnButtonCreate = false;
      s.isLockedApprove = true;
      s.receiptApprove = a.payload;
    },

    callApproveFailure(s, a) {
      s.isFailureApprove = true;
      s.isLoadingApprove = a.payload;
    },

    callCheckApproveRequest(s, a) {
      s.isLoading = true;
    },

    callCheckApproveSuccess(s, a) {
      s.isLoading = false;
      s.resDiv = a.payload;
    },

    setButton(s, a) {
      s.isTurnOnButtonCreate = a.payload;
    },

    resetRedux(s) {
      s.dataToken = [{ symbol: '' }];
      s.isLoading = false;
      s.detailDataVesting = {
        id: '',
        walletAddress: '',
        totalAmountVesting: 0,
        currentAmountVesting: 0,
        claimedTotalAmount: 0,
        startedTime: 0,
        endedTime: 0,
        status: 0,
        txnHash: '',
        isHasToken: false,
        scheme: {
          id: '',
          schemeName: '',
          cryptoAssetSymbol: '',
          durationTime: 0,
          durationType: 0,
          afterStartTime: 0,
          afterStartType: 0,
        },
        vestedAmount: 0,
        vestingBcId: '',
      };
      s.isLoadingDialog = false;
      s.totalData = 0;
      s.isPopupCreateVesting = false;
      s.namePopUpCreateVesting = '';
      s.dataSchemeCreate = [{}];
      s.receiptBC = '';
      s.isPopupConfirm = false;
      s.receiptApprove = '';
      s.isLoadingApprove = false;
      s.isLockedApprove = false;
      s.isTurnOnButtonCreate = true;

      s.isLoadingCallBC = false;
      s.turnOnPopupSuccess = false;
      s.isFailureApprove = false;
      s.isFailureCallBC = false;
    },

    failureCallBC(s, a) {
      s.isFailureCallBC = a.payload;
    },

    failureApprove(s, a) {
      s.isFailureApprove = a.payload;
    },

    setDataWhenError(s, a) {
      s.isLoadingCallBC = a.payload;
      s.isFailureCallBC = a.payload;
      s.isLoadingApprove = a.payload;
    },

    postVesting(s, a) {},

    turnOnPopupActive(s, a) {
      s.isSwitchPopUpActive = a.payload;
    },

    turnOffPopupActive(s, a) {
      s.isSwitchPopUpActive = a.payload;
      s.isStatusActive = false;
    },

    callActiveBCRequest(s, a) {
      s.isLoadingButtonActive = true;
    },

    callActiveBCSuccess(s, a) {
      s.isLoadingButtonActive = false;
      s.receiptActive = a.payload;
      s.isPopUpActiveSuccess = true;
    },

    callActiveBCFailure(s, a) {
      s.isLoadingButtonActive = false;
      s.isLoadingButtonActive = false;
      s.isPopUpActiveFailure = a.payload;
    },

    closePopUpActiveError(s, a) {
      s.isPopUpActiveFailure = a.payload;
      s.isPopUpActiveSuccess = a.payload;
    },

    closePopUpActiveSuccess(s, a) {
      s.isSwitchPopUpActive = a.payload;
      s.isPopUpActiveSuccess = a.payload;
      s.isPopUpActiveFailure = a.payload;
    },

    backupDataListVestingTable(s) {
      s.isStatusActive = false;
    },
  },
});

export const { actions: Actions } = vestingSlice;

export const useSlice = () => {
  useInjectReducer({ key: vestingSlice.name, reducer: vestingSlice.reducer });
  useInjectSaga({ key: vestingSlice.name, saga: vestingSaga });
  return { actions: vestingSlice.actions };
};
