import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { boolean } from 'yup/lib/locale';
import { addNewTokenSaga } from './saga';
import { AddNewTokenState } from './types';

export const initialState: AddNewTokenState = {
  Progress: null,
  alertInfor: null,
  status: null,
  ProgressList: null,
  alertInforList: null,
  statusList: null,
};

const slice = createSlice({
  name: 'addNewToken',
  initialState,
  reducers: {
    addNewToken(state, action) {
      state.Progress = true;
      state.alertInfor = null;
      state.status = null;
    },
    addNewTokenSuc(state) {
      state.Progress = false;
      state.alertInfor = true;
      state.status = true;
    },
    addNewTokenFail(state) {
      state.Progress = false;
      state.alertInfor = true;
      state.status = false;
    },
    alertInforReset(state) {
      state.alertInfor = false;
    },
    reset(state) {
      state.Progress = null;
      state.alertInfor = null;
      state.status = null;
      state.ProgressList = null;
      state.alertInforList = null;
      state.statusList = null;
    },
    addNewTokenList(state, action) {
      state.ProgressList = true;
      state.alertInforList = null;
      state.statusList = null;
    },
    addNewTokenSucList(state) {
      state.ProgressList = false;
      state.alertInforList = true;
      state.statusList = true;
    },
    addNewTokenFailList(state) {
      state.ProgressList = false;
      state.alertInforList = true;
      state.statusList = false;
    },
  },
});

export const { actions } = slice;

export const useAddNewTokenSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: addNewTokenSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddNewTokenSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
