import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Saga } from './saga';
import { State, ViewOfficalPawnShopProps } from './types';

export const initialState: State = {
  ListOffcialPawn: [
    {
      id: 0,
      name: '',
      email: '',
      addedDate: 0,
      token: 0,
      position: 0,
      isDelete: false,
    },
  ],
};

const slice = createSlice({
  name: 'homepagePawn',
  initialState,
  reducers: {
    getDataOffcialPawnRequire(state) {},
    getDataOffcialPawnSuccess(
      state,
      action: PayloadAction<ViewOfficalPawnShopProps[]>,
    ) {
      state.ListOffcialPawn = action.payload;
    },

    UpPositionOffcialPawnAction(state, action: PayloadAction<number>) {
      if (action.payload === 1) {
      } else {
        state.ListOffcialPawn.sort(
          (a: ViewOfficalPawnShopProps, b: ViewOfficalPawnShopProps) =>
            a.position - b.position,
        ).map((item, index) => {
          if (action.payload === index + 1) {
            item.position = item.position - 1;
          }
          if (action.payload === index + 2) {
            item.position = item.position + 1;
          }
          return item;
        });
      }
    },
    DownPositionOffcialPawnAction(state, action: PayloadAction<number>) {
      if (action.payload === state.ListOffcialPawn.length) {
      } else {
        state.ListOffcialPawn.sort(
          (a: ViewOfficalPawnShopProps, b: ViewOfficalPawnShopProps) =>
            a.position - b.position,
        ).map((item, index) => {
          if (action.payload === index + 1) {
            item.position = item.position + 1;
          }
          if (action.payload === index) {
            item.position = item.position - 1;
          }
          return item;
        });
      }
    },
    deleteDataOffcialPawnItem(state, action: PayloadAction<Number>) {
      state.ListOffcialPawn.map(item => {
        if (action.payload === item.id) {
          item.isDelete = true;
        } else {
          return item;
        }
      });
    },
  },
});

export const { actions: Actions } = slice;

export const HomePawn = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: Saga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
