import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { schemeSaga } from './saga';
import { SchemeState } from './types';

export const initialState: SchemeState = {
  filterScheme: {
    name: '',
    status: '',
    token: '',
  },
  dataScheme: [
    {
      id: 0,
      schemeName: '',
      durationTime: 0,
      durationType: 0,
      afterStartTime: 0,
      afterStartType: null,
      status: null,
      isDeleted: false,
      crypto_asset: {
        id: 0,
        symbol: '',
      },
    },
  ],

  params: {
    page: 0,
    size: 10,
  },

  listTokens: [
    {
      symbol: '',
      tokenAddress: '',
    },
  ],

  isFalse: false,
  reRender: false,
};

const slice = createSlice({
  name: 'scheme',
  initialState,
  reducers: {
    fetchDataScheme(state, action: PayloadAction<any>) {},

    fetchDataSchemeSuccess(state, action: PayloadAction<any>) {
      state.dataScheme = action.payload;
      state.isFalse = false;
    },

    fetchDataSchemeFail(state, action: PayloadAction<any>) {
      state.isFalse = action.payload;
    },

    getAllToken(state) {},

    getAllTokenSuccess(state, action: PayloadAction<any>) {
      state.listTokens = action.payload;
    },

    getAllTokenFail(state, action: PayloadAction<any>) {
      state.isFalse = action.payload;
    },

    changeStatusScheme(state, action: PayloadAction<any>) {},

    changeStatusSchemeSuccess(state) {
      state.reRender = !state.reRender;
    },

    changeStatusFail(state, action: PayloadAction<any>) {
      state.isFalse = action.payload;
    },
  },
});

export const { actions } = slice;

export const useSchemeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: schemeSaga });
  return { actions: slice.actions };
};
