import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initState } from '.';

const selectSlice = (state: RootState) => state?.auction || initState;

export const selectIsLoading = createSelector(
  [selectSlice],
  state =>
    state.active.isLoading || state.ended.isLoading || state.waiting.isLoading,
);

export const selectActiveAuctions = createSelector(
  [selectSlice],
  state => state.active,
);

export const selectEndedAuctions = createSelector(
  [selectSlice],
  state => state.ended,
);

export const selectWaitingAuctions = createSelector(
  [selectSlice],
  state => state.waiting,
);
