import Web3 from '../initWeb3';
import { store } from 'index';
import { walletActions as ac } from 'app/components/Wallet/slice';
import authService from 'services/authService';
import { useAuthSlice } from 'app/pages/LoginPage/slice';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { loginActions as actions } from 'app/pages/LoginPage/slice/index';

export const loginSignature = async (walletAddress: string) => {
  store.dispatch(ac.storeWallet(walletAddress));

  if (_isEmpty(localStorage.getItem('lastToken'))) {
    const data = await authService.getNonce({ walletAddress });
    const signature = await signNonce(walletAddress, _get(data, 'data.data'));
    const loginParams = {
      walletAddress,
      signature,
    };

    store.dispatch(actions.signInSignatureRequest(loginParams));
  }
};

export const signNonce = async (address, nonce) => {
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();
  let signature = '';
  try {
    signature = await web3.eth.personal.sign(String(nonce), address);
  } catch (error) {
    // store.dispatch(headerStateActions.logOutReq());
    // store.dispatch(walletAction(null));
    localStorage.removeItem('StoreWallet');
    localStorage.removeItem('extensionName');
  }
  return signature;
};
