import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { editVotingSaga } from './saga';
import { editVotingState } from './types';

export const initialState: editVotingState = {
  Progress: null,
  alertInfor: null,
  status: null,
  tokenDetail: {},
  votingStatus: false,
};

const slice = createSlice({
  name: 'editVoting',
  initialState,
  reducers: {
    editVoting(state, action) {},

    callBlockChain(state, action) {},

    setTokenDetail(state, action) {
      state.tokenDetail = action.payload;
    },

    setVotingStatus(state, action) {
      state.votingStatus = action.payload;
    },

    alertInforReset(state) {
      state.alertInfor = false;
    },

    reset(state) {
      state.Progress = null;
      state.alertInfor = null;
      state.status = null;
    },
  },
});

export const { actions } = slice;

export const useAddNewTokenSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: editVotingSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddNewTokenSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
