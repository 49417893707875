import { FormControl, FormHelperText, IconButton, styled } from '@mui/material';
import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Controller, UseFormReturn } from 'react-hook-form';

interface UploadFileProps {
  name: string;
  form: UseFormReturn;
}

const Input = styled('input')({
  display: 'none',
});

export const UploadFile = (props: UploadFileProps) => {
  const { control } = props.form;

  const {
    formState: { errors },
    watch,
  } = props.form;

  const hasError = errors[props.name];

  const handleFileChange = e => {
    let url = URL.createObjectURL(e.target.files[0]);

    const preloadImage = (src: string) => {
      return new Promise(resolve => {
        const img = new Image();

        img.onload = function () {
          if (img.height === 64 && img.width === 64) {
            resolve({
              url: url,
              data: e.target.files[0],
            });
          } else {
            resolve(null);
          }
        };

        img.src = src;
      });
    };

    return preloadImage(url);
  };

  return (
    <>
      <Controller
        name={props.name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <FormControl sx={{ width: '100%' }}>
            <label
              {...field}
              htmlFor="icon-button-file"
              onChange={e => handleFileChange(e).then(el => field.onChange(el))}
            >
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={handleFileChange}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <CloudUploadIcon />
              </IconButton>
            </label>
            <FormHelperText
              sx={{
                position: 'absolute',
                top: '54px',
                left: '0px',
                margin: '0px',
                color: '#d32f2f',
              }}
            >
              {hasError?.message}
            </FormHelperText>
          </FormControl>
        )}
      />
    </>
  );
};
