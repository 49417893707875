import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import authService from 'services/authService';
import { RootState } from 'types';
import { loginActions as actions } from '.';

function* signInHandler(action) {
  const { username, password } = action.payload;
  try {
    const response = yield call(authService.login, username, password);
    yield put(actions.signInSuccess(response?.data));
    yield put(actions.fetchUserProfile());
  } catch (err: any) {
    yield put(actions.signInFailure(err.response.data));
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(authService.getProfile);
    yield put(actions.fetchUserProfileSuccess(response.data?.data));
  } catch (err: any) {
    yield put(actions.fetchUserProfileFailure(err.response?.data));
  }
}

function* signInHandlerSignature(action) {
  const isLogIn = yield select(
    (state: RootState) => state.globalState?.loggedInUser,
  );

  let response;
  try {
    response = yield call(authService.loginWithSignature, action.payload);

    // yield put(actions.signInSuccess(response.data));
    yield delay(250);
    yield put(actions.getUserProfileSignature(response.data));
    //
    if (isLogIn) {
      yield put(actions.setLastToken(response.data.access_token));
    } else {
      yield put(actions.setLastToken(response.data.access_token));
      // yield put(actions.setAccessToken(response.data.access_token));
    }
  } catch (err: any) {
    yield put(actions.errorConnectWallet(true));
  }
}

export function* loginSaga() {
  yield takeLatest(actions.signInRequest.type, signInHandler);
  yield takeLatest(actions.fetchUserProfile.type, fetchUserProfile);
  yield takeLatest(actions.signInSignatureRequest.type, signInHandlerSignature);
}
