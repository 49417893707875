import {
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShortenWalletAddress } from 'utils/formatWalletAddress';
import { useSlice } from '../../slice';
import { selectVesting } from '../../slice/selectors';
import { RowDataVesting, StatusVesting } from '../../slice/types';
import CustomizedDialogs from '../DialogDetailVesting';
import DialogActivePlan from '../DiglogActivePlan';
import { formatNumberText, FunctionFormatNum } from 'utils/formatNumber';

interface DataVestingRows {
  dataRows: Array<RowDataVesting>;
  getPages: Function;
  getSize: Function;
  pages: number;
}

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const Root = styled('div')(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : grey[100]};
  }
  `,
);

const renderDurationType = durationType => {
  switch (durationType) {
    case 0:
      return 'Days';
    case 1:
      return 'Weeks';
    case 2:
      return 'Months';
    case 3:
      return 'Quater';
    case 4:
      return 'Year';

    default:
      break;
  }
};

export const renderStatus = status => {
  switch (status) {
    case StatusVesting.STATUS_INACTIVE:
      return 'Inactive';
    case StatusVesting.STATUS_ACTIVE:
      return 'Active';
    case StatusVesting.STATUS_COMPLETED:
      return 'Completed';
    case StatusVesting.STATUS_PROCESSING_TRANSACTION:
      return 'Processing transaction';
    default:
      break;
  }
};

const convertMillisecondToDate = (startTime, endTime) => {
  return `${moment.unix(startTime / 1000).format('DD/MM/YYYY')} - ${moment
    .unix(endTime / 1000)
    .format('DD/MM/YYYY')}`;
};

export const renderTime = (
  status,
  durationType,
  durationTime,
  startTime,
  endTime,
) => {
  switch (status) {
    case StatusVesting.STATUS_INACTIVE:
      return `${durationTime} ${renderDurationType(durationType)}`;
    case StatusVesting.STATUS_ACTIVE:
      return convertMillisecondToDate(startTime, endTime);

    default:
      break;
  }
};

export default function UnstyledTable(props: DataVestingRows) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function createData(
    stt: number,
    wallet: string,
    scheme: string,
    totalVesting: number,
    currentVesting: number,
    endedTime: number | null,
    status: number,
    durationTime: number,
    durationType: number,
    startedTime: number,
    id: string,
    token: string,
  ) {
    return {
      stt,
      wallet,
      scheme,
      totalVesting,
      currentVesting,
      endedTime,
      status,
      durationTime,
      durationType,
      startedTime,
      id,
      token,
    };
  }

  const rows = props.dataRows?.map((item, index) =>
    createData(
      index + 1,
      item.walletAddress,
      item.scheme.schemeName,
      item.totalAmountVesting,
      item.currentAmountVesting,
      item.endedTime,
      item.status,
      item.scheme.durationTime,
      item.scheme.durationType,
      item.startedTime,
      item.id,
      item.scheme.cryptoAssetSymbol,
    ),
  );

  const dispatch = useDispatch();
  const { actions } = useSlice();
  const { totalData, isStatusActive } = useSelector(selectVesting);
  const wallet = useSelector(selectWallet).wallet;

  const handleChangePage = (e, p) => {
    props.getPages(p);
  };

  const handleChangeSize = e => {
    setRowsPerPage(e.target.value);
    props.getSize(e.target.value);
  };

  const handleClickDetail = id => {
    const param = {
      wallet_address: wallet,
      role_type: 4,
      id,
    };
    dispatch(actions.getDetailVestingRequest(param));
    if (isStatusActive) {
      dispatch(actions.turnOffPopupDetail(false));
    }
  };

  return (
    <Root sx={{ width: '100%' }}>
      <table aria-label="custom pagination table">
        <thead>
          <tr>
            <th>#</th>
            <th>Wallet</th>
            <th>Scheme</th>
            <th>Total vesting</th>
            <th>Current vesting</th>
            <th>Time</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, inx) => (
            <tr key={row.stt} style={{ cursor: 'pointer', height: '50px' }}>
              <td>{row.stt}</td>
              <td align="center">
                <a
                  href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/address/${row.wallet}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ShortenWalletAddress numShort={4}>
                    {row.wallet}
                  </ShortenWalletAddress>
                </a>
              </td>
              <td align="center" onClick={() => handleClickDetail(row.id)}>
                {row.scheme}
              </td>
              <td align="center" onClick={() => handleClickDetail(row.id)}>
                <FunctionFormatNum>{row.totalVesting}</FunctionFormatNum>{' '}
                {row.token}
              </td>
              <td align="center" onClick={() => handleClickDetail(row.id)}>
                <FunctionFormatNum>
                  {formatNumberText(row.currentVesting)}
                </FunctionFormatNum>{' '}
                {row.token}
              </td>
              <td align="center" onClick={() => handleClickDetail(row.id)}>
                {renderTime(
                  row.status,
                  row.durationType,
                  row.durationTime,
                  row.startedTime,
                  row.endedTime,
                )}
              </td>
              <td align="center" onClick={() => handleClickDetail(row.id)}>
                {renderStatus(row.status)}
              </td>
              <td style={{ margin: '0 auto', width: '140px' }}>
                {row.status === StatusVesting.STATUS_INACTIVE && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(actions.turnOnPopupActive(true));
                      dispatch(actions.setActive(true));
                      handleClickDetail(row.id);
                    }}
                  >
                    Active plan
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '17px',
        }}
      >
        <FormControl>
          <Select value={rowsPerPage} onChange={handleChangeSize}>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={9999}>All</MenuItem>
          </Select>
        </FormControl>

        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(totalData / rowsPerPage)}
            page={props.pages}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
          />
        </Stack>
      </Box>
      <CustomizedDialogs />
      <DialogActivePlan />
    </Root>
  );
}
