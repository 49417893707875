/* --- STATE --- */

export interface ValueStatus {
  key: number | string;
  value: string;
}

interface SchemeVesting {
  id: string;
  schemeName: string;
  cryptoAssetSymbol: string;
  durationTime: number;
  durationType: number;
  afterStartTime: number;
  afterStartType: number;
}

export interface RowDataVesting {
  id: string;
  walletAddress: string;
  totalAmountVesting: number;
  currentAmountVesting: number;
  claimedTotalAmount: number;
  startedTime: number;
  endedTime: number;
  status: number;
  txnHash: string;
  isHasToken: boolean;
  scheme: SchemeVesting;
  vestedAmount: number;
  vestingBcId: string;
}

interface Token {
  symbol: string;
}
export interface VestingState {
  listDataVesting: Array<RowDataVesting>;
  dataToken: Array<Token>;
  isLoading: boolean;
  detailDataVesting: RowDataVesting;
  isLoadingDialog: boolean;
  totalData: number;
  isPopupCreateVesting: boolean;
  namePopUpCreateVesting: string;
  dataSchemeCreate: any;
  receiptBC: any;
  isPopupConfirm: boolean;
  receiptApprove: any;
  isLoadingApprove: boolean;
  isLockedApprove: boolean;
  isTurnOnButtonCreate: boolean;
  resDiv: number;
  isLoadingCallBC: boolean;
  turnOnPopupSuccess: boolean;
  isFailureCallBC: boolean;
  isFailureApprove: boolean;
  isSwitchPopUpActive: boolean;
  isLoadingButtonActive: boolean;
  isSwitchPopUpActiveSuccess: boolean;
  isSwitchPopUpActiveFailure: boolean;
  isStatusActive: boolean;
  receiptActive: any;
  isPopUpActiveSuccess: boolean;
  isPopUpActiveFailure: boolean;
}

export enum StatusVesting {
  STATUS_INACTIVE,
  STATUS_ACTIVE,
  STATUS_COMPLETED,
  STATUS_PROCESSING_TRANSACTION,
}

export interface ParamDeatailVesting {
  wallet_address: string | null | Object;
  role_type: number;
  id: string;
}
