import React, { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import { Helmet } from 'react-helmet-async';
import { DfyInputField } from 'app/components/DfyInputField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormReturn } from 'react-hook-form';
import DfyDropdown from 'app/components/DropDown';
import { WrapperDuration, WrapperInput, WrapperStartDate } from './style';
import _ from 'lodash';
import PopupScheme from './components/PopupScheme';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectCreateScheme } from './slice/selectors';
import { useCreateSchemeSlice } from './slice';
import { isEmpty } from 'lodash';
import { DfyAlert } from 'app/components/DfyAlert';

interface dataScheme {}

export default function CreateScheme(): ReactElement {
  const dispatch = useDispatch();
  const { actions } = useCreateSchemeSlice();
  const schema = yup.object({
    name: yup
      .string()
      .required('Name is required')
      .max(100, 'Maximum length allowed is 100 characters'),
    duration: yup
      .number()
      .required('Duration is required')
      .typeError('Duration is required')
      .integer('Duration must be integer')
      .min(1, 'Duration must greater than 0')
      .when('dropDuration', {
        is: 'Week',
        then: yup
          .number()
          .max(1560, 'Maximum vesting duration is 1560  week')
          .typeError('Duration is required'),
      })
      .when('dropDuration', {
        is: 'Month',
        then: yup
          .number()
          .max(360, 'Maximum vesting duration is 360  month')
          .typeError('Duration is required'),
      })
      .when('dropDuration', {
        is: 'Quarter',
        then: yup
          .number()
          .max(120, 'Maximum vesting duration is 120 quarters')
          .typeError('Duration is required'),
      }),
    cliff: yup
      .number()
      .required('cliff is required')
      .typeError('Cliff must be integer')
      .integer('Cliff must be integer')
      .min(0, 'The cliff must be greater than or equal to 0')
      .when('duration', {
        is: value => value > 0,
        then: yup
          .number()
          .integer('Cliff must be integer')
          .typeError('Cliff must be integer')
          .lessThan(
            yup.ref('duration'),
            'Cliff time can not greater than Vesting duration',
          ),
      }),
    token: yup.string().typeError('Token vesting is required'),
    dropDuration: yup.string().typeError('Vesting duration is required'),
  }); // validation inputfield

  const form: UseFormReturn = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
    mode: 'all',
  }); //type

  const { handleSubmit } = useForm();

  const Wallet = useSelector(selectWallet);

  const [valueDuration, setValueDuration] = useState();
  const [showSchemeDialog, setShowSchemeDialog] = useState(false);
  const [dataScheme, setDataScheme] = useState();
  const [dataToken, setDataToken] = useState();

  const durationList = [
    { label: 'Week', value: 'Week' },
    { label: 'Month', value: 'Month' },
    { label: 'Quarter', value: 'Quarter' },
  ];

  useEffect(() => {
    dispatch(actions.getAllToken());
  }, []);

  const token = useSelector(selectCreateScheme);

  useEffect(() => {
    if (!isEmpty(token.listToken)) {
      setDataToken(token.listToken[0]);
    }
  }, [token]);

  const handleSelectDuration = value => {
    setValueDuration(value.label);
  };

  const closeSchemeDialog = (value: boolean) => {
    setShowSchemeDialog(value);
  };

  const handleGetListToken = value => {
    setDataToken(value);
  };

  const onSubmit = (dataForm: any) => {
    const dataCreateScheme = {
      ...dataForm,
      tokenAddress: dataToken,
    };

    setShowSchemeDialog(true);
    setDataScheme(dataCreateScheme);
  };

  return (
    <>
      <Helmet>
        <title>Scheme</title>
        <meta
          name="description"
          content="A Boilerplate application scheme page"
        />
      </Helmet>
      {token.listToken && (
        <OneColumnLayout>
          <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
            <Paper
              elevation={3}
              sx={{
                padding: '16px',
                minHeight: 'calc(100vh - 200px)',
              }}
            >
              <Typography variant="h4">Creat scheme </Typography>

              <Box
                sx={{ marginTop: '16px', justifyContent: 'space-between' }}
                display={'flex'}
              >
                <Grid xs={5}>
                  <WrapperInput sx={{ marginTop: '20px' }}>
                    <Typography>Scheme Name</Typography>
                    <DfyInputField
                      form={form}
                      name="name"
                      placeholder="Scheme name"
                      disabled={false}
                    />
                  </WrapperInput>
                  <Box sx={{ marginTop: '20px' }}>
                    <Typography>Vesting duration</Typography>
                    <WrapperDuration>
                      <DfyInputField
                        form={form}
                        name="duration"
                        placeholder=""
                        disabled={false}
                        type="number"
                      />
                      <DfyDropdown
                        name="dropDuration"
                        options={durationList}
                        form={form}
                        defaultValue={durationList[0]?.value}
                        handleSelectedItem={handleSelectDuration}
                      />
                    </WrapperDuration>
                  </Box>
                </Grid>
                <Grid xs={5}>
                  <Box sx={{ marginTop: '20px' }}>
                    <Typography>Token vesting</Typography>
                    <DfyDropdown
                      name="token"
                      options={token.listToken}
                      form={form}
                      defaultValue={token.listToken[0]?.value || 'DFY'}
                      handleSelectedItem={handleGetListToken}
                    />
                  </Box>
                  <WrapperStartDate>
                    <Typography>Cliff</Typography>
                    <DfyInputField
                      form={form}
                      name="cliff"
                      placeholder=""
                      disabled={false}
                      type="number"
                      defaultValue="0"
                    />
                    <Box className="labelInput">
                      {valueDuration ? valueDuration : 'Week'}
                    </Box>
                  </WrapperStartDate>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!Boolean(Wallet.wallet)}
                >
                  Create scheme
                </Button>
              </Box>
              {!Boolean(Wallet.wallet) && (
                <Box>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: 'red',
                      margin: '10px 0px',
                    }}
                  >
                    You have not connected wallet
                  </Typography>
                </Box>
              )}
            </Paper>
          </form>
        </OneColumnLayout>
      )}

      <Backdrop
        sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
        open={token.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <PopupScheme
        callback={closeSchemeDialog}
        show={showSchemeDialog}
        data={dataScheme}
      />
      {!token.status && (
        <DfyAlert
          type="danger"
          alertText={
            <Typography sx={{ color: 'black' }}>
              {'Something went wrong!'}
            </Typography>
          }
          isOpen={token.alertInfor === null ? false : token.alertInfor}
          handle={() => {}}
          onClose={() => {
            dispatch(actions.alertReset());
          }}
        />
      )}
    </>
  );
}
