import { call, put, takeLatest } from 'redux-saga/effects';
import schemeServices from 'services/schemeService';
import { actions } from '.';

function* getSchemeList(action) {
  try {
    const response = yield schemeServices.getScheme(action.payload);
    yield put(actions.fetchDataSchemeSuccess(response.data));
  } catch (err: any) {
    yield put(actions.fetchDataSchemeFail(true));
  }
}

function* getAllToken() {
  try {
    const data = yield call(schemeServices.getTokenScheme);
    yield put(actions.getAllTokenSuccess(data?.data?.data));
  } catch (error: any) {
    console.log(error.response);
    yield put(actions.getAllTokenFail(error.response));
  }
}

function* changeStatusScheme(action) {
  try {
    yield schemeServices.changeStatusScheme(action.payload);
    yield put(actions.changeStatusSchemeSuccess());
  } catch (error: any) {
    console.log(error.response);
    yield put(actions.changeStatusFail(error.response));
  }
}

export function* schemeSaga() {
  yield takeLatest(actions.fetchDataScheme.type, getSchemeList);
  yield takeLatest(actions.getAllToken.type, getAllToken);
  yield takeLatest(actions.changeStatusScheme.type, changeStatusScheme);
}
