import { take, call, put, select, takeLatest, all } from 'redux-saga/effects';
import apiCheckCollection from 'services/apiCheckCollection';
import { importCollectionActions as actions } from '.';

function* checkCollectionRequestSaga(action) {
  try {
    const result = yield call(apiCheckCollection.check, action.payload);
    yield put(actions.checkCollectionSuccess(result.data));
  } catch (error: any) {
    yield put(actions.checkCollectionFailure(error));
  }
}
function* importCollectionRequestSaga(action) {
  try {
    const result = yield call(
      apiCheckCollection.importNewCollection,
      action.payload,
    );
    yield put(actions.importCollectionSuccess(result.data));
  } catch (error: any) {
    yield put(actions.importCollectionFailure(error.response.data.rd));
  }
}

function* watchCheckCollectionReq() {
  yield takeLatest(
    actions.checkCollectionRequest.type,
    checkCollectionRequestSaga,
  );
}
function* watchImportCollectionReq() {
  yield takeLatest(
    actions.importCollectionRequest.type,
    importCollectionRequestSaga,
  );
}

export function* importCollectionSaga() {
  yield all([watchCheckCollectionReq(), watchImportCollectionReq()]);
}
