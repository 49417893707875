/**
 *
 * Footer
 *
 */
import { Container, Typography } from '@mui/material';

interface Props {}

export default function Footer(props: Props) {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: '30px' }}>
      <Typography variant="body1" color="inherit">
        © 2022 Marketplace Admin
      </Typography>
    </Container>
  );
}
