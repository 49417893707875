import * as gasInfo from '../supportService/getGasInformation';
import Web3 from '../initWeb3';
import auctionNftAbi from '../config/auctionNft.abi.json';
import { filterTokenToAddress } from '../supportService/filterTokenToAddress';
import { store } from 'index';

/**
 * approveAuction
 */

export const approveAuction = async (
  from,
  auctionId,
  approval,
  spender = store?.getState()?.glocalState?.contracts?.AuctionNFT,
) => {
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();
  const auctionContract = new web3.eth.Contract(auctionNftAbi, spender);
  try {
    const txData = await auctionContract.methods.approveAuction(
      auctionId,
      approval,
    );
    console.log('txData: ', txData);
    let dt = txData.encodeABI();
    console.log('encodeABI: ', dt);
    const nonce = await web3.eth.getTransactionCount(from, 'pending');
    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: txData.encodeABI(),
    };
    const gasData = await gasInfo.getGasInformation(tx);
    return {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
      hasError: gasData.hasError,
      msgError: gasData.msgError,
    };
  } catch (error) {
    console.log(error);
  }
};

/**
 * bid
 * @param {*} from (vi hien tai)
 * @param {*} tokenId
 * @param {*} collectionAddress
 * @param {*} startingPrice
 * @param {*} buyOutPrice
 * @param {*} priceStep
 * @param {*} currency
 * @param {*} startTime
 * @param {*} endTime
 * @param {*} spender (env)
 * @returns
 */
export const putOnAuction = async (
  from,
  tokenId,
  collectionAddress,
  startingPrice,
  buyOutPrice,
  priceStep,
  currency,
  startTime,
  endTime,
  spender = store?.getState()?.glocalState?.contracts?.AuctionNFT,
) => {
  console.log(
    from,
    tokenId,
    collectionAddress,
    startingPrice,
    buyOutPrice,
    priceStep,
    currency,
    startTime,
    endTime,
  );
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();
  const auctionContract = new web3.eth.Contract(auctionNftAbi, spender);
  const supportSymbol = JSON.parse(
    localStorage.getItem('StoreCryptoCurrency')!,
  );
  const tokenAddress = filterTokenToAddress(supportSymbol, currency);

  try {
    const txData = await auctionContract.methods.putOnAuction(
      tokenId,
      collectionAddress,
      startingPrice,
      buyOutPrice,
      priceStep,
      tokenAddress,
      startTime,
      endTime,
    );

    const nonce = await web3.eth.getTransactionCount(from, 'pending');
    // Create transaction
    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: txData.encodeABI(),
    };

    const gasData = await gasInfo.getGasInformation(tx);

    return {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };
  } catch (error) {
    console.log(error);
  }
};

/**
 * check owner nft on auction
 * @param {*} from (vi hien tai)
 * @param {*} autionId
 * @param {*} spender (env)
 * @returns
 */
export const checkOwner = async (
  from,
  autionId,
  spender = store?.getState()?.glocalState?.contracts?.AuctionNFT,
) => {
  console.log(from, from, autionId);
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();
  const auctionContract = new web3.eth.Contract(auctionNftAbi, spender);

  const allowance = await auctionContract.methods.auctions(autionId).call();

  return allowance.auctionData.owner;
};
