import { styled } from '@mui/material';
export const Wrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '600px',
  // height: '100%',
  justifyContent: 'space-between',

  '.control': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '40px',

    '.MuiOutlinedInput-root': {
      height: '40px',
      minWidth: '140px',
    },

    '.MuiTableCell-root': {
      height: '40px !important',
    },
  },

  '.fieldWrapper': {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  '.titleField': {
    marginRight: '20px',
    minWidth: '44px',
  },

  button: {
    height: '40px',
  },

  '.statusField': {
    marginLeft: '-30px',
  },

  '.iconStatus': {
    cursor: 'pointer',
    height: '36px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  '.listIconStatus': {
    display: 'flex',
  },

  '.pagination': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  '.MuiFormHelperText-root': {
    position: 'absolute',
    bottom: '-40px',
    width: '200px',
  },
});
