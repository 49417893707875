import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { history } from 'app';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SchemeTypeEnum from 'types/SchemeTypeEnum';
interface Props {
  show: boolean;
  item?: any;
  callback: (value: boolean) => void;
  hideSchemeCB: (value: boolean) => void;
}

export default function HideSchemeDialog({
  show,
  callback,
  hideSchemeCB,
  item,
}: Props) {
  const handleClose = () => {
    callback(false);
  };

  const onHideScheme = () => {
    hideSchemeCB(false);
    callback(false);
    history.push('/scheme');
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Hide scheme'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to {item === SchemeTypeEnum.HIDDEN ? 'Unhide' : 'Hide'}{' '}
          this scheme?
        </DialogContentText>
        <DialogActions
          sx={{
            justifyContent: 'center !important',
          }}
        >
          <Button variant="contained" onClick={() => onHideScheme()}>
            {item === SchemeTypeEnum.HIDDEN ? 'Unhide' : 'Hide'}
          </Button>
        </DialogActions>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}
