import * as React from 'react';
import { useEffect } from 'react';
import Web3 from 'services/walletService/initWeb3';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import metaBinance from './assets/Meta-Binane.svg';
import Trust from './assets/Trust.svg';
import Safepal from './assets/Safepal.svg';
import QR from './assets/QR.svg';
import { BoxCustom, ButtonCustom, Modal } from './styled';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { DfyConfirm } from '../DfyConfirm';
import authService from 'services/authService';
import { store } from 'index';
import { walletAction } from 'store/globalReducer';
import { DfyAlert } from '../DfyAlert';
import { useDispatch } from 'react-redux';
import { useWalletSlice } from '../Wallet/slice';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DfyAlertProps {
  handle: Function;
  isOpen: boolean;
  onClose: Function;
  auto: boolean;
}

const handleClose: Function = (e: any) => {};

interface BtnWalletProps {
  types: string;
  img: string;
  text: string;
  handle: Function;
}

const BtnWallet = (props: BtnWalletProps) => {
  const handleClick = () => {
    props.handle();
  };
  return (
    <ButtonCustom onClick={handleClick} types={props.types}>
      <img
        style={{
          marginRight: '10px',
          width: '54px',
        }}
        src={props.img}
        alt=""
      />
      <span>{props.text}</span>
    </ButtonCustom>
  );
};

export function DfyPopupConnectWallet(props: DfyAlertProps) {
  const storage = store.getState();
  const userLogin: any = {};

  const instanceValue = Web3.getInstance;
  const [confirm, setConfirm] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [currentWallet, setCurrentWallet] = React.useState('');
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);

  const { actions } = useWalletSlice();
  const dispatch = useDispatch();

  const handleClose = () => {
    props.onClose();
    setLoading1(false);
    setLoading2(false);
    setLoading3(false);
    setLoading4(false);

    return props.handle();
  };

  const handleWalletConnected = wallet => {
    if (wallet === undefined || wallet === null || wallet.trim().length === 0) {
      console.log('Ví có vấn đề, không đúng.');
      return;
    }
    dispatch(actions.storeWallet(wallet));
    localStorage.setItem('wallet_address', wallet);
  };

  useEffect(() => {
    console.log('useEffect 1241121313131: ' + props.auto);
    if (props.auto) {
      handlerClickConnect('Meta');
    }
  }, []);

  const handlerClickConnect = key => {
    switch (key) {
      case 'Meta':
        (async () => {
          setLoading1(true);
          setLoading2(false);
          setLoading3(false);
          setLoading4(false);
          localStorage.setItem('extensionName', 'Metamask');
          let walletAddress = await instanceValue.setWeb3();
          handleWalletConnected(walletAddress);
          setLoading1(false);
          handleClose();
        })();
        break;
      case 'Trust':
        (async () => {
          setLoading2(true);
          setLoading1(false);
          setLoading3(false);
          setLoading4(false);
          localStorage.setItem('extensionName', 'Metamask');
          let walletAddress = await instanceValue.setWeb3();
          handleWalletConnected(walletAddress);
          setLoading2(false);
          handleClose();
        })();
        break;
      case 'Safepal':
        (async () => {
          setLoading3(true);
          setLoading1(false);
          setLoading2(false);
          setLoading4(false);
          localStorage.setItem('extensionName', 'Metamask');
          let walletAddress = await instanceValue.setWeb3();
          handleWalletConnected(walletAddress);
          setLoading3(false);
          handleClose();
        })();

        break;
      case 'OQ':
        (async () => {
          setLoading4(true);
          setLoading1(false);
          setLoading2(false);
          setLoading3(false);
          localStorage.setItem('extensionName', 'WalletConnect');
          let walletAddress = await instanceValue.setWeb3();
          handleWalletConnected(walletAddress);
          setLoading4(false);
          handleClose();
        })();
        break;
      default:
        break;
    }
  };

  const handleCloseModalCf = () => {
    setConfirm(false);
  };

  const handleSubmitConnect = async () => {
    console.log('todo');
    try {
      await authService.connectWalletAddressWithEmail({
        walletAddress: currentWallet,
        lastToken: localStorage.getItem('lastToken'),
      });
    } catch (error) {
      console.log(error);
      store.dispatch(walletAction(null));
      localStorage.removeItem('StoreWallet');
      localStorage.removeItem('extensionName');
      localStorage.removeItem('lastToken');
    }
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Wallet intergration
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CancelIcon sx={{ color: '#E0E0E0' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <p className="content-text">
            In order to receive your DFY Tokens, please connect account to your
            wallet
          </p>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '15px',
              position: 'relative',
              '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                left: 0,
                bottom: 0,
                borderBottom: `1px dashed #BDBDBD`,
              },
            }}
          >
            {loading1 ? (
              <ButtonCustom types="1" sx={{ justifyContent: 'center' }}>
                <CircularProgress />
              </ButtonCustom>
            ) : (
              <BtnWallet
                handle={() => handlerClickConnect('Meta')}
                types="1"
                img={metaBinance}
                text="Connect to Metamask/Binance Chain"
              />
            )}
            {loading2 ? (
              <ButtonCustom types="2" sx={{ justifyContent: 'center' }}>
                <CircularProgress />
              </ButtonCustom>
            ) : (
              <BtnWallet
                handle={() => handlerClickConnect('Trust')}
                types="2"
                img={Trust}
                text="Connect to Trust Wallet"
              />
            )}
            {loading3 ? (
              <ButtonCustom types="3" sx={{ justifyContent: 'center' }}>
                <CircularProgress />
              </ButtonCustom>
            ) : (
              <BtnWallet
                handle={() => handlerClickConnect('Safepal')}
                types="3"
                img={Safepal}
                text="Connect to Safepal"
              />
            )}
            {loading4 ? (
              <ButtonCustom types="4" sx={{ justifyContent: 'center' }}>
                <CircularProgress />
              </ButtonCustom>
            ) : (
              <BtnWallet
                handle={() => handlerClickConnect('OQ')}
                types="4"
                img={QR}
                text="Get WalletConnect QR code"
              />
            )}
          </Box>
          <BoxCustom sx={{}}>
            <span>
              Extensions and Mobile Apps allowed to connect to DeFi account:
            </span>
            <br />
            <span>
              <b>On Desktop</b>: MetaMask and Binance Chain extensions
            </span>
            <br />
            <span>
              <b>On Mobile</b>: Trust Wallet and MetaMask apps
            </span>
            <br />
            <span>
              Please access our website through browser of the wallet app to
              connect your wallet to DeFi account.{' '}
            </span>
          </BoxCustom>
        </DialogContent>
      </Modal>
      {confirm ? (
        <DfyConfirm
          onClose={handleCloseModalCf}
          isOpen={confirm}
          currentAddress={currentWallet}
          email={userLogin?.email}
          handle={handleSubmitConnect}
        />
      ) : (
        ''
      )}
      {openError ? (
        <DfyAlert
          type="danger"
          onClose={handleCloseError}
          isOpen={openError}
          alertText={errorMessage}
          handle={() => handleClose()}
        />
      ) : (
        ''
      )}
    </>
  );
}
