import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { ViewOfficalPawnShopProps } from '../../slice/types';
import { ButtonEdit } from '../styleAll';

interface Props {
  titleName: string;
  children: ReactNode;
  setIsEdit?: any;
  IsEdit?: boolean;
  getData: Function;
  data: ViewOfficalPawnShopProps[];
}

const Section = (props: Props) => {
  const handle = () => {
    props.setIsEdit(true);
  };

  const handleSave = () => {
    const data = props.data.map(item => {
      return {
        idPawnshop: item.id,
        idToken: item.token,
        imageToken: item.token,
        position: item.position,
        idDelete: item.isDelete,
      };
    });

    console.log(data);
  };

  return (
    <Box sx={{ marginBottom: '100px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          marginBottom: '20px',
          '&::before': {
            position: 'absolute',
            content: '""',
            width: '100%',
            height: '1px',
            left: 0,
            top: '100%',
            backgroundColor: '#000',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '20px',
            flexGrow: 1,
          }}
        >
          {props.titleName}
        </Typography>
        <Box>
          {props.IsEdit ? (
            <>
              <ButtonEdit
                onClick={() => {
                  props.setIsEdit(false);
                  props.getData();
                }}
              >
                Cancel
              </ButtonEdit>
              <ButtonEdit
                onClick={() => {
                  props.setIsEdit(false);
                  props.getData();
                  handleSave();
                }}
              >
                Save
              </ButtonEdit>
            </>
          ) : (
            <ButtonEdit onClick={() => handle()}>Edit</ButtonEdit>
          )}
        </Box>
      </Box>
      <Box>{props.children}</Box>
    </Box>
  );
};

export default Section;
