import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { addNewTokenPageListActions as actions } from '.';
import abi from 'services/walletService/config/voting.abi.json';
import Web3 from 'services/walletService/initWeb3';
import * as gasInfo from 'services/walletService/supportService/getGasInformation';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import { BigNumber } from 'bignumber.js';
import { pinJSONToIPFS } from 'services/ipfsService/pinJSONToIPFS';
import addNewTokenServices from 'services/apiAddNewToken';

function* addListToken(action) {
  console.log('list page ', action.payload);

  try {
    const from = action.payload.wallet;

    const spender = action.payload.spender;

    const data = {
      type: 1,
      symbol: action.payload.data.symbol,
      name: action.payload.data.name,
      contract: action.payload.data.contract,
      logo_cid: action.payload.data.logo_cid,
      whitelist_type: action.payload.whitelist_type,
      price_from: action.payload.price_from,
      oracle_id: action.payload.oracle_id,
      price_feed_address: action.payload.price_feed_address,
    };

    const ipfsJson = yield call(pinJSONToIPFS, data);

    //call BC start
    const initWeb3 = yield Web3.getInstance;

    const web3: any = initWeb3.getWeb3();

    const addNewToken1 = new web3.eth.Contract(abi, spender);

    const addNewTokenData = addNewToken1.methods.ListNewToken(
      action.payload.data.bc_id,
      action.payload.price_feed_address,
      action.payload.PutNewTokenAs.includes('0') ? 1 : 0,
      ipfsJson.data.IpfsHash,
    );

    const nonce = yield web3.eth.getTransactionCount(from, 'pending');

    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: addNewTokenData.encodeABI(),
    };
    const gasData = yield gasInfo.getGasInformation(tx);

    const txAddNewToken = {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };

    const receipt = yield signAndSendTx(txAddNewToken);

    const dataApi = {
      ...data,
      txn_hash: receipt.transactionHash,
      token_cid: ipfsJson.data.IpfsHash,
    };

    yield call(addNewTokenServices.addNewList, dataApi);

    yield put(actions.addTokenScc());
  } catch (error) {
    yield put(actions.addTokenFail());
  }
}

export function* addNewTokenPageListSaga() {
  yield takeLatest(actions.addToken.type, addListToken);
}
