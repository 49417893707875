const DateTimeHelper = {
  convertTime(time) {
    if (isNaN(time)) {
      return '';
    }
    let a = new Date(time);
    let year = a.getFullYear();
    let month = a.getMonth() + 1;
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    return (
      (date >= 10 ? date : '0' + date) +
      '/' +
      (month >= 10 ? month : '0' + month) +
      '/' +
      year +
      ' ' +
      (hour >= 10 ? hour : '0' + hour) +
      ':' +
      (min >= 10 ? min : '0' + min) +
      ':' +
      (sec >= 10 ? sec : '0' + sec)
    );
  },
  convertTime2(time) {
    if (isNaN(time)) {
      return '';
    }
    let a = new Date(time);
    let year = a.getFullYear();
    let month = a.getMonth() + 1;
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    return (
      (hour >= 10 ? hour : '0' + hour) +
      ':' +
      (min >= 10 ? min : '0' + min) +
      ' ' +
      (date >= 10 ? date : '0' + date) +
      '/' +
      (month >= 10 ? month : '0' + month) +
      '/' +
      year
    );
  },
  calcRemainTime(start: number, end: number): string {
    let period = end - start;
    let day = Math.floor(period / 86400000);
    period = period % 86400000;
    let hour = Math.floor(period / 3600000);
    period = period % 3600000;
    let minute = Math.floor(period / 60000);
    let s = '';
    if (day > 0) {
      s += day > 1 ? day + ' days ' : day + ' day ';
    }
    if (day > 0) {
      s += hour > 1 ? hour + ' hrs ' : hour + ' hr ';
    } else {
      if (hour > 0) {
        s += hour > 1 ? hour + ' hrs ' : hour + ' hr ';
      }
    }
    s += minute > 1 ? minute + ' mins ' : minute + ' min';
    return s;
  },
};

export default DateTimeHelper;
