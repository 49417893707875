import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.scheme || initialState;

export const selectScheme = createSelector(
  [selectSlice],
  state => state.dataScheme,
);

export const selectToken = createSelector(
  [selectSlice],
  state => state.listTokens,
);

export const selectSchemeParams = createSelector(
  [selectSlice],
  state => state.params,
);

export const selectReRender = createSelector(
  [selectSlice],
  state => state.reRender,
);
