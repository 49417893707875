import { useEffect, useState } from 'react';
// import { checkApprove, createApprove } from './approve';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import { isEmpty } from 'lodash';
import { CircularProgress } from '@mui/material';
import BigNumber from 'bignumber.js';
import { ButtonApproveStyled } from './style';
import { checkApprove, createApprove } from './approveAddtoken';

interface DfyButtonApproveProps {
  curAddress: any; //ví hiện tại
  tokenSymbol: any; //tên
  toAddress: string; //contract tièn
  amount: string; //tiền
  handleAction?: any;
  haveError?: boolean;
}

export default function DfyButtonApprove(props: DfyButtonApproveProps) {
  console.log('dfy app rove');

  //init something
  const {
    curAddress,
    tokenSymbol,
    toAddress,
    amount,
    handleAction,
    haveError,
  } = props;
  console.log('props', props);

  const [allowance, setAllowance] = useState<Number>();
  const [receipt, setReceipt] = useState();
  const [loading, setLoading] = useState(false);

  //create approve
  const handleApprove = async () => {
    setLoading(true);
    try {
      const tx = await createApprove(
        curAddress,
        tokenSymbol,
        toAddress,
        amount,
      );
      const receipt = await signAndSendTx(tx);
      setReceipt(receipt);
      setLoading(false);
      handleAction(true);
    } catch (error) {
      setLoading(false);
    }
  };

  //checkApprove
  useEffect(() => {
    (async () => {
      console.log('DFY approve');

      const res = await checkApprove(
        curAddress,
        tokenSymbol,
        toAddress,
        amount,
      );

      console.log('res', res);

      const resDiv18: number = Number(
        new BigNumber(res).dividedBy(10 ** 18).toFixed(),
      );
      console.log('resDiv18', resDiv18);

      if (resDiv18 >= Number(amount)) {
        handleAction(true);
        console.log('true');
      } else {
        handleAction(false);
        console.log('false');
      }
      setAllowance(resDiv18);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curAddress, amount]);

  return (
    <>
      {allowance! < Number(amount) && isEmpty(receipt) && loading === false && (
        <ButtonApproveStyled
          variant="contained"
          size="large"
          disabled={haveError}
          onClick={handleApprove}
        >
          Approve
        </ButtonApproveStyled>
      )}

      {allowance! < Number(amount) && !isEmpty(receipt) && loading === false && (
        <ButtonApproveStyled disabled={true} variant="contained" size="large">
          Approved
        </ButtonApproveStyled>
      )}

      {loading === true && (
        <ButtonApproveStyled
          variant="contained"
          size="large"
          sx={{ width: '125px' }}
        >
          <CircularProgress size={19} color="inherit" />
        </ButtonApproveStyled>
      )}
    </>
  );
}
