import { call, takeLatest, put, all } from 'redux-saga/effects';
import { apiGetTokenList } from 'services/apiTokenList';
import Web3 from 'services/walletService/initWeb3';
import { actions } from '.';
import abi from 'services/walletService/config/voting.abi.json';
import * as gasInfo from 'services/walletService/supportService/getGasInformation';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import CancellTokenServices from 'services/apiCancellTokenServices';

function* searchToken(action) {
  try {
    yield put(actions.setLoadingUI(true));
    const res = yield call(apiGetTokenList, {
      ...action.payload,
    });

    yield put(actions.getTokenListData(res.data.rows));
    yield put(actions.setTotalPage(res.data.total));
    yield put(actions.setLoadingUI(false));
  } catch (err: any) {}
}

function* CancellBC(action) {
  try {
    yield put(actions.setLoadingStatus(true));
    const from = action.payload.from;

    const spender = action.payload.spender;

    const id = Number(action.payload.id);

    const idBE = action.payload.idBE;

    //call BC start
    const initWeb3 = yield Web3.getInstance;

    const web3: any = initWeb3.getWeb3();

    const cancell = new web3.eth.Contract(abi, spender);

    const cancellData = cancell.methods.cancelTokenVoting(id);

    const nonce = yield web3.eth.getTransactionCount(from, 'pending');

    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: cancellData.encodeABI(),
    };
    const gasData = yield gasInfo.getGasInformation(tx);

    const txAddNewToken = {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };

    const receipt = yield signAndSendTx(txAddNewToken);

    const data = {
      id: idBE,
      txn_hash: receipt.transactionHash,
    };

    yield call(CancellTokenServices.cancell, data);

    yield put(actions.callBcCancellScc());
    yield put(actions.setLoadingStatus(false));
    yield put(actions.setPopupStatus(false));
    yield put(actions.setAlertStatus(true));
  } catch (error) {
    yield put(actions.callBcCancellFail());
    yield put(actions.setLoadingStatus(false));
    yield put(actions.setPopupStatus(false));
    yield put(actions.setErrorStatus(true));
  }
}

function* searchTokenListApi() {
  yield takeLatest(actions.searchTokenList.type, searchToken);
}

function* watchCheckAssetStatusReq() {
  yield takeLatest(actions.callBcCancell.type, CancellBC);
}

export function* tokenList() {
  yield all([searchTokenListApi(), watchCheckAssetStatusReq()]);
}
