/**
 *
 * AuctionPage
 *
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import ActiveAuction from './components/ActiveAuction';
import EndedAuction from './components/EndedAuction';
import WaitingAuction from './components/WaitingAuction';

interface Props {}

export function AuctionPage(props: Props) {
  const [value, setValue] = React.useState(0);

  const tabs = [
    {
      label: 'Active',
    },
    {
      label: 'Ended',
    },
    {
      label: 'Waiting for review',
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Auction Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <OneColumnLayout>
        <Paper
          elevation={3}
          sx={{
            padding: '16px',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <Typography variant="h4">Auction List</Typography>
          <Box
            sx={{ marginTop: '16px', borderBottom: 1, borderColor: 'divider' }}
          >
            <Tabs value={value} onChange={handleChange}>
              {tabs.map((tab, index) => (
                <Tab label={tab.label} key={index} />
              ))}
            </Tabs>
          </Box>
          {value === 0 && <ActiveAuction />}
          {value === 1 && <EndedAuction />}
          {value === 2 && <WaitingAuction />}
        </Paper>
      </OneColumnLayout>
    </>
  );
}
