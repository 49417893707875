import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

export interface DfyProfile {
  activatedAt: number | null;
  address: any;
  birthday: any;
  createdAt: number | null;
  email: string | null;
  id: number | null;
  isActive: true;
  kyc: any;
  links: any;
  name: string;
  pawnShop: any;
  phoneNumber: any;
}

interface DfyMoney {
  symbol: string;
  balance: number;
}

interface DfyWalletMyAccount {
  currentAddress: string;
  money: Array<DfyMoney>;
}

export interface GlobalState {
  loggedInUser: DfyProfile | null;
  wallet: DfyWalletMyAccount | null;
  contract: any;
}

const initialState: GlobalState = {
  loggedInUser: null,
  wallet: null,
  contract: null,
};

export const profileUser = createAction<DfyProfile | null>('global/user');
export const walletAction = createAction<Object | null>('global/wallet');
export const contractAction = createAction<any | null>('global/contract');

const globalReducer = createReducer(initialState, {
  [profileUser.toString()]: (state, action: PayloadAction<DfyProfile>) => {
    state.loggedInUser = action.payload;
  },
  [walletAction.toString()]: (
    state,
    action: PayloadAction<DfyWalletMyAccount>,
  ) => {
    state.wallet = action.payload;
  },
  [contractAction.toString()]: (state, action: PayloadAction<any>) => {
    state.contract = action.payload;
  },
});

export default globalReducer;
