import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const ButtonApproveStyled = styled(Button)(({ theme }) => ({
  height: '48px',
  fontSize: '16px',
  lineHeight: '20px',

  [theme.breakpoints.down('md')]: {
    height: '40px',
  },
}));
