import apiClient from './apiService';

const homePageServices = {
  getHomePage(params: any) {
    return apiClient.request({
      method: 'GET',
      params: params,
      url: '/api-svc/api/homepage',
    });
  },
  findById(id: string) {
    return apiClient.request({
      method: 'GET',
      params: {},
      url: '/api-svc/api/homepage/' + id,
    });
  },
  deleteHomePage(params: any) {
    return apiClient.request({
      method: 'DELETE',
      data: params,
      url: '/api-svc/api/homepage',
    });
  },

  getCryptoCurrency() {
    return apiClient.request({
      method: 'GET',
      url: '/defi-pawn-crypto-service/public-api/v1.0.0/crypto-asset',
    });
  },
};

export default homePageServices;
