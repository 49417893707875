import Web3 from 'services/walletService/initWeb3';
import abi from 'services/walletService/config/voting.abi.json';
import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from '.';
import { apiTokenDetail } from 'services/apiTokenDetail';
import { pinJSONToIPFS } from './../../../../services/ipfsService/pinJSONToIPFS';
import * as gasInfo from 'services/walletService/supportService/getGasInformation';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import BigNumber from 'bignumber.js';
import { apiEditVoting } from 'services/apiEditVoting';

const handleMomentS = value => {
  return moment(value, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').valueOf() / 1000;
};

function* handleAddNewToken(action) {
  try {
    const tokenDetail = yield call(apiTokenDetail, action.payload);
    yield put(actions.setTokenDetail(tokenDetail.data.item));
  } catch (error) {}
}

function* handleCallBlockChain(action) {
  try {
    const from = action.payload.wallet;

    const spender = action.payload.spender;

    const newAdditional = action?.payload?.additional_info?.map(item => {
      return {
        content: item.value,
        title: item.trait_type,
      };
    });

    //call BC start

    yield put(actions.setVotingStatus(true));

    const initWeb3 = yield Web3.getInstance;

    const web3: any = initWeb3.getWeb3();

    const editContract = new web3.eth.Contract(abi, spender);

    const logo_cid = yield call(pinJSONToIPFS, action.payload.upLoadFileImg);

    const ipfs = yield call(pinJSONToIPFS, {
      ...action.payload,
      upLoadFileImg: logo_cid.data.IpfsHash,
    });

    const editDataData = editContract.methods.editTokenVoting(
      Number(action.payload.votingId),
      action.payload.contract,
      new BigNumber(Number(action.payload.voting))
        .multipliedBy(10 ** 18)
        .toFixed(),
      new BigNumber(Number(action.payload.reward))
        .multipliedBy(10 ** 18)
        .toFixed(),
      Number(action.payload.dateForm),
      Number(action.payload.dateTo),
      ipfs.data.IpfsHash,
    );

    const nonce = yield web3.eth.getTransactionCount(from, 'pending');

    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: editDataData.encodeABI(),
    };

    const gasData = yield gasInfo.getGasInformation(tx);

    const txAddNewToken = {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };

    const receipt = yield signAndSendTx(txAddNewToken);

    const dataEdit = {
      additional_info: newAdditional,
      contract: action.payload.contract,
      from_date: action.payload.dateForm,
      to_date: action.payload.dateTo,
      logo_cid: logo_cid.data.IpfsHash,
      name: action.payload.tokenName,
      project_name: action.payload.project,
      reward_pool: action.payload.reward,
      symbol: action.payload.ticker,
      target_voting: action.payload.voting,
      telegram: action.payload.telegram,
      token_cid: ipfs.data.IpfsHash,
      twitter: action.payload.twitter,
      txn_hash: receipt.transactionHash,
      website: action.payload.website,
    };

    yield call(apiEditVoting, dataEdit, action.payload.votingId);

    yield put(actions.setVotingStatus(false));
  } catch (error) {
    yield put(actions.setVotingStatus(false));
    console.log(error, 'lỗi nè');
  }
}

export function* editVotingSaga() {
  yield takeLatest(actions.editVoting.type, handleAddNewToken);
  yield takeLatest(actions.callBlockChain.type, handleCallBlockChain);
}
