import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { Actions as actions } from '.';

const dataFake = [
  {
    id: 200000,
    name: '1',
    email: 'lemaiTest1@gmail.com',
    addedDate: 123123123123,
    token: 123123123123123,
    position: 1,
    isDelete: false,
  },
  {
    id: 100000,
    name: '2',
    email: 'lemaiTest2@gmail.com',
    addedDate: 123123123123,
    token: 123123123123123,
    position: 2,
    isDelete: false,
  },
  {
    id: 300000,
    name: '3',
    email: 'lemaiTest3@gmail.com',
    addedDate: 123123123123,
    token: 123123123123123,
    position: 3,
    isDelete: false,
  },
  {
    id: 400000,
    name: '4',
    email: 'lemaiTest4@gmail.com',
    addedDate: 123123123123,
    token: 123123123123123,
    position: 4,
    isDelete: false,
  },
];

function* getListOffcialPawn() {
  yield put(actions.getDataOffcialPawnSuccess(dataFake));
}

export function* Saga() {
  yield takeLatest(actions.getDataOffcialPawnRequire.type, getListOffcialPawn);
}
