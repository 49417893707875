import apiClient from './apiService';

const addNewTokenServices = {
  addNew(data: any) {
    return apiClient.request({
      method: 'POST',
      url: `voting-svc/api/tokens`,
      // headers: {
      //   walletAddress: '0xe3E354a41F01CC084679cb52d0EDC50C1C58EEff',
      // },
      data: data,
    });
  },
  addNewList(data: any) {
    return apiClient.request({
      method: 'POST',
      url: `voting-svc/api/tokens/list`,
      data: data,
    });
  },
};

export default addNewTokenServices;
