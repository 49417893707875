import { Button, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import deleteIcon from '../assets/trash2.svg';
import plus from '../assets/pluscircle.svg';

interface PropertiesProps {
  handleAddMoreList: Function;
  status: boolean;
}

export const Properties = (props: PropertiesProps) => {
  const { handleAddMoreList, status } = props;

  const [inputList, setInputList] = React.useState<any>([]);

  React.useEffect(() => {
    setInputList([]);
  }, [status]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...inputList];

    list[index][name] = value;

    setInputList(list);

    handleAddMoreList(list);
  };

  const handleAdd = () => {
    setInputList([...inputList, { trait_type: '', value: '' }]);
  };

  const handleRemove = index => {
    const list = [...inputList];

    list.splice(index, 1);

    setInputList(list);

    handleAddMoreList(list);
  };

  return (
    <Box>
      {inputList.map((e, index) => {
        return (
          <Grid
            key={index}
            container
            sx={{ alignItems: 'center', marginBottom: { xs: 0, sm: '20px' } }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
              xs={10}
              sm={11}
            >
              <Grid
                item
                xs={12}
                lg={6}
                sx={{ marginBottom: { xs: '16px', sm: 0 } }}
              >
                {e.trait_type.length <= 50 ? (
                  <TextField
                    name="trait_type"
                    placeholder="Properties"
                    value={e.trait_type}
                    onChange={a => handleInputChange(a, index)}
                  />
                ) : (
                  <TextField
                    name="trait_type"
                    placeholder="Properties"
                    value={e.trait_type}
                    onChange={a => handleInputChange(a, index)}
                    error
                    helperText="Maximum character is 50 digits"
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                sx={{ marginBottom: { xs: '16px', sm: 0 } }}
              >
                {e.value.length <= 500 ? (
                  <TextField
                    name="value"
                    placeholder="Value"
                    value={e.value}
                    onChange={a => handleInputChange(a, index)}
                  />
                ) : (
                  <TextField
                    name="value"
                    placeholder="Value"
                    value={e.value}
                    onChange={a => handleInputChange(a, index)}
                    error
                    helperText="Maximum character is 500 digits"
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={2} sm={1}>
              <Button
                sx={{ padding: '0px', marginTop: { xs: '-20px', sm: 0 } }}
                variant="text"
                onClick={() => handleRemove(index)}
              >
                <img src={deleteIcon} alt="Delete" />
              </Button>
            </Grid>
          </Grid>
        );
      })}

      <Box>
        <Button sx={{ padding: 0, textTransform: 'none' }} onClick={handleAdd}>
          <img src={plus} alt="plus" />
          <Typography
            sx={{ marginLeft: '8px', fontSize: { xs: '14px', sm: '16px' } }}
          >
            Add more
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
