import {
  ActiveAuctionState,
  AuctionError,
  AuctionParams,
  AuctionResponse,
  AuctionState,
  EndedAuctionState,
  WaitingAuctionState,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../../utils/redux-injectors';
import { auctionSaga } from './saga';
import { PayloadAction } from '@reduxjs/toolkit';

const initEndedAuctionState: EndedAuctionState = {
  isLoading: false,
  rows: [],
  total: 0,
  params: {
    status: [12],
    name: '',
    page: 1,
    size: 50,
  },
  error: {
    rc: -1,
    rd: 'Unknown',
  },
};

const initActiveAuctionState: ActiveAuctionState = {
  isLoading: false,
  rows: [],
  total: 0,
  params: {
    status: [2],
    name: '',
    page: 1,
    size: 50,
  },
  error: {
    rc: -1,
    rd: 'Unknown',
  },
  showHiddenForm: false,
};

const initWaitingAuctionState: WaitingAuctionState = {
  isLoading: false,
  rows: [],
  total: 0,
  params: {
    status: [9],
    name: '',
    page: 1,
    size: 50,
  },
  error: {
    rc: -1,
    rd: 'Unknown',
  },
};

export const initState: AuctionState = {
  active: initActiveAuctionState,
  ended: initEndedAuctionState,
  waiting: initWaitingAuctionState,
};

const slice = createSlice({
  name: 'auction',
  initialState: initState,
  reducers: {
    fetchActiveAuction(state, action: PayloadAction<AuctionParams>) {
      state.active.isLoading = true;
      state.active.params = action.payload;
    },
    fetchActiveAuctionSuccess(state, action: PayloadAction<AuctionResponse>) {
      state.active.isLoading = false;
      state.active.rows = action.payload.rows;
      state.active.total = action.payload.total;
    },
    fetchActiveAuctionError(state, action: PayloadAction<AuctionError>) {
      state.active.isLoading = false;
      state.active.error = action.payload;
    },
    setActiveLoading(state, action: PayloadAction<boolean>) {
      state.active.isLoading = action.payload;
    },
    // hide auction
    hideAuction(state, action: PayloadAction<any>) {
      state.active.isLoading = true;
    },
    hideAuctionSuccess(state, action: PayloadAction<any>) {
      state.active.isLoading = false;
    },
    hideAuctionError(state, action: PayloadAction<any>) {
      state.active.isLoading = false;
    },
    showFormHidden(state, action: PayloadAction<boolean>) {
      state.active.showHiddenForm = action.payload;
    },
    // end hide auction
    fetchEndedAuction(state, action: PayloadAction<AuctionParams>) {
      state.ended.isLoading = true;
      state.ended.params = action.payload;
    },
    fetchEndedAuctionSuccess(state, action: PayloadAction<AuctionResponse>) {
      state.ended.isLoading = false;
      state.ended.rows = action.payload.rows;
      state.ended.total = action.payload.total;
    },
    fetchEndedAuctionError(state, action: PayloadAction<AuctionError>) {
      state.ended.isLoading = false;
      state.ended.error = action.payload;
    },
    fetchWaitingAuction(state, action: PayloadAction<AuctionParams>) {
      state.waiting.isLoading = true;
      state.waiting.params = action.payload;
    },
    fetchWaitingAuctionSuccess(state, action: PayloadAction<AuctionResponse>) {
      state.waiting.isLoading = false;
      state.waiting.rows = action.payload.rows;
      state.waiting.total = action.payload.total;
    },
    fetchWaitingAuctionError(state, action: PayloadAction<AuctionError>) {
      state.waiting.isLoading = false;
      state.waiting.error = action.payload;
    },
    setWaitingLoading(state, action: PayloadAction<boolean>) {
      state.waiting.isLoading = action.payload;
    },
  },
});

export const { actions: auctionActions } = slice;

export const useAuctionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: auctionSaga });
  return { actions: slice.actions };
};
