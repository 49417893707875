import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { WalletState } from './types';
import { walletSaga } from './saga';

export const initialState: WalletState = {
  wallet: null,
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    storeWallet(state, action: PayloadAction<Object>) {
      state.wallet = action.payload;
    },

    emptyWallet(state) {
      state.wallet = null;
    },
  },
});

export const { actions: walletActions } = slice;

export const useWalletSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: walletSaga });
  return { actions: slice.actions, wActions: slice.actions };
};
