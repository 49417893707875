import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { Box } from '@mui/system';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Section from './conponents/Section/Section';
import { ModalOffcailPawn } from './conponents/Modal/Modal';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';

import { useDispatch, useSelector } from 'react-redux';
import { select } from './slice/selectors';
import { HomePawn } from './slice';
import { ViewOfficalPawnShopProps } from './slice/types';

interface OfficalPawnShopProps {
  rows: ViewOfficalPawnShopProps[];
  setIsModal?: any;
}

function createData(
  name: string,
  email: string,
  token: number,
  addedDate: number,
  position: number,
  isDelete: boolean,
) {
  return { name, email, token, addedDate, position, isDelete };
}

const Index = () => {
  const dispatch = useDispatch();
  const { actions } = HomePawn();
  //OffcialPawn state
  const [isEditOfficalPawnShop, setIsEditOfficalPawnShop] =
    React.useState(false);
  const [isModalOffcialPawn, setIsModalOffcialPawn] = React.useState(false);

  const openModalOffcialPawn = () => {
    setIsModalOffcialPawn(true);
  };

  const data = useSelector(select);

  React.useEffect(() => {
    getDataOffcialPawn();
  }, []);

  const getDataOffcialPawn = () => {
    dispatch(actions.getDataOffcialPawnRequire());
  };

  return (
    <>
      <Helmet>
        <title>Homepage Pawn Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <OneColumnLayout>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '30px',
            }}
          >
            <Typography
              component="h3"
              sx={{
                fontSize: '28px',
                lineHeight: '34px',
                flexGrow: 1,
                fontWeight: '500',
              }}
            >
              Home page setup
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#C3c3c3',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#ccc',
                  color: '#000',
                },
              }}
            >
              Preview
            </Button>
          </Box>

          <Box>
            <Section
              titleName="Official pawnshop with new token"
              setIsEdit={setIsEditOfficalPawnShop}
              IsEdit={isEditOfficalPawnShop}
              getData={getDataOffcialPawn}
              data={data.ListOffcialPawn}
            >
              {isEditOfficalPawnShop ? (
                <EditOfficalPawnShop
                  rows={data.ListOffcialPawn}
                  setIsModal={openModalOffcialPawn}
                />
              ) : (
                <ViewOfficalPawnShop rows={data.ListOffcialPawn} />
              )}
            </Section>
            <ModalOffcailPawn
              setIsModalOffcialPawn={setIsModalOffcialPawn}
              isModalOffcialPawn={isModalOffcialPawn}
            />
          </Box>
        </Box>
      </OneColumnLayout>
    </>
  );
};

export default Index;

const EditAction = props => {
  const handleUp = () => {
    props.hanldeUp(props.position);
  };

  const handleDown = () => {
    props.handleDown(props.position);
  };

  const handleDelete = () => {
    props.handleDelete(props.id);
  };

  return (
    <TableCell
      align="left"
      sx={{
        borderBottom: props.last ? 'none' : '1px solid rgba(224, 224, 224, 1)',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <Button
        variant="text"
        sx={{ color: '#000', minWidth: 'auto', padding: '0' }}
        onClick={() => handleDelete()}
      >
        <DeleteOutlineIcon />
      </Button>
      <Button
        variant="text"
        sx={{ color: '#000', minWidth: 'auto', padding: '0' }}
        onClick={() => handleUp()}
      >
        <ArrowDropUpIcon sx={{ marginBottom: '-1px' }} />
      </Button>
      <Button
        variant="text"
        sx={{ color: '#000', minWidth: 'auto', padding: '0' }}
        onClick={() => handleDown()}
      >
        <ArrowDropDownIcon sx={{ marginTop: '-1px' }} />
      </Button>
    </TableCell>
  );
};

const ViewOfficalPawnShop = (props: OfficalPawnShopProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Pawnshop</TableCell>
            <TableCell>Email</TableCell>
            <TableCell align="center">Added date</TableCell>
            <TableCell>Token</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell align="center">{row.addedDate}</TableCell>
                <TableCell>{row.token}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EditOfficalPawnShop = (props: OfficalPawnShopProps) => {
  const data = [...props.rows];

  const dispatch = useDispatch();
  const { actions } = HomePawn();
  const handleUp = e => {
    dispatch(actions.UpPositionOffcialPawnAction(e));
  };
  const handleDown = e => {
    dispatch(actions.DownPositionOffcialPawnAction(e));
  };
  const handleDelete = e => {
    dispatch(actions.deleteDataOffcialPawnItem(e));
  };

  const openModal = () => {
    props.setIsModal(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Pawnshop</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Token</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .sort(
              (a: ViewOfficalPawnShopProps, b: ViewOfficalPawnShopProps) =>
                a.position - b.position,
            )
            .map((row, index) => {
              let last = false;
              if (index === data.length - 1) {
                last = true;
              }
              if (!row.isDelete) {
                return (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderBottom: last
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)',
                      }}
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: last
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)',
                      }}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: last
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: last
                          ? 'none'
                          : '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      {row.token}
                    </TableCell>
                    <EditAction
                      last={last}
                      hanldeUp={handleUp}
                      handleDown={handleDown}
                      position={row.position}
                      id={row.id}
                      handleDelete={handleDelete}
                    />
                  </TableRow>
                );
              }
            })}
          <TableRow>
            <TableCell align="center" colSpan={6}>
              <Button
                variant="contained"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '18px',
                  padding: '8px 12px',
                  backgroundColor: '#C3c3c3',
                  color: '#000',
                  '&:hover': {
                    backgroundColor: '#ccc',
                    color: '#000',
                  },
                }}
                onClick={openModal}
              >
                <AddIcon
                  sx={{
                    fontSize: '15px',
                    marginRight: '4px',
                  }}
                />
                Add
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
