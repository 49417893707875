import * as React from 'react';
import { useState } from 'react';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import GavelIcon from '@mui/icons-material/Gavel';
import ViewListIcon from '@mui/icons-material/ViewList';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from 'app/pages/LoginPage/slice';
import { selectLoggedInUser } from 'app/pages/LoginPage/slice/selectors';
import { DfyPopupConnectWallet } from '../DfyPopupConnectWallet';
import { selectWallet } from '../Wallet/slice/selectors';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '.MuiPaper-elevation': {},
}));

const menus = [
  {
    icon: <HomeIcon />,
    label: 'Homepage',
    href: '/home',
  },
  {
    icon: <HomeIcon />,
    label: 'Home Pawn',
    href: '/homepage-pawn',
  },
  {
    icon: <GavelIcon />,
    label: 'Auction',
    href: '/auction',
  },
  {
    icon: <GavelIcon />,
    label: 'Collection',
    href: '/collection',
  },
  {
    icon: <BusinessCenterIcon />,
    label: 'PawnShop',
    href: '/pawn-shop',
  },
  {
    icon: <ViewListIcon />,
    label: 'Token list',
    href: '/token-list',
  },
  {
    icon: <BusinessCenterIcon />,
    label: 'Vesting',
    href: '/vesting',
  },
  {
    icon: <GavelIcon />,
    label: 'Scheme',
    href: '/scheme',
  },
];

export default function MiniDrawer() {
  const [open, setOpen] = React.useState(true);
  const { actions } = useAuthSlice();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);
  const walletState = useSelector(selectWallet);

  const toggleDrawer = () => {
    setOpen(prevCheck => !prevCheck);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConnectWallet = () => {
    setAnchorEl(null);
    setAutoConnect(false);
    setOpenConnect(true);
  };

  const handleLogout = () => {
    dispatch(actions.logoutRequest());
    handleClose();
  };

  const [openConnect, setOpenConnect] = useState<boolean>(false);
  const [autoConnect, setAutoConnect] = useState<boolean>(false);

  const handleCloseConnect = () => {
    setOpenConnect(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{}}
          >
            {open && <MenuIcon />}
            {!open && <ChevronRightIcon />}
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Marketplace Admin
          </Typography>
          <div>
            {walletState && walletState.wallet !== null ? (
              walletState.wallet + ' - '
            ) : (
              <label>Chưa kết nối ví - </label>
            )}
            {loggedInUser && loggedInUser.name}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleConnectWallet}>Connect</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar />
        <Divider />
        <List>
          {menus.map((menu, index) => (
            <ListItem
              button
              key={index}
              component={NavLink}
              to={menu.href}
              activeClassName="Mui-selected"
            >
              <ListItemIcon sx={{ minWidth: '40px' }}>{menu.icon}</ListItemIcon>
              <ListItemText primary={menu.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <DfyPopupConnectWallet
        onClose={handleCloseConnect}
        isOpen={openConnect}
        handle={handleClose}
        auto={autoConnect}
      />
    </Box>
  );
}
