import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useSlice } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectVesting } from '../../slice/selectors';
import * as yup from 'yup';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment, { Moment } from 'moment';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import { Box } from '@mui/system';
import DialogConfirmPopUp from '../DialogConfirmVesting';
import { selectGlocalState } from 'app/pages/GlobalStateWrapper/slice/selectors';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CustomizedDialogs(props) {
  const dispatch = useDispatch();
  const { actions } = useSlice();
  const {
    isPopupCreateVesting,
    namePopUpCreateVesting,
    dataSchemeCreate,
    receiptBC,
  } = useSelector(selectVesting);

  const { contracts } = useSelector(selectGlocalState);
  const [selectScheme, setSelectScheme] = React.useState<any>('');
  const [textEr, setTextEr] = React.useState(false);
  const [textErW, setTextErW] = React.useState(false);
  const [value, setValue] = React.useState<Date | null>(new Date(''));
  const [selectTokenRelease, setSelectTokenRelease] = React.useState(0);
  const [dataConfirm, setDataConfirm] = React.useState<any>({
    name: '',
    from: '',
    addressWallet: '',
    totalAmount: 0,
    amountDeposit: 0,
    schemeId: '',
    startTime: 0,
    spender: '',
    tokenRelease: 0,
    vestedAmount: 0,
    token: '',
  });
  const wallet = useSelector(selectWallet).wallet;

  const handleChangeDate = (newValue: Date | null, f) => {
    f.onChange(newValue);
    setValue(newValue);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectScheme(event.target.value as string);
  };

  let schema = yup.object({
    vestingName: yup
      .string()
      .required('Vesting name is required')
      .max(50, 'Maximum length allowed is 50 characters'),
    wallet: yup
      .string()
      .required('Receiving wallet address is required')
      .matches(/^(0x)[a-fA-F0-9]+$/, 'Invalid wallet address')
      .min(42, 'Invalid wallet address')
      .max(42, 'Invalid wallet address'),
    totalAmount: yup
      .number()
      .required('Total amount is required')
      .min(0, 'Min value >= 0')
      .test(
        'onlyNumbers',
        'Total amount must greater than vested amount',
        (value: any) => value > dataConfirm.vestedAmount,
      )
      .test('onlyNumbers', 'Max 5 digits after decimal point', (value: any) =>
        /^\d+(\.\d{1,5})?$/.test(value),
      )
      .typeError('Total is required'),

    vestedAmount: yup
      .number()
      .required('Vested amount is required')
      .min(0, 'Min value >= 0')
      .test('onlyNumbers', 'Max 5 digits after decimal point', (value: any) =>
        /^\d+(\.\d{1,5})?$/.test(value),
      )
      .test(
        'onlyNumbers',
        'Vested amount can not greater than Total amount',
        (value: any) => value < dataConfirm.totalAmount,
      )
      .typeError('Vested amount is required'),
    date: yup
      .date()
      .required('Start date is required')
      .typeError('Invalid Date'),
  });

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    control,
  } = form;

  const onSubmit = e => {
    const param = {
      name: e.vestingName,
      from: wallet,
      addressWallet: e.wallet,
      totalAmount: e.totalAmount,
      amountDeposit:
        namePopUpCreateVesting === 'without'
          ? 0
          : e.totalAmount - e.vestedAmount,
      totalClaimed: e.vestedAmount,
      schemeId: selectScheme,
      startTime:
        moment(e.date, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').valueOf() / 1000,
      spender: contracts?.Vesting,
      tokenRelease: selectTokenRelease,
      vestedAmount: e.vestedAmount,
      schemeName: dataSchemeCreate.find(
        item => item.schemeBcId === selectScheme,
      ).schemeName,
      token: dataSchemeCreate.find(
        (item, inx) => item.schemeBcId === selectScheme,
      ).cryptoAssetSymbol,
    };

    if (selectScheme !== '' || wallet !== null) {
      setDataConfirm(param);
      const paramApprove = {
        curAddress: param.from,
        tokenSymbol: param.token,
        toAddress: param.spender,
        amount: param.amountDeposit,
      };
      dispatch(actions.callCheckApproveRequest(paramApprove));
    } else {
      setTextEr(true);
    }
  };

  React.useEffect(() => {
    if (isPopupCreateVesting === false) {
      form.reset();
      setSelectScheme('');
      setValue(new Date(''));
      setTextEr(false);
    }
  }, [isPopupCreateVesting]);

  React.useEffect(() => {
    if (selectScheme !== '') {
      setTextEr(false);
    }
  }, [selectScheme]);

  React.useEffect(() => {
    if (wallet) {
      setTextErW(false);
    }
  }, [wallet]);

  React.useEffect(() => {
    const param = {
      addressWallet: dataConfirm.addressWallet,
      schemeId: selectScheme,
      totalAmount: dataConfirm.totalAmount,
      vestedAmount: dataConfirm.vestedAmount,
      isHasToken: namePopUpCreateVesting === 'with' ? true : false,
      txnHash: receiptBC.transactionHash,
      name: dataConfirm.name,
      startedTime: dataConfirm.startTime,
      tokenReleaseType: dataConfirm.tokenRelease,
    };
    if (receiptBC.transactionHash) {
      dispatch(actions.postVesting(param));
    }
  }, [receiptBC.transactionHash]);

  const handleTotalAmount = value => {
    setDataConfirm({ ...dataConfirm, totalAmount: value });
  };

  const handleVestedAmount = value => {
    setDataConfirm({ ...dataConfirm, vestedAmount: value });
  };

  return (
    <div>
      <BootstrapDialog open={isPopupCreateVesting}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            dispatch(actions.setPopUp(false));
            setValue(new Date(''));
          }}
        >
          Create vesting {namePopUpCreateVesting} token
        </BootstrapDialogTitle>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent sx={{ width: '600px' }}>
            <Typography>Vesting name:</Typography>
            <Controller
              name="vestingName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  placeholder="Enter name"
                  helperText={errors['vestingName']?.message}
                  {...field}
                  error={Boolean(errors['vestingName'])}
                  type="text"
                  autoComplete="off"
                  fullWidth
                />
              )}
            />
            <Typography sx={{ marginTop: '12px' }}>Wallet address:</Typography>
            <Controller
              name="wallet"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  helperText={errors['wallet']?.message}
                  {...field}
                  error={Boolean(errors['wallet'])}
                  type="text"
                  autoComplete="off"
                  fullWidth
                  placeholder="Enter address"
                />
              )}
            />
            <Typography sx={{ marginTop: '12px' }}>Scheme:</Typography>
            <FormControl fullWidth>
              <Select
                value={selectScheme}
                displayEmpty
                onChange={handleChange}
                MenuProps={MenuProps}
              >
                <MenuItem value={''} sx={{ color: '#aaa' }} disabled>
                  Select scheme
                </MenuItem>
                {dataSchemeCreate.map((item, inx) => (
                  <MenuItem key={inx} value={item.schemeBcId}>
                    {item.schemeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {textEr && (
              <Typography
                sx={{ fontSize: '12px', color: '#d32f2f', marginLeft: '13px' }}
              >
                Scheme is required
              </Typography>
            )}
            <Typography sx={{ marginTop: '12px' }}>Total amount:</Typography>
            <Controller
              name="totalAmount"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={errors['totalAmount']?.message}
                  {...field}
                  error={Boolean(errors['totalAmount'])}
                  type="number"
                  autoComplete="off"
                  fullWidth
                  placeholder="Enter amount"
                  onChange={e => {
                    field.onChange(e.target.value);
                    handleTotalAmount(e.target.value);
                  }}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      display: 'flex',
                      justifyContent: 'flex-end',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {dataSchemeCreate.map((item, inx) => {
                          if (item.schemeBcId === selectScheme) {
                            return (
                              <div key={inx}>
                                <img
                                  key={inx}
                                  src={
                                    require(`app/assets/${
                                      item?.cryptoAssetSymbol
                                        ? item?.cryptoAssetSymbol
                                        : 'DFY'
                                    }.png`).default
                                  }
                                  alt=""
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '4px',
                                  }}
                                />{' '}
                                {item?.cryptoAssetSymbol ? (
                                  item?.cryptoAssetSymbol
                                ) : (
                                  <Typography key={inx}>DFY</Typography>
                                )}
                              </div>
                            );
                          }
                        })}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Typography sx={{ marginTop: '12px' }}>Vested amount:</Typography>
            <Controller
              name="vestedAmount"
              control={control}
              render={({ field }) => (
                <TextField
                  helperText={errors['vestedAmount']?.message}
                  {...field}
                  error={Boolean(errors['vestedAmount'])}
                  type="number"
                  fullWidth
                  onChange={e => {
                    field.onChange(e.target.value);
                    handleVestedAmount(e.target.value);
                  }}
                  placeholder="Enter amount"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {dataSchemeCreate.map(item => {
                          if (item.schemeBcId === selectScheme) {
                            return (
                              <>
                                <img
                                  src={
                                    require(`app/assets/${
                                      item?.cryptoAssetSymbol
                                        ? item?.cryptoAssetSymbol
                                        : 'DFY'
                                    }.png`).default
                                  }
                                  alt=""
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    marginRight: '4px',
                                  }}
                                />{' '}
                                {item?.cryptoAssetSymbol
                                  ? item?.cryptoAssetSymbol
                                  : 'DFY'}
                              </>
                            );
                          }
                        })}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Typography sx={{ marginTop: '12px' }}>Start date:</Typography>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MM/dd/yyyy"
                    value={value}
                    onChange={(date: any) => handleChangeDate(date, field)}
                    renderInput={params => (
                      <TextField
                        {...field}
                        {...params}
                        helperText={errors['date']?.message}
                        error={Boolean(errors['date'])}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
            <Typography sx={{ marginTop: '12px' }}>Token release:</Typography>
            <FormControl fullWidth>
              <Select
                value={selectTokenRelease}
                displayEmpty
                onChange={e => {
                  setSelectTokenRelease(e.target.value as number);
                }}
              >
                <MenuItem value={0}>Daily</MenuItem>
                <MenuItem value={1}>Weekly</MenuItem>
                <MenuItem value={2}>Monthly</MenuItem>
                <MenuItem value={3}>Quarterly</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          {!wallet && (
            <Typography
              sx={{ fontSize: '12px', color: '#d32f2f', marginLeft: '13px' }}
            >
              Please connect wallet before create vesting
            </Typography>
          )}
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{ margin: '12px 0 12px 0' }}
              type="submit"
              disabled={!wallet}
            >
              Create vesting plan
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <DialogConfirmPopUp data={dataConfirm} />
    </div>
  );
}
