import * as gasInfo from '../supportService/getGasInformation';
import Web3 from '../initWeb3';
import erc20Abi from '../config/erc20.abi.json';
import softNftAbi from '../config/softNft.abi.json';
import { filterTokenToAddress } from '../supportService/filterTokenToAddress';
import BigNumber from 'bignumber.js';
import { store } from 'index';

/**
 * check approve
  @param {string} from format wallet address
  @param {string} tokenSymbol
  @param {string} spender
 * @returns
 */

const MAX_INT = '115792089237316195423570985008687907853269984665640564039457';
export const checkApprove = async (
  from,
  tokenSymbol,
  spender,
  amountApprove,
) => {
  if (tokenSymbol === 'BNB') {
    return amountApprove;
  } else {
    console.log(from, tokenSymbol, spender, amountApprove);

    const instanceValue = Web3.getInstance;
    const web3: any = instanceValue.getWeb3();
    const supportSymbol = store.getState().glocalState.StoreCryptoCurrency;

    const tokenAddress = filterTokenToAddress(supportSymbol, tokenSymbol);

    const tokenContract = new web3.eth.Contract(erc20Abi, tokenAddress);
    console.log(tokenContract);

    const allowance = await tokenContract.methods
      .allowance(from, spender)
      .call();

    return allowance;
  }
};

/**
 * create approve
  @param {} from format wallet address
  @param {} tokenSymbol
  @param {} amount
  @param {} spender
 * @returns
 */
export const createApprove = async (
  from,
  tokenSymbol,
  spender,
  amountApprove,
) => {
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();
  const supportSymbol = JSON.parse(
    localStorage.getItem('StoreCryptoCurrency')!,
  );

  const tokenAddress = supportSymbol.filter(
    item => item.symbol === tokenSymbol,
  )[0].address;

  const tokenContract = new web3.eth.Contract(
    erc20Abi,
    tokenAddress, // sua
  );

  const txData = tokenContract.methods.approve(spender, MAX_INT);

  const nonce = await web3.eth.getTransactionCount(from, 'pending');
  // data tx
  const tx = {
    from,
    to: tokenAddress, // sua,
    value: 0,
    nonce,
    data: txData.encodeABI(),
  };

  const gasData = await gasInfo.getGasInformation(tx);

  return {
    tx,
    gasPrice: gasData.gasPrice,
    gasLimit: gasData.gasLimit,
  };
};

/**
 * check isApprovedForAll contract collection nft token
  @param {string} from format wallet address
  @param {string} contractSale
  @param {string} collectionAddress
 * @returns
 */
export const isApprovedForAll = async (
  from,
  contractSale,
  collectionAddress,
) => {
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();

  const collectionSoftNftContract = new web3.eth.Contract(
    softNftAbi,
    collectionAddress,
  );
  const allowance = await collectionSoftNftContract.methods
    .isApprovedForAll(from, contractSale)
    .call();
  return allowance;
};

/**
   * create setApprovalForAll contract collection nft token
    @param {} from format wallet address
    @param {} contractSale
    @param {boolean} condition  
    @param {} collectionAddress
   * @returns
   */
export const setApprovalForAll = async (
  from,
  contractSale,
  condition,
  collectionAddress,
) => {
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();

  const collectionSoftNftContract = new web3.eth.Contract(
    softNftAbi,
    collectionAddress,
  );

  const txData = collectionSoftNftContract.methods.setApprovalForAll(
    contractSale,
    condition,
  );

  const nonce = await web3.eth.getTransactionCount(from, 'pending');
  // data tx
  const tx = {
    from,
    to: collectionAddress,
    value: 0,
    nonce,
    data: txData.encodeABI(),
  };

  const gasData = await gasInfo.getGasInformation(tx);

  return {
    tx,
    gasPrice: gasData.gasPrice,
    gasLimit: gasData.gasLimit,
  };
};
