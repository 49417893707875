import { call, put, takeLatest } from 'redux-saga/effects';
import pawnShopServices from 'services/pawnShopServices';
import { pawnShopActions as actions } from '.';

function* getPawnShopHandler(action) {
  try {
    const response = yield call(pawnShopServices.getPawnShop, action.payload);
    yield put(actions.fetchPawnShopSuccess(response?.data));
  } catch (err: any) {
    yield put(actions.fetchPawnShopError(err.response.data));
  }
}

export function* pawnShopSaga() {
  yield takeLatest(actions.fetchPawnShop.type, getPawnShopHandler);
}
