import React, { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGlocalStateSlice } from './slice';

interface GlobalStateWrapperProps {
  children: ReactNode;
}

export default function GlobalStateWrapper(props: GlobalStateWrapperProps) {
  const { actions } = useGlocalStateSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.someAction());
    dispatch(actions.getStoreCryptoCurrencyRequest());
  }, []);

  // useEffect(() => {

  // }, []);

  return <>{props.children}</>;
}
