import { call, put, takeLatest } from 'redux-saga/effects';
import collectionServices from 'services/collectionServices';
import { collectionActions as actions } from '.';

function* getCollectionHandler(action) {
  try {
    const response = yield call(
      collectionServices.getCollection,
      action.payload,
    );
    yield put(actions.fetchCollectionSuccess(response?.data));
  } catch (err: any) {
    yield put(actions.fetchCollectionError(err.response.data));
  }
}

export function* collectionSaga() {
  yield takeLatest(actions.fetchCollection.type, getCollectionHandler);
}
