// import Web3 from '../initWeb3';
import contractHubAbi from '../config/contractHub.abi.json';
import { store } from 'index';
import { glocalStateActions } from 'app/pages/GlobalStateWrapper/slice';

// import Web3 from 'moralis';
/**
 * check owner nft on auction
 * @param {*} spender (hub)
 * @returns
 */
export const getContractBySignature = async (
  spender = process.env.REACT_APP_HUB_ADDRESS,
) => {
  try {
    const Moralis = require('moralis');
    const web3 = await Moralis.enableWeb3();
    const hubContract = new web3.eth.Contract(contractHubAbi, spender);

    const addresses = await Promise.all([
      hubContract.methods
        .getContractAddress(process.env.REACT_APP_VOTING_CONTRACT_ADDRESS)
        .call(),
      hubContract.methods
        .getContractAddress(process.env.REACT_APP_VESTING_CONTRACT_ADDRESS)
        .call(),
      hubContract.methods
        .getContractAddress(process.env.REACT_APP_NFT_AUCTION_ADDRESS)
        .call(),
    ]);
    let data = addresses.reduce(function (res, item) {
      let keyIndex = Object.keys(item)[3];
      let key = item[keyIndex];
      let valueIndex = Object.keys(item)[2];
      let value = item[valueIndex];
      res[key] = value;
      return res;
    }, {});
    return data;
  } catch (error) {
    return;
  }
};
