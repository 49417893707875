import Web3 from '../initWeb3';

export const getGasInformation = async txRaw => {
  const instanceValue = Web3.getInstance;
  const web3: any = instanceValue.getWeb3();
  const gasPrice = await web3.eth.getGasPrice();
  let gasLimit = 0;
  let hasError: boolean = false;
  let msgError: string = '';
  try {
    gasLimit = await web3.eth.estimateGas(txRaw);
  } catch (error) {
    gasLimit = 4000000;
    console.log(error, 'error gas');
    console.log('errCode: ' + error.code + ', errMessage: ' + error.message);
    hasError = true;
    msgError = error.message;
    if (msgError && msgError.startsWith('Internal JSON-RPC error.')) {
      let s = msgError.replaceAll('Internal JSON-RPC error.', '');
      let j = JSON.parse(s);
      if (j && j.message) {
        msgError = j.message;
      }
    }
  }
  return {
    gasPrice,
    gasLimit,
    hasError,
    msgError,
  };
};
