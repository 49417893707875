import { getContractBySignature } from 'services/walletService/supportService/contractHub';
import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { glocalStateActions as actions } from '.';
import homePageServices from 'services/homePageServices';

function* doSomething() {
  try {
    const contract = yield call(getContractBySignature);
    yield put(actions.someThingTrue(contract));
  } catch (error) {
    console.log('Error contract:', error);
  }
}
function* watchHandleCallApiCrypto(a) {
  try {
    const res = yield homePageServices.getCryptoCurrency();
    yield put(actions.getStoreCryptoCurrencySuccess(res?.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* glocalStateSaga() {
  yield takeLatest(actions.someAction.type, doSomething);
  yield takeLatest(
    actions.getStoreCryptoCurrencyRequest.type,
    watchHandleCallApiCrypto,
  );
}
