import { BigNumber } from 'bignumber.js';
import { pinJSONToIPFS } from './../../../../services/ipfsService/pinJSONToIPFS';
import moment from 'moment';
import { take, call, put, select, takeLatest, all } from 'redux-saga/effects';
import { pinFileToIPFS } from 'services/ipfsService/pinFileToIPFS';
// import { addNewTokenActions as actions } from '.';
import Web3 from 'services/walletService/initWeb3';
import abi from 'services/walletService/config/voting.abi.json';
import * as gasInfo from 'services/walletService/supportService/getGasInformation';
import { signAndSendTx } from 'services/walletService/supportService/signAndSendTx';
import addNewTokenServices from 'services/apiAddNewToken';
import { actions } from '.';

const handleMomentS = value => {
  return moment(value, 'ddd MMM DD YYYY HH:mm:ss GMTZZ').valueOf() / 1000;
};

function* handleAddNewToken(action) {
  if (action.payload.type === '0') {
    try {
      const ipfs = yield call(pinFileToIPFS, action.payload.upLoadFileImg.data);

      const additional_info =
        action.payload.properties === undefined
          ? []
          : action.payload.properties.map(e => {
              return { title: e.trait_type, content: e.value };
            });

      const data = {
        symbol: action.payload.ticker,
        name: action.payload.tokenName,
        contract: action.payload.contract,
        logo_cid: ipfs.data.IpfsHash,
        from_date: handleMomentS(action.payload.dateForm),
        to_date: handleMomentS(action.payload.dateTo),
        project_name: action.payload.project,
        telegram: action.payload.telegram,
        twitter: action.payload.twitter,
        target_voting: action.payload.voting,
        reward_pool: action.payload.reward,
        additional_info: additional_info,
        website: action.payload.website,
      };

      const ipfsJson = yield call(pinJSONToIPFS, data);

      const from = action.payload.wallet;

      const spender = action.payload.spender;

      //call BC start
      const initWeb3 = yield Web3.getInstance;

      const web3: any = initWeb3.getWeb3();

      const addNewToken1 = new web3.eth.Contract(abi, spender);

      const addNewTokenData = addNewToken1.methods.addNewToken(
        data.contract,
        new BigNumber(Number(data.target_voting))
          .multipliedBy(10 ** 18)
          .toFixed(),
        new BigNumber(Number(data.reward_pool))
          .multipliedBy(10 ** 18)
          .toFixed(),
        Number(data.from_date),
        Number(data.to_date),
        ipfsJson.data.IpfsHash,
      );

      const nonce = yield web3.eth.getTransactionCount(from, 'pending');

      const tx = {
        from,
        to: spender,
        value: 0,
        nonce,
        data: addNewTokenData.encodeABI(),
      };
      const gasData = yield gasInfo.getGasInformation(tx);

      const txAddNewToken = {
        tx,
        gasPrice: gasData.gasPrice,
        gasLimit: gasData.gasLimit,
      };

      const receipt = yield signAndSendTx(txAddNewToken);

      const dataApi = {
        ...data,
        txn_hash: receipt.transactionHash,
        token_cid: ipfsJson.data.IpfsHash,
        type: 0,
      };

      yield call(addNewTokenServices.addNew, dataApi);

      //call BC end

      yield put(actions.addNewTokenSuc());
    } catch (error) {
      yield put(actions.addNewTokenFail());
    }
  }
}
function* handleAddNewTokenList(action) {
  try {
    const ipfs = yield call(
      pinFileToIPFS,
      action.payload.data.upLoadFileImg.data,
    );

    const data = {
      type: 1,
      symbol: action.payload.data.ticker,
      name: action.payload.data.tokenName,
      contract: action.payload.data.contract,
      logo_cid: ipfs.data.IpfsHash,
      whitelist_type: action.payload.whitelist_type,
      price_from: action.payload.price_from,
      oracle_id: action.payload.oracle_id,
      price_feed_address: action.payload.price_feed_address,
    };

    const ipfsJson = yield call(pinJSONToIPFS, data);

    const from = action.payload.data.wallet;

    const spender = action.payload.data.spender;

    // //call BC start
    const initWeb3 = yield Web3.getInstance;

    const web3: any = initWeb3.getWeb3();

    const addNewTokenList = new web3.eth.Contract(abi, spender);

    const addNewTokenDataList = addNewTokenList.methods.ListAddNewToken(
      data.contract,
      data.price_feed_address,
      action.payload.PutNewTokenAs.includes('0') ? 1 : 0,
      ipfsJson.data.IpfsHash,
    );

    const nonce = yield web3.eth.getTransactionCount(from, 'pending');

    const tx = {
      from,
      to: spender,
      value: 0,
      nonce,
      data: addNewTokenDataList.encodeABI(),
    };

    const gasData = yield gasInfo.getGasInformation(tx);

    const txAddNewToken = {
      tx,
      gasPrice: gasData.gasPrice,
      gasLimit: gasData.gasLimit,
    };

    const receipt = yield signAndSendTx(txAddNewToken);

    const dataApi = {
      ...data,
      txn_hash: receipt.transactionHash,
      token_cid: ipfsJson.data.IpfsHash,
    };

    yield call(addNewTokenServices.addNewList, dataApi);

    //call BC end

    yield put(actions.addNewTokenSucList());
  } catch (error) {
    yield put(actions.addNewTokenFailList());
  }
}

function* addNewTokenSagaType_0() {
  yield takeLatest(actions.addNewToken.type, handleAddNewToken);
}

function* addNewTokenSagaType_1() {
  yield takeLatest(actions.addNewTokenList.type, handleAddNewTokenList);
}

export function* addNewTokenSaga() {
  yield all([addNewTokenSagaType_0(), addNewTokenSagaType_1()]);
}
