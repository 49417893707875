import {
  CollectionError,
  CollectionGetParams,
  CollectionResponse,
  CollectionState,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../../utils/redux-injectors';
import { collectionSaga } from './saga';

export const initState: CollectionState = {
  isLoading: false,
  params: {
    name: '',
    collection_type: '',
    owner_account: '',
    page: 1,
    size: 50,
  },
  collections: [],
  total: 0,
};

const slice = createSlice({
  name: 'collections',
  initialState: initState,
  reducers: {
    fetchCollection(state, action: PayloadAction<CollectionGetParams>) {
      state.isLoading = true;
      state.params = action.payload;
    },
    fetchCollectionSuccess(state, action: PayloadAction<CollectionResponse>) {
      state.isLoading = false;
      state.collections = action.payload.rows;
      state.total = action.payload.total;
    },
    fetchCollectionError(state, action: PayloadAction<CollectionError>) {
      state.isLoading = false;
      state.collections = [];
      state.total = 0;
    },
  },
});

export const { actions: collectionActions } = slice;

export const useCollectionSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: collectionSaga });
  return { actions: slice.actions };
};
