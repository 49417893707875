//imports needed for this function
const axios = require('axios');
const FormData = require('form-data');

export const pinFileToIPFS = file => {
  const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';

  // we gather a local file for this example, but any valid readStream source will work here.
  let data = new FormData();
  data.append('file', file);

  return axios.post(url, data, {
    maxContentLength: 'Infinity', // this is needed to prevent axios from erroring out with large files
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      pinata_api_key: 'ac8828bff3bcd1c1b828',
      pinata_secret_api_key:
        'cd1b0dc4478a40abd0b80e127e1184697f6d2f23ed3452326fe92ff3e92324df',
    },
  });
};
