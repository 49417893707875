import { Dialog, styled } from '@mui/material';
import { Box } from '@mui/system';

export const Modal = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '20px',
    maxWidth: '645px',
    minHeight: '367px',
    padding: '0px 0px 24px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    },
    backgroundColor: '#000000',
    '.MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.MuiTypography-body1': {
      textAlign: 'center',
    },
    '.MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '37px',
      color: '#FFFFFF',
      padding: '20px 20px 52px 36px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '25px',
        padding: '20px 10px 32px 20px',
      },
    },
    '.MuiDialogContent-root': {
      padding: '0 45px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
      },
    },
    '.MuiSvgIcon-root': {
      fontSize: '39.5px',
    },
    '.content-text': {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      alignItems: 'center',
      color: '#FFFFFF',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginBottom: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '17px',
        lineHeight: '18px',
      },
    },
  },
  '.MuiButton-root': {
    backgroundImage: '#ffffff',
    minWidth: '140px',
    borderRadius: '1000px',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
  },
}));

interface color {
  types: string;
}

export const ButtonCustom = styled('button')<color>(({ theme, types }) => ({
  backgroundColor:
    types === '1'
      ? '#FFFFFF'
      : types === '2'
      ? '#ffffff'
      : types === '3'
      ? '#ffffff'
      : '#ffffff',
  outline: 'none',
  borderRadius: '5px',
  border: 'none',
  margin: '8px 0',
  height: '75px',
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 30px',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 6px',
  },
}));

export const BoxCustom = styled(Box)(({ theme }) => ({
  padding: '15px 16px 20px 16px',
  marginTop: '25px',
  backgroundColor: '#FFFFFF',
  borderRadius: '5px',
}));
