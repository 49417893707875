import { Paper } from '@mui/material';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import { Helmet } from 'react-helmet-async';
import { TokenListComponent } from './components/TokenListComponent';

export const TokenListPage = () => {
  return (
    <>
      <Helmet>
        <title>Token list Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <OneColumnLayout>
        <Paper
          elevation={3}
          sx={{
            padding: '16px',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <TokenListComponent />
        </Paper>
      </OneColumnLayout>
    </>
  );
};
