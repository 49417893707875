import { PayloadAction } from '@reduxjs/toolkit';
import authService from 'services/authService';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { loginSaga } from './saga';
import {
  AuthState,
  ILoginResponse,
  ISignInForm,
  ISignInFormSignature,
} from './types';

export const initialState: AuthState = {
  isAuthenticated: !!authService.getAccessToken(),
  loggedInUser: null,
  isSending: false,
  isFailure: false,
  accessToken: '',
  lastToken: '',
  walletError: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInRequest(state, action: PayloadAction<ISignInForm>) {
      state.isSending = true;
      state.isFailure = false;
    },
    signInSuccess(state, action: PayloadAction<ILoginResponse>) {
      state.isSending = false;
      state.isFailure = false;
      state.isAuthenticated = true;
      authService.setAccessToken(action.payload.access_token);
      authService.setRefreshToken(action.payload.refresh_token);
    },
    signInFailure(state, action: PayloadAction<string>) {
      state.isSending = false;
      state.isFailure = true;
      state.isAuthenticated = false;
    },
    logoutRequest(state) {
      state.isSending = false;
      state.isFailure = false;
      state.isAuthenticated = false;
      state.loggedInUser = null;

      authService.removeAccessToken();
      authService.removeRefreshToken();
    },
    fetchUserProfile(state) {},
    fetchUserProfileSuccess(state, action) {
      state.loggedInUser = action.payload;
    },
    fetchUserProfileFailure(state, action) {
      state.loggedInUser = null;
    },
    setAccessToken(state, action: any) {
      state.accessToken = action.payload;
      localStorage.setItem('access_token', action.payload);
    },
    setLastToken(state, action: PayloadAction<string>) {
      state.lastToken = action.payload;
      localStorage.setItem('lastToken', action.payload);
    },
    cleaTokens(state) {
      state.lastToken = '';
      state.accessToken = '';
    },
    signInSignatureRequest(
      state,
      action: PayloadAction<ISignInFormSignature>,
    ) {},
    getUserProfileSignature(state, action: PayloadAction<any>) {},
    errorConnectWallet(state, action: PayloadAction<boolean>) {
      state.walletError = action.payload;
    },
  },
});

export const { actions: loginActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loginSaga });
  return { actions: slice.actions };
};
