import apiClient from './apiService';

const pawnShopServices = {
  getPawnShop(params: any) {
    return apiClient.request({
      method: 'GET',
      params: params,
      url: '/defi-pawn-crypto-service/public-api/v1.0.0/pawn-shop/search-p2p-lenders',
    });
  },
};

export default pawnShopServices;
