import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuctionSlice } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import apiClient from 'services/apiService';
import { Alert, Snackbar } from '@mui/material';
import { selectActiveAuctions } from '../../slice/selectors';
import { NotifyInfo } from 'types/model';

interface Props {
  show: boolean;
  item: any;
  callback: (value: boolean) => void;
}

export default function HideDialog({ show, item, callback }: Props) {
  const { actions } = useAuctionSlice();
  const dispatch = useDispatch();
  const activeAuction = useSelector(selectActiveAuctions);

  const [nft, setNft] = useState<NotifyInfo>({
    show: false,
    content: '',
    severity: 'success',
  });

  const notify = (content: string, success: boolean) => {
    let n: NotifyInfo = {
      ...nft,
      show: true,
      content: content,
      severity: success ? 'success' : 'error',
    };
    setNft(n);
  };

  const handleCloseNotify = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    let n: NotifyInfo = { ...nft, show: false };
    setNft(n);
  };

  const onHideAuctionClicked = () => {
    let params = {
      id: item.id,
      is_show: false,
    };
    dispatch(actions.setActiveLoading(true));
    let success = false;
    apiClient
      .request({
        method: 'POST',
        data: params,
        url: '/api-svc/api/auction/hide-nft',
      })
      .then(data => {
        let msg = '';
        if (data && data.data) {
          console.log('data: ' + JSON.stringify(data.data));
          let rc = data.data.rc;
          if (rc === 0) {
            notify('Hide auction success', true);
            dispatch(actions.fetchActiveAuction(activeAuction.params));
            success = true;
            return;
          }
          msg = 'Hide auction success: ' + data.data.rd;
        } else {
          msg = 'Hide auction failed !';
        }
        notify(msg, false);
      })
      .catch(e => {
        console.log(e);
        let msg = 'Hide Auction failed';
        if (e && e.message) {
          msg += ': ' + e.message;
        }
        notify(msg, false);
      })
      .finally(() => {
        callback(false);
        if (!success) {
          dispatch(actions.setActiveLoading(false));
        }
      });
  };

  const handleClose = () => {
    callback(false);
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Hide NFT auction'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hide NFT auction will be hidden on platform but the auction still
            continue on blockchain
            <br />
            <strong>{item ? item.name : ''}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onHideAuctionClicked} autoFocus>
            Hide NFT
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={nft.show}
        autoHideDuration={3000}
        onClose={handleCloseNotify}
      >
        <Alert
          onClose={handleCloseNotify}
          severity={nft.severity}
          sx={{ width: '100%' }}
        >
          {nft.content}
        </Alert>
      </Snackbar>
    </>
  );
}
