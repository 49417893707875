const AppUtils = {
  calcTotalPage(size, total) {
    let result = total;
    result = Math.floor(total / size);
    const a1 = total % size;
    if (a1 !== 0) {
      result++;
    }
    return result;
  },

  formatNumberEx(n) {
    let c = 0,
      d = '.',
      t = ',',
      s = n < 0 ? '-' : '',
      i: any = parseInt((n = Math.abs(+n || 0).toFixed(c))) + '';
    let j = 0;
    j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : '')
    );
  },
};

export default AppUtils;
