import { InputAdornment } from '@mui/material';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { IconInputField } from './icon';
import { DfyInputFieldStyle } from './style';

interface DfyInputFieldProps {
  placeholder: string;
  name: string;
  form: UseFormReturn;
  disabled: boolean;
  type?: string;
  defaultValue?: string | number;
  onGetText?: (data) => void;
}

export const DfyInputField = (props: DfyInputFieldProps) => {
  const { control } = props.form;

  const {
    formState: { errors },
  } = props.form;

  const hasError = errors[props.name];

  return (
    <>
      <Controller
        name={props.name}
        control={control}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <DfyInputFieldStyle
            {...field}
            onChange={e => {
              field.onChange(e.target.value);
              if (props.onGetText) {
                props.onGetText(e);
              }
            }}
            sx={{
              '.MuiFormHelperText-root.Mui-error': {
                animation: 'example 0.4s ease',

                '@keyframes example': {
                  from: { transform: 'translateY(-8px)' },
                  to: { transform: 'translateY(0)' },
                },
              },
            }}
            autoComplete="off"
            error={Boolean(hasError)}
            helperText={hasError?.message}
            placeholder={props.placeholder}
            variant="outlined"
            disabled={props.disabled}
            styleDisabled={props.disabled}
            type={props.type}
            InputProps={{
              endAdornment: Boolean(hasError) ? (
                <InputAdornment position="end">
                  <IconInputField type="error" />
                </InputAdornment>
              ) : (
                ''
              ),
            }}
          />
        )}
      />
    </>
  );
};
