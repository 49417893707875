export interface FormSearch {
  data: any;
  total: number;
  loadingStatus: boolean;
  popupStatus: boolean;
  successStatus: boolean;
  errorStatus: boolean;
  loadingUI: boolean;
}

export enum TokenListEnum {
  DAYS = 90,
}

export enum TokenListStatusEnum {
  COMMING_SOON,
  VOTING,
  QUEUE,
  FAILED,
  EXECUTE,
  CANCELLED,
}
