/**
 *
 * OneColumnLayout
 *
 */
import * as React from 'react';
import { Container } from '@mui/material';
import Footer from 'app/components/Footer';
import Header from 'app/components/Header';
import { Box } from '@mui/system';

export function OneColumnLayout({ children }) {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Container
            maxWidth={false}
            sx={{ marginTop: '64px', padding: '0 !important' }}
          >
            {children}
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
