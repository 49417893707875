import { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet-async';
import ActiveSchemeDialog from './components/PopupScheneDetail/ActiveSchemeDialog';
import HideSchemeDialog from './components/PopupHideScheme/HideSchemeDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useSchemeSlice } from './slice';
import { useHistory } from 'react-router-dom';
import AppUtils from 'utils/app_utils';
import { selectScheme, selectToken, selectReRender } from './slice/selectors';
import { DurationType, SchemeState, SchemeTypeEnum } from './slice/types';
import { typeOf } from 'mathjs';
import SearchIcon from '@mui/icons-material/Search';
interface Props {
  afterStartTime: number;
  afterStartType: number;
  crypto_asset: {
    id: number;
    symbol: string;
  };
  durationTime: number;
  durationType: number;
  id: number;
  isDeleted: boolean;
  schemeName: string;
  status: number;
}

interface params {
  page: number;
  size: number;
  name?: string;
  status?: number;
  token?: string;
}

const Scheme = () => {
  const [tokenType, setTokenType] = useState<any>();
  const [statusType, setStatusType] = useState<any>();
  const [statusRow, setStatusRow] = useState<number | undefined>();
  const [idScheme, setIdScheme] = useState<string | undefined>();
  const [showDetailDialog, setShowDetailDialog] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const closeDetailDialog = (value: boolean) => {
    setShowDetailDialog(value);
  };

  const [hideSchemeDialog, setHideSchemeDialog] = useState<boolean>(false);

  const [detailScheme, setDetailScheme] = useState({
    cliffTime: 0,
    cryptoAssetSymbol: '',
    durationTime: 0,
    durationType: 0,
    id: '',
    schemeName: '',
    status: 0,
  });

  const [dataToken, setDataToken] = useState([
    {
      value: '',
      label: '',
      tokenAddress: '',
    },
  ]);

  const [params, setParams] = useState<params>({
    page: 1,
    size: 10,
  });

  const { actions } = useSchemeSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const reRender = useSelector(selectReRender);

  useEffect(() => {
    dispatch(actions.fetchDataScheme(params));
  }, [params, reRender]);

  useEffect(() => {
    dispatch(actions.getAllToken());
  }, []);

  const token = useSelector(selectToken);

  useEffect(() => {
    setDataToken(
      token?.map(o => ({
        value: o?.symbol,
        label: o?.symbol,
        tokenAddress: o?.tokenAddress,
      })),
    );
  }, [token]);

  const selectData = useSelector(selectScheme);

  const closeHideSchemeDialog = (value: boolean) => {
    setHideSchemeDialog(value);
  };

  const handleNameTextChange = (e: any) => {
    setParams({
      ...params,
      name: e.target.value.trim(),
      page: 1,
    });
  };

  const handleStatusTypeChange = (e: any) => {
    setStatusType(e.target.value);
    setParams({
      ...params,
      status: e.target.value,
      page: 1,
    });
  };

  const handleTokenTextChange = (e: any) => {
    setTokenType(e.target.value);
    setParams({
      ...params,
      token: e.target.value,
      page: 1,
    });
  };

  const changeHideScheme = () => {
    dispatch(actions.changeStatusScheme(idScheme));
  };

  const onViewSchemeClicked = row => {
    setShowDetailDialog(true);
    setDetailScheme(row);
  };

  const onHideSchemeClicked = row => {
    setHideSchemeDialog(true);
    setIdScheme(row.id);
    setStatusRow(row.status);
  };

  const handlePageChange = (e: any, page: number) => {
    setPage(page);
    setParams({
      ...params,
      page: page,
      size: 10,
    });
  };

  return (
    <>
      <Helmet>
        <title>Scheme</title>
        <meta
          name="description"
          content="A Boilerplate application scheme page"
        />
      </Helmet>
      <OneColumnLayout>
        <Paper
          elevation={3}
          sx={{
            padding: '16px',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <Typography variant="h4">Scheme </Typography>
          <Box
            sx={{
              marginTop: '16px',
              justifyContent: 'space-between',
              height: '56px',
            }}
            display={'flex'}
          >
            <Box display={'flex'} columnGap={'20px'}>
              <TextField
                onChange={handleNameTextChange}
                label="Name"
                placeholder="Scheme"
                sx={{ width: '350px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Select
                id="demo-simple-select-helper"
                value={tokenType}
                onChange={handleTokenTextChange}
                displayEmpty
                sx={{
                  width: '114px',
                }}
                renderValue={
                  tokenType === undefined ? () => <Box>Token</Box> : undefined
                }
              >
                <MenuItem value={''}>
                  <em>All</em>
                </MenuItem>
                {token.map((o, i) => (
                  <MenuItem key={i} value={o.symbol}>
                    {o.symbol}
                  </MenuItem>
                ))}
              </Select>

              <Select
                id="demo-simple-select-helper"
                value={statusType}
                displayEmpty
                onChange={handleStatusTypeChange}
                sx={{
                  width: '114px',
                }}
                renderValue={
                  statusType === undefined ? () => <Box>Status</Box> : undefined
                }
              >
                <MenuItem value={''}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={SchemeTypeEnum.ACTIVE}>Active</MenuItem>
                <MenuItem value={SchemeTypeEnum.HIDDEN}>Hidden</MenuItem>
                <MenuItem value={SchemeTypeEnum.PROCESSING}>
                  Processing transaction
                </MenuItem>
              </Select>
            </Box>

            <Button
              variant="contained"
              onClick={() => history.push('/scheme/create')}
              startIcon={<AddIcon />}
            >
              Add new
            </Button>
          </Box>

          <Box
            sx={{ marginTop: '16px', borderBottom: 1, borderColor: 'divider' }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Table Collection">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Scheme</TableCell>
                    <TableCell align="left">Total vesting (all time)</TableCell>
                    <TableCell align="left">Active vesting amount</TableCell>
                    <TableCell align="left">Duration</TableCell>
                    <TableCell align="left">Cliff</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectData?.rows?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.schemeName}</TableCell>
                      <TableCell align="left">
                        {row.totalVestingAmount + ' ' + row.cryptoAssetSymbol}
                      </TableCell>
                      <TableCell align="left">
                        {row.activeVestingAmount + ' ' + row.cryptoAssetSymbol}
                      </TableCell>
                      <TableCell>
                        {row.durationTime}{' '}
                        {row.durationType === DurationType.WEEKS
                          ? 'Week'
                          : row.durationType === DurationType.MONTHS
                          ? 'Month'
                          : 'Quarter'}
                      </TableCell>
                      <TableCell>
                        {row.cliffTime}{' '}
                        {row.durationType === DurationType.WEEKS
                          ? 'Week'
                          : row.durationType === DurationType.MONTHS
                          ? 'Month'
                          : 'Quarter'}
                      </TableCell>
                      <TableCell align="left">
                        {row.status === SchemeTypeEnum.IN_ACTIVE
                          ? 'In active'
                          : row.status === SchemeTypeEnum.ACTIVE
                          ? 'Active'
                          : row.status === SchemeTypeEnum.COMPLETED
                          ? 'Completed'
                          : row.status === SchemeTypeEnum.PROCESSING
                          ? 'Processing'
                          : 'Hidden'}
                      </TableCell>
                      <TableCell align="center">
                        {row.status !== SchemeTypeEnum.PROCESSING && (
                          <IconButton
                            onClick={() => onHideSchemeClicked(row)}
                            aria-label="view"
                            size="small"
                          >
                            {row.status !== SchemeTypeEnum.HIDDEN ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        )}

                        <IconButton
                          onClick={() => onViewSchemeClicked(row)}
                          aria-label="view"
                          size="small"
                        >
                          view
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Pagination
            page={page}
            onChange={handlePageChange}
            sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}
            count={AppUtils.calcTotalPage(10, selectData.total)}
            variant="outlined"
            shape="rounded"
          />
        </Paper>
      </OneColumnLayout>
      <ActiveSchemeDialog
        callback={closeDetailDialog}
        show={showDetailDialog}
        data={detailScheme}
      />

      <HideSchemeDialog
        callback={closeHideSchemeDialog}
        show={hideSchemeDialog}
        hideSchemeCB={changeHideScheme}
        item={statusRow}
      />
    </>
  );
};

export default Scheme;
