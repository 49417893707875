import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { InforToken } from './components/inforToken';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadFile } from './components/uploadFile';
import { Date } from './components/date';
import { Properties } from './components/properties';

import { ReactComponent as Cancell } from './components/assets/x-circle.svg';
import { useAddNewTokenSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { selectGlocalState } from '../GlobalStateWrapper/slice/selectors';
import { selectAddNewToken } from './slice/selectors';
import { DfyAlert } from 'app/components/DfyAlert';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import DfyButtonApprove from './components/buttonApprove';
import { BigNumber } from 'bignumber.js';

import DialogAddANewToken from './components/addANewList';

interface AddNewTokenProps {}

export function AddNewToken(props: AddNewTokenProps) {
  const [properties, setProperties] = React.useState<any>();

  const [statusReset, setStatusReset] = React.useState<boolean>(false);

  const [FileImg, setFileImg] = React.useState<any>();

  const [statusDialog, setStatusDialog] = React.useState<any>(false);

  const [dataSum, setDataSum] = React.useState<any>({
    contract: null,
    reward: null,
  });

  const [type, setType] = React.useState('0');

  const [resetDate, setResetDate] = React.useState(false);

  const [openListANewToken, setOpenListANewToken] = React.useState(false); // mở ra dialog tạo token ko thông qua vote

  const [statusComfirm, setStatusComfirm] = React.useState<boolean>(false);

  const schema = yup.object({
    ticker: yup
      .string()
      .required('Invalid token ticker')
      .max(50, 'Invalid token ticker'),
    tokenName: yup
      .string()
      .required('Invalid token name')
      .max(50, 'Invalid token name'),
    contract: yup
      .string()
      .required('Invalid Token contract')
      .max(100, 'Invalid Token contract'),
    project: yup
      .string()
      .required('Invalid Project name')
      .max(100, 'Invalid Project name'),
    telegram: yup
      .string()
      .required('Invalid Telegram')
      .max(100, 'Invalid Telegram'),
    twitter: yup
      .string()
      .required('Invalid Twitter')
      .max(100, 'Invalid Twitter'),
    website: yup
      .string()
      .required('Invalid Website')
      .max(100, 'Invalid Website'),
    voting: yup
      .string()
      .required('Invalid Target voting')
      .test('voting', 'Max 5 digits after decimal point', (value: any) =>
        /^\d+(\.\d{1,5})?$/.test(value),
      )
      .typeError('Total is required'),
    reward: yup
      .string()
      .required('Invalid Reward pool')
      .test('reward', 'Max 5 digits after decimal point', (value: any) =>
        /^\d+(\.\d{1,5})?$/.test(value),
      )
      .typeError('Total is required'),
    dateForm: yup
      .string()
      .required('Invalid From date')
      .nullable()
      .required('Invalid From date'),
    dateTo: yup
      .string()
      .required('Invalid To date')
      .nullable()
      .required('Invalid To date')
      .when('dateForm', (dateForm, schema) =>
        schema.test(
          'dateTo',
          'The To date must be greater than the From date',
          value => moment(value).valueOf() > moment(dateForm).valueOf(),
        ),
      ),
    upLoadFileImg: yup
      .object()
      .required('This field is required.')
      .shape({
        url: yup.string().required('This field is required.'),
        data: yup.mixed().required('This field is required.'),
      })
      .nullable()
      .required('This field is required.'),
  }); // validation inputfield

  const schema2 = yup.object({
    ticker: yup
      .string()
      .required('Invalid token ticker')
      .max(50, 'Invalid token ticker'),
    tokenName: yup
      .string()
      .required('Invalid token name')
      .max(50, 'Invalid token name'),
    contract: yup
      .string()
      .required('Invalid Token contract')
      .max(100, 'Invalid Token contract'),
    upLoadFileImg: yup
      .object()
      .required('This field is required.')
      .shape({
        url: yup.string().required('This field is required.'),
        data: yup.mixed().required('This field is required.'),
      })
      .nullable()
      .required('This field is required.'),
  }); // validation inputfield

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(type.toString() === '0' ? schema : schema2),
  }); //type

  const handleGetProperties = data => {
    setProperties(data);
  };

  const upLoadFileImg = form.watch('upLoadFileImg');

  const tickerSymbol = form.watch('ticker');

  React.useEffect(() => {
    setFileImg(upLoadFileImg);
  }, [upLoadFileImg]);

  const spender = useSelector(selectGlocalState);

  const Progress = useSelector(selectAddNewToken);

  const Wallet = useSelector(selectWallet);

  const onSubmit = (data: any) => {
    const dataAddNew = {
      ...data,
      properties: properties,
      spender: spender.contracts.Vote,
      type: type,
      wallet: Wallet.wallet,
    };

    if (type === '0') {
      setStatusDialog(true);
    } else {
      setOpenListANewToken(true);
    }
    setDataSum(dataAddNew);
  }; //get data

  const dispatch = useDispatch();

  const history = useHistory();

  const { actions } = useAddNewTokenSlice();

  const handleConfrim = () => {
    dispatch(actions.addNewToken(dataSum));
  };

  React.useEffect(() => {
    if (Progress.Progress !== null) {
      setStatusDialog(false);
    }
  }, [Progress.Progress]);

  //type

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handleReset = () => {
    dispatch(actions.reset());
    form.reset({
      ticker: '',
      tokenName: '',
      contract: '',
      project: '',
      telegram: '',
      twitter: '',
      website: '',
      voting: '',
      reward: '',
      dateForm: '',
      dateTo: '',
      upLoadFileImg: '',
    });

    setStatusReset(!statusReset);

    setResetDate(!resetDate);
  };

  const handleCloseListANewToken = () => {
    setOpenListANewToken(false);
  };

  return (
    <>
      <Helmet>
        <title>Auction Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <OneColumnLayout>
        <Grid container>
          <Grid item xs={12}>
            <Button variant="text" onClick={() => history.push('/token-list')}>
              <ArrowBackIosIcon />
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Box sx={{ margin: '30px 0px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="type"
                  onChange={handleChange}
                >
                  <MenuItem value={0}>Voting for a new token</MenuItem>
                  <MenuItem value={1}>List a new token</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            {/* ticker start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box>
                <Typography>Token Ticker *</Typography>
              </Box>
              <Box>
                <InforToken
                  form={form}
                  name="ticker"
                  style={{ width: { xs: '300px', xl: '400px' } }}
                />
              </Box>
            </Grid>
            {/* ticker end */}

            {/* name start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                <Typography>Token name *</Typography>
              </Box>
              <Box>
                <InforToken
                  form={form}
                  name="tokenName"
                  style={{ width: { xs: '300px', xl: '400px' } }}
                />
              </Box>
            </Grid>
            {/* name end */}

            {/* contract start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box>
                <Typography>Token contract *</Typography>
              </Box>
              <Box>
                <InforToken
                  form={form}
                  name="contract"
                  style={{ width: { xs: '300px', xl: '400px' } }}
                />
              </Box>
            </Grid>
            {/* contract end */}

            {/* uploadfile start */}
            <Grid
              item
              xs={12}
              lg={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              columnGap="10px"
            >
              <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                <Typography component="p">Token logo *</Typography>
                <Typography component="p">(64x64px)</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '300px' }}>
                  <UploadFile form={form} name="upLoadFileImg" />
                </Box>
                {Boolean(FileImg) && (
                  <Box>
                    <img
                      src={Boolean(FileImg) ? FileImg.url : ''}
                      style={{ width: '32px', height: '32px' }}
                      alt=""
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            {/* uploadfile end */}

            {type.toString() === '0' && (
              <>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box>
                    <Typography>From *</Typography>
                  </Box>
                  <Box>
                    <Date
                      form={form}
                      name="dateForm"
                      type="from"
                      reset={resetDate}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                    <Typography>To *</Typography>
                  </Box>
                  <Box>
                    <Date
                      form={form}
                      name="dateTo"
                      type="to"
                      reset={resetDate}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box>
                    <Typography>Project name *</Typography>
                  </Box>
                  <Box>
                    <InforToken
                      form={form}
                      name="project"
                      style={{ width: { xs: '300px', xl: '400px' } }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                    <Typography>Telegram</Typography>
                  </Box>
                  <Box>
                    <InforToken
                      form={form}
                      name="telegram"
                      style={{ width: { xs: '300px', xl: '400px' } }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box>
                    <Typography>Website</Typography>
                  </Box>
                  <Box>
                    <InforToken
                      form={form}
                      name="website"
                      style={{ width: { xs: '300px', xl: '400px' } }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                    <Typography>Twitter</Typography>
                  </Box>
                  <Box>
                    <InforToken
                      form={form}
                      name="twitter"
                      style={{ width: { xs: '300px', xl: '400px' } }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box>
                    <Typography>Target voting (DFY)</Typography>
                  </Box>
                  <Box>
                    <InforToken
                      form={form}
                      name="voting"
                      style={{ width: { xs: '300px', xl: '400px' } }}
                      type="number"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap="10px"
                >
                  <Box sx={{ marginLeft: { xs: '0px', lg: '50px' } }}>
                    <Typography>
                      Reward pool{' '}
                      {Boolean(tickerSymbol) ? `(${tickerSymbol})` : ''}
                    </Typography>
                  </Box>
                  <Box>
                    <InforToken
                      form={form}
                      name="reward"
                      style={{ width: { xs: '300px', xl: '400px' } }}
                      type="number"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box>
                    <Typography>Properties</Typography>
                  </Box>
                  <Box sx={{ margin: '30px 0px' }}>
                    <Properties
                      handleAddMoreList={handleGetProperties}
                      status={statusReset}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </OneColumnLayout>
      {/* statusDialog */}
      <Dialog open={statusDialog}>
        <Box sx={{ padding: '10px 30px 10px 30px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              columnGap: '20px',
            }}
          >
            <Box>
              <Typography>do you want to create this voting</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setStatusDialog(false)}>
                <Cancell style={{ width: '30px', height: '30px' }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography>Token:</Typography>

            <Typography>{dataSum?.ticker}</Typography>
          </Box>

          {type.toString() === '0' && (
            <>
              <Box sx={{ display: 'flex' }}>
                <Typography>From:</Typography>

                <Typography>
                  {moment(
                    dataSum?.dateForm,
                    'ddd MMM DD YYYY HH:mm:ss GMTZZ',
                  ).format('DD/MM/yyyy')}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex' }}>
                <Typography>To:</Typography>

                <Typography>
                  {moment(
                    dataSum?.dateTo,
                    'ddd MMM DD YYYY HH:mm:ss GMTZZ',
                  ).format('DD/MM/yyyy')}
                </Typography>
              </Box>
            </>
          )}
          <Box sx={{ margin: '30px 0px' }}>
            <Box display="flex" justifyContent="space-between">
              {Boolean(spender.contracts) &&
              Boolean(Wallet.wallet) &&
              Boolean(dataSum.reward) &&
              Boolean(dataSum.contract) ? (
                <DfyButtonApprove
                  curAddress={Wallet.wallet}
                  tokenSymbol={dataSum.contract}
                  toAddress={spender.contracts.Vote}
                  amount={new BigNumber(Number(dataSum.reward))
                    .multipliedBy(10 ** 18)
                    .toFixed()}
                  handleAction={e => {
                    setStatusComfirm(e);
                  }}
                />
              ) : (
                ''
              )}

              <Button
                variant="contained"
                onClick={handleConfrim}
                disabled={!statusComfirm}
              >
                confirm
              </Button>
            </Box>
            {!Boolean(Wallet.wallet) && (
              <Box>
                <Typography sx={{ color: 'red', margin: '10px 0px' }}>
                  let's connect the wallet
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
      <Dialog open={Progress.Progress === null ? false : Progress.Progress}>
        <Box sx={{ padding: '40px' }}>
          <CircularProgress />
        </Box>
      </Dialog>
      <DfyAlert
        type={Progress.status ? 'success' : 'danger'}
        alertText={
          <Typography sx={{ color: 'black' }}>
            {Progress.status ? 'Something when wrong' : 'error occurred'}
          </Typography>
        }
        handle={handleReset}
        isOpen={Progress.alertInfor === null ? false : Progress.alertInfor}
        onClose={() => dispatch(actions.alertInforReset())}
      />

      <DialogAddANewToken
        open={openListANewToken}
        handleClose={handleCloseListANewToken}
        data={dataSum}
      />
    </>
  );
}
