import MenuItem from '@mui/material/MenuItem';
import {
  SelectProps,
  MenuItemProps,
  FormControl,
  FormHelperText,
} from '@mui/material';
import Select from '@mui/material/Select';
import { styled, SxProps, Theme } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactNode } from 'hoist-non-react-statics/node_modules/@types/react';
import { Controller, UseFormReturn } from 'react-hook-form';
import * as React from 'react';

interface DfyDropdownProps<T> extends Omit<SelectProps, 'sx'> {
  options: Array<T>;
  menuItemProps?: Omit<MenuItemProps, 'sx'>;
  sx?: SxProps<Theme>;
  menuItemSx?: SxProps<Theme>;
  label?: string;
  form: UseFormReturn;
  name: string;
  defaultValue?: string | undefined | any;
  handleSelectedItem: (option: any) => void;
}

export interface OptionItems {
  value: string | number | undefined;
  label: string | number | ReactNode | undefined;
  disabled?: boolean;
  country_id?: number | undefined;
  latitude?: number | undefined;
  longitude?: number | undefined;
  id?: number | undefined;
  name?: string | undefined;
  code?: string | undefined;
}

export default function DfyDropdown<T extends OptionItems>({
  menuItemProps,
  options,
  sx,
  menuItemSx,
  label,
  MenuProps,
  handleSelectedItem,
  defaultValue,
  ...props
}: DfyDropdownProps<T>) {
  const {
    formState: { errors },
    control,
  } = props.form;

  const hasError = errors[props.name];

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field }) => (
        <FormControl error={!!hasError} sx={{ minWidth: '100%' }}>
          <StyledSelect
            {...field}
            MenuProps={{
              sx: {
                '& .MuiMenu-paper': {
                  borderRadius: '10px',

                  '& ul': {
                    padding: 0,
                  },
                },
              },
              ...MenuProps,
            }}
            IconComponent={KeyboardArrowDownIcon}
            sx={sx}
            {...props}
          >
            {label && (
              <StyledMenuItem
                divider
                sx={{ display: 'none' }}
                {...menuItemProps}
              >
                {label}
              </StyledMenuItem>
            )}

            {options.map((option, index) => {
              return (
                <StyledMenuItem
                  key={option.value || option.id}
                  value={option.value || option.id}
                  divider={index === options.length - 1}
                  sx={menuItemSx}
                  disabled={option.disabled ? true : false}
                  onClick={() => {
                    handleSelectedItem(option);
                  }}
                  {...menuItemProps}
                >
                  {option.label ||
                    option.code + ' ' + option.name ||
                    option.value}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
          <FormHelperText
            sx={{
              marginLeft: '0',
            }}
          >
            {hasError?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

const StyledSelect = styled(Select)(({ theme }) => ({
  height: '44px',

  '& fieldset': {
    borderRadius: '5px',
  },

  '&.Mui-disabled': {
    '& .MuiSelect-select': {},

    '& svg': {},

    '& fieldset': {
      border: 'none',

      zIndex: -1,
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  height: 40,

  fontWeight: 500,
  fontSize: '16px',

  '&.Mui-selected, &:hover': {},
}));

// todo
// 1.Chinh border khi focus, hover, active: mau va kich co border
