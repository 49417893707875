import axios from 'axios';
import config from '../config';
import authService from './authService';

const apiClient = axios.create(config.api);

// Request interceptor
apiClient.interceptors.request.use(
  (config: any) => {
    const accessToken = authService.getAccessToken();

    if (accessToken) {
      config.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common['Accept'] = 'application/json';

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Response interceptor
const interceptor = apiClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    axios.interceptors.response.eject(interceptor);

    if (!originalRequest._retry) {
      authService
        .refreshToken()
        .then(fulfilled => {
          const access_token = fulfilled.data.access_token;
          const refresh_token = fulfilled.data.refresh_token;
          authService.setAccessToken(access_token);
          authService.setRefreshToken(refresh_token);
          apiClient.defaults.headers.common['Authorization'] =
            'Bearer ' + access_token;
          return apiClient;
        })
        .catch((err: any) => {
          authService.removeAccessToken();
          authService.removeRefreshToken();
          window.location.href = '/login';

          return Promise.reject(err);
        });
    }

    return Promise.reject(error);
  },
);

export default apiClient;
