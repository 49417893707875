/**
 *
 * ImportCollection
 *
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Chain } from '../../slice/types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Wrapper } from './styles';
import { useImportCollectionSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCheckImportCollection,
  selectImportCollectionError,
  selectImportNewCollection,
} from './slice/selectors';

interface ImportCollectionProps {
  isOpen: boolean;
  handleClose: Function;
}

export function ImportCollection(props: ImportCollectionProps) {
  const [chain, setChain] = React.useState<Chain>(Chain.BinanceSmartChain);
  const [checkCollection, setCheckCollection] = React.useState<boolean>(false);

  const checkCollectionSelect = useSelector(selectCheckImportCollection);

  const importNewCollectionSelect = useSelector(selectImportNewCollection);

  const importCollectionError = useSelector(selectImportCollectionError);

  const handleChange = (event: SelectChangeEvent) => {
    setChain(Number(event.target.value));
  };

  const arrChain = [
    { value: Chain.BinanceSmartChain, label: 'Binance Smart Chain' },
  ];

  const schema = yup.object().shape({
    address: yup
      .string()
      .required('Collection address is required')
      .matches(/^(0x)[0-9]*[A-F]*[a-f]*/, 'Invalid wallet address')
      .min(42, 'Invalid wallet address')
      .max(42, 'Invalid wallet address'),
  });

  const form = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { control } = form;

  const {
    formState: { errors },
  } = form;

  const hasError = errors.address;

  const onSubmit = data => {
    dispatch(actions.importCollectionRequest(data.address));
  };

  const handleCloseImportant = () => {
    // FIXME: sau này có thêm chain sẽ ko auto fill Binance Smart Chain cái này nữa
    // setChain(0);
    form.reset();
    form.setValue('address', '');
    props.handleClose();
    dispatch(actions.clearImportCollectionFailure());
  };

  const dispatch = useDispatch();
  const { actions } = useImportCollectionSlice();

  //api check collection address

  React.useEffect(() => {
    if (!Boolean(hasError) && form.getValues('address')) {
      dispatch(actions.checkCollectionRequest(form.getValues('address')));
      setCheckCollection(true);
    } else {
      setCheckCollection(false);
    }
  }, [hasError, form.getValues('address'), form.watch()]);

  React.useEffect(() => {
    if (!importNewCollectionSelect) {
      props.handleClose();
      form.reset();
      form.setValue('address', '');
    }
  }, [importNewCollectionSelect]);

  const handleEnableImport = (checkCollection, checkCollectionSelect) => {
    if (!checkCollection) {
      return true;
    } else {
      switch (Boolean(checkCollectionSelect)) {
        case true:
          return true;

        default:
          return false;
      }
    }
  };

  return (
    <>
      <Dialog open={props.isOpen}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Import Collection</Typography>
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleCloseImportant()}
            />
          </DialogTitle>

          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '20px !important',
              gap: '20px',
            }}
          >
            <FormControl fullWidth sx={{ margin: 'auto', minWidth: '200px' }}>
              <InputLabel id="demo-simple-select-label">Chain</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={String(chain)}
                label="Chain"
                onChange={handleChange}
              >
                {arrChain.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Wrapper>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={Boolean(hasError)}
                    helperText={hasError?.message}
                    sx={{ margin: 'auto', minWidth: '300px' }}
                    autoFocus
                    label="Enter address"
                    type="address"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Wrapper>
          </DialogContent>
          {checkCollection && (
            <Typography sx={{ ml: '10px' }}>
              {checkCollectionSelect
                ? `This collection is already imported`
                : `Please check the collection address carefully before importing`}
            </Typography>
          )}
          {importCollectionError && (
            <Typography sx={{ ml: '10px', color: 'red' }}>
              {importCollectionError}
            </Typography>
          )}
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              disabled={handleEnableImport(
                checkCollection,
                checkCollectionSelect,
              )}
              type="submit"
            >
              Import
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
