import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { glocalStateSaga } from './saga';
import { GlocalStateState } from './types';

export const initialState: GlocalStateState = {
  contracts: null,
  StoreCryptoCurrency: [{}],
};

const slice = createSlice({
  name: 'glocalState',
  initialState,
  reducers: {
    someAction(state) {},
    someThingTrue(state, action: PayloadAction<any>) {
      state.contracts = action.payload;
    },

    getStoreCryptoCurrencyRequest(s) {},

    getStoreCryptoCurrencySuccess(s, a) {
      s.StoreCryptoCurrency = a.payload;
      const pareJSON = JSON.stringify(s.StoreCryptoCurrency);
      localStorage.setItem('StoreCryptoCurrency', pareJSON);
    },
  },
});

export const { actions: glocalStateActions } = slice;

export const useGlocalStateSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: glocalStateSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGlocalStateSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
