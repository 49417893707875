import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  CircularProgress,
  Grid,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, typography } from '@mui/system';
import { create, all } from 'mathjs';
import { useDispatch, useSelector } from 'react-redux';
import { useSchemeAddSlice } from './slice';
import { selectWallet } from 'app/components/Wallet/slice/selectors';
import { DfyAlert } from 'app/components/DfyAlert';
import { selectSchemeAdd } from './slice/selectors';
import { number } from 'yup/lib/locale';
import { selectGlocalState } from 'app/pages/GlobalStateWrapper/slice/selectors';
import CloseIcon from '@mui/icons-material/Close';
import { ShortenWalletAddress } from 'utils/formatWalletAddress';
interface Props {
  show: boolean;
  item?: any;
  callback: (value: boolean) => void;
  data?: any;
}

export default function PopupScheme({ show, callback, data }: Props) {
  const { contracts } = useSelector(selectGlocalState);

  const handleClose = () => {
    callback(false);
  };

  const [statusDialog, setStatusDialog] = React.useState<any>(false);

  const dispatch = useDispatch();

  const { actions } = useSchemeAddSlice();

  const Wallet = useSelector(selectWallet);

  const Progress = useSelector(selectSchemeAdd);

  const slideName = (value: string) => {
    return value?.length > 25 ? value.slice(0, 25) + '...' : value;
  };

  const typeDropDuration =
    data?.dropDuration === 'Week'
      ? 7
      : data?.dropDuration === 'Month'
      ? 30
      : 90;

  const dataSubmit = {
    name: data?.name,
    vestTime: data?.duration,
    cliffTime: data?.cliff,
    durationTime: data?.duration,
    periodTime: typeDropDuration,
    tokenAddress: data?.tokenAddress?.tokenAddress,
    wallet: Wallet.wallet,
    cryptoAssetSymbol: data?.token,
    durationType: data?.dropDuration,
    spender: contracts?.Vesting,
  };

  React.useEffect(() => {
    if (Progress.Progress !== null) {
      setStatusDialog(false);
    }
  }, [Progress.Progress]);

  const createScheme = () => {
    dispatch(actions.addNewScheme(dataSubmit));
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Create scheme'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid>
            <TableRow>
              <TableCell>Scheme Name:</TableCell>
              <TableCell>{slideName(data?.name)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Token vesting:</TableCell>
              <TableCell>
                {data?.token}{' '}
                <img
                  src={
                    require(`app/assets/${
                      data?.token ? data?.token : 'DFY'
                    }.png`).default
                  }
                  alt=""
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '4px',
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vesting duration:</TableCell>
              <TableCell>{data?.duration + ' ' + data?.dropDuration}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cliff:</TableCell>
              <TableCell>{data?.cliff + ' ' + data?.dropDuration}</TableCell>
            </TableRow>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'center !important',
        }}
      >
        <Button
          variant="contained"
          onClick={() => createScheme()}
          disabled={!Boolean(Wallet.wallet)}
        >
          Create scheme
        </Button>
      </DialogActions>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Dialog open={Progress.Progress === null ? false : Progress.Progress}>
        <Box sx={{ padding: '40px' }}>
          <CircularProgress />
        </Box>
      </Dialog>

      <DfyAlert
        type={Progress.status ? 'success' : 'danger'}
        alertText={
          <>
            {Progress.status ? (
              <>
                <Typography sx={{ color: 'black', marginBottom: '10px' }}>
                  Your Scheme have been transferred successfully
                </Typography>
                <Typography sx={{ color: 'black', marginBottom: '10px' }}>
                  TXN hash:
                  <Link
                    target="_blank"
                    href={`${process.env.REACT_APP_BLOCK_EXPLORER_URL}/tx/${Progress.dataBC.txnHash}`}
                    style={{
                      textDecoration: 'underline',
                      display: 'inline-block',
                      color: 'blue',
                    }}
                  >
                    {' '}
                    <ShortenWalletAddress
                      children={Progress.dataBC.txnHash}
                      numShort={8}
                    />
                  </Link>
                </Typography>
              </>
            ) : (
              <Typography sx={{ color: 'black' }}>
                Something went wrong!
              </Typography>
            )}
          </>
        }
        handle={() => {}}
        isOpen={Progress.alertInfor === null ? false : Progress.alertInfor}
        onClose={() => {
          if (Progress.status) {
            dispatch(actions.alertInforReset());
          } else {
            dispatch(actions.addNewSchemeReset());
            handleClose();
          }
        }}
      />
    </Dialog>
  );
}
