import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useSlice } from '../../slice';
import { selectVesting } from '../../slice/selectors';
import { Box } from '@mui/system';
import { renderStatus, renderTime } from '../VestingListTable';
import { formatNumberText, FunctionFormatNum } from 'utils/formatNumber';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs() {
  const dispatch = useDispatch();
  const { actions } = useSlice();
  const { isLoadingDialog, detailDataVesting } = useSelector(selectVesting);

  return (
    <div>
      <BootstrapDialog
        onClose={() => {
          dispatch(actions.closeDialog(false));
        }}
        aria-labelledby="customized-dialog-title"
        open={isLoadingDialog}
        sx={{
          '.MuiDialog-paper': {
            maxWidth: '800px !important',
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            dispatch(actions.closeDialog(false));
          }}
        >
          Vesting detail
        </BootstrapDialogTitle>
        <Box
          sx={{
            display: 'flex',
            width: '800px',
            justifyContent: 'center',
            gap: '30px',
          }}
        >
          <Box>
            <Typography>Wallet address:</Typography>
            <Typography>Scheme:</Typography>
            <Typography>Total amount:</Typography>
            <Typography>Current vesting:</Typography>
            <Typography>Claim amount:</Typography>
            <Typography>Time:</Typography>
            <Typography>Status:</Typography>
          </Box>
          <Box>
            <Typography>{detailDataVesting.walletAddress}</Typography>
            <Typography>{detailDataVesting.scheme.schemeName}</Typography>
            <Typography>
              <img
                src={
                  require(`app/assets/${
                    detailDataVesting.scheme.cryptoAssetSymbol
                      ? detailDataVesting.scheme.cryptoAssetSymbol
                      : 'DFY'
                  }.png`).default
                }
                alt=""
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '4px',
                }}
              />{' '}
              <FunctionFormatNum>
                {formatNumberText(detailDataVesting.totalAmountVesting)}
              </FunctionFormatNum>{' '}
              {detailDataVesting.scheme.cryptoAssetSymbol}
            </Typography>
            <Typography>
              <img
                src={
                  require(`app/assets/${
                    detailDataVesting.scheme.cryptoAssetSymbol
                      ? detailDataVesting.scheme.cryptoAssetSymbol
                      : 'DFY'
                  }.png`).default
                }
                alt=""
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '4px',
                }}
              />{' '}
              <FunctionFormatNum>
                {formatNumberText(detailDataVesting.currentAmountVesting)}
              </FunctionFormatNum>{' '}
              {detailDataVesting.scheme.cryptoAssetSymbol}
            </Typography>
            <Typography>
              <img
                src={
                  require(`app/assets/${
                    detailDataVesting.scheme.cryptoAssetSymbol
                      ? detailDataVesting.scheme.cryptoAssetSymbol
                      : 'DFY'
                  }.png`).default
                }
                alt=""
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '4px',
                }}
              />{' '}
              <FunctionFormatNum>
                {formatNumberText(detailDataVesting.claimedTotalAmount)}
              </FunctionFormatNum>{' '}
              {detailDataVesting.scheme.cryptoAssetSymbol}
            </Typography>
            <Typography>
              {renderTime(
                detailDataVesting.status,
                detailDataVesting.scheme.durationType,
                detailDataVesting.scheme.durationTime,
                detailDataVesting.startedTime,
                detailDataVesting.endedTime,
              )
                ? renderTime(
                    detailDataVesting.status,
                    detailDataVesting.scheme.durationType,
                    detailDataVesting.scheme.durationTime,
                    detailDataVesting.startedTime,
                    detailDataVesting.endedTime,
                  )
                : 'null'}
            </Typography>
            <Typography>{renderStatus(detailDataVesting.status)}</Typography>
          </Box>
        </Box>
        <DialogActions
          sx={{ justifyContent: 'center', margin: '12px 0 12px 0' }}
        >
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              dispatch(actions.closeDialog(false));
            }}
          >
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
