import apiClient from './apiService';

const vestingServices = {
  getToken() {
    return apiClient.request({
      method: 'GET',
      url: '/defi-pawn-crypto-service/public-api/v1.0.0/crypto-asset/vesting',
    });
  },

  getDataListVesting(page, size, status, symbolToken, walletAddress) {
    return apiClient.request({
      method: 'GET',
      url: '/vesting-svc/api/v1.0.0/vesting',
      params: { page, size, status, symbolToken, walletAddress },
    });
  },

  postDataVessting(
    walletAddress,
    schemeId,
    totalAmount,
    vestedAmount,
    isHasToken,
    txnHash,
    name,
    startedTime,
    tokenReleaseType,
  ) {
    return apiClient.request({
      method: 'POST',
      url: '/vesting-svc/api/v1.0.0/vesting',
      data: {
        walletAddress,
        schemeId,
        totalAmount,
        vestedAmount,
        isHasToken,
        txnHash,
        name,
        startedTime,
        tokenReleaseType,
      },
    });
  },

  getDetailVesting(id) {
    return apiClient.request({
      method: 'GET',
      url: `/vesting-svc/api/v1.0.0/vesting/${id}`,
    });
  },

  getDataSchemeCreate(wallet_address, s = 9999, p = 0, role_type = 4) {
    return apiClient.request({
      method: 'GET',
      url: `/vesting-svc/api/v1.0.0/vesting/schemes`,
    });
  },

  putIdActive(id) {
    return apiClient.request({
      method: 'PATCH',
      url: `/vesting-svc/api/v1.0.0/vesting/active/${id}`,
    });
  },
};

export default vestingServices;
