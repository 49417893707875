import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, MenuItem, Select, Snackbar } from '@mui/material';
import * as auctionBlockchain from '../../../../../services/walletService/auctionService';
import { signAndSendTx } from '../../../../../services/walletService/supportService/signAndSendTx';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectWallet } from '../../../../components/Wallet/slice/selectors';
import { useAuctionSlice } from '../../slice';
import { selectWaitingAuctions } from '../../slice/selectors';
import { NotifyInfo } from '../../../../../types/model';
import apiClient from '../../../../../services/apiService';

interface Props {
  show: boolean;
  item: any;
  callback: (value: boolean) => void;
}

export default function RejectDialog({ show, item, callback }: Props) {
  const { actions } = useAuctionSlice();
  const dispatch = useDispatch();
  const walletState = useSelector(selectWallet);
  const waitingAuction = useSelector(selectWaitingAuctions);
  const [nft, setNft] = useState<NotifyInfo>({
    show: false,
    content: '',
    severity: 'success',
  });

  const notify = (content: string, success: boolean) => {
    let n: NotifyInfo = {
      ...nft,
      show: true,
      content: content,
      severity: success ? 'success' : 'error',
    };
    setNft(n);
  };

  const handleClose = () => {
    callback(false);
  };

  const handleCloseNotify = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    let n: NotifyInfo = { ...nft, show: false };
    setNft(n);
  };

  const onRejectAuctionClicked = async () => {
    console.log('item: ' + JSON.stringify(item));

    if (isEmpty(walletState.wallet)) {
      console.log('onRejectAuctionClicked wallet: ' + walletState.wallet);
      notify('Please connect wallet before reject auction', false);
      return;
    }

    let auctionTx;
    try {
      auctionTx = await auctionBlockchain.approveAuction(
        walletState.wallet,
        item.auction_id,
        2,
      );
    } catch (e) {
      console.log(e);
      console.log('mLog: ' + e);
    }

    console.log('auctionTx: ' + auctionTx);
    if (auctionTx === undefined || auctionTx === null) {
      notify('Reject auction failed, please try again', false);
      return;
    }

    if (auctionTx.hasError) {
      let s = auctionTx.msgError;
      if (s === undefined) {
        s = 'Call reject in blockchain failure';
      }
      notify(s, false);
      return;
    }

    dispatch(actions.setWaitingLoading(true));
    try {
      const receipt = await signAndSendTx(auctionTx);
      console.log('receipt: ' + JSON.stringify(receipt));
      if (receipt) {
        let status = receipt.status;
        if (status) {
          notify(
            'Reject auction in processing, please wait a moment and refresh page',
            true,
          );
          handleClose();
          let params = {
            txn_hash: receipt.transactionHash,
            from: receipt.from,
            to: receipt.to,
            id: item.id,
            note: note,
            reason: reason,
          };
          sendApproveToBE(params);
          return;
        } else {
          notify('Reject auction failed, please try again', false);
        }
      } else {
        notify('Reject auction failed, please try again', false);
      }
      dispatch(actions.setWaitingLoading(false));
    } catch (e: any) {
      console.log('e: ' + e.code + ', ' + e.message);
      let msg = 'Reject auction error';
      let dt = e.data;
      if (dt !== undefined) {
        console.log('data: ' + JSON.stringify(dt));
      }
      if (e.message) {
        msg += ': ' + e.message;
      }
      notify(msg, false);
      dispatch(actions.setWaitingLoading(false));
    }
  };

  const sendApproveToBE = (params: any) => {
    apiClient
      .request({
        method: 'POST',
        data: params,
        url: '/api-svc/api/auction/reject-auction',
      })
      .then(data => {})
      .catch(e => {})
      .finally(() => {
        dispatch(actions.fetchWaitingAuction(waitingAuction.params));
      });
  };

  const [reason, setReason] = useState<string>('copy_plagiarism');
  const [note, setNote] = useState<string>('');

  const onTextFieldChange = (e: any) => {
    setNote(e.target.value);
  };

  const onSelectedChange = (e: any) => {
    setReason(e.target.value);
  };

  return (
    <>
      <Dialog maxWidth="sm" open={show} onClose={handleClose}>
        <DialogTitle>Reject Auction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The auction session will be rejected.
          </DialogContentText>

          <Select
            autoFocus
            value={reason}
            label="Reason"
            inputProps={{
              name: 'max-width',
              id: 'max-width',
            }}
            onChange={onSelectedChange}
          >
            <MenuItem value="copy_plagiarism">Copyright plagiarism</MenuItem>
            <MenuItem value="spam">Spam</MenuItem>
            <MenuItem value="violence">Violence, erotic, terrorist</MenuItem>
            <MenuItem value="child_abuse">Child abuse</MenuItem>
            <MenuItem value="religion_offense">Religion offense</MenuItem>
            <MenuItem value="other_reason">Other reason</MenuItem>
          </Select>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Note"
            type="text"
            fullWidth
            variant="standard"
            value={note}
            onChange={onTextFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={onRejectAuctionClicked}>Reject Auction</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={nft.show}
        autoHideDuration={6000}
        onClose={handleCloseNotify}
      >
        <Alert
          onClose={handleCloseNotify}
          severity={nft.severity}
          sx={{ width: '100%' }}
        >
          {nft.content}
        </Alert>
      </Snackbar>
    </>
  );
}
