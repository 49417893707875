import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initState } from '.';

const selectSlice = (state: RootState) => state?.pawnShops || initState;

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectPawnShops = createSelector(
  [selectSlice],
  state => state.pawnShops,
);

export const selectPawnShopParams = createSelector(
  [selectSlice],
  state => state.params,
);

export const selectSize = createSelector(
  [selectSlice],
  state => state.params?.size || 50,
);

export const selectTotal = createSelector([selectSlice], state => state.total);

export const selectPawnShop = createSelector([selectSlice], state => state);
