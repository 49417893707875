import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCollectionParams,
  selectCollections,
  selectIsLoading,
  selectSize,
  selectTotal,
} from './slice/selectors';
import { useCollectionSlice } from './slice';
import { CollectionGetParams } from './slice/types';
import DateTimeHelper from 'utils/date_time_helper';
import AppUtils from 'utils/app_utils';
import { ImportCollection } from './components/ImportCollection';
import apiClient from 'services/apiService';

interface Props {}

export function CollectionPage(props: Props) {
  const isLoading = useSelector(selectIsLoading);
  const collections = useSelector(selectCollections);
  const params: CollectionGetParams = useSelector(
    selectCollectionParams,
  ) as CollectionGetParams;
  const size = useSelector(selectSize);
  const total = useSelector(selectTotal);

  const [page, setPage] = useState<number>(1);
  const [collectionType, setCollectionType] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [owner, setOwner] = useState<string>('');

  const { actions } = useCollectionSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchCollection(params));
  }, []);

  //import collection
  const [openImportCollection, setOpenImportCollection] = React.useState(false);

  const handleImportCollection = () => {
    setOpenImportCollection(true);
  };

  const handleClose = () => {
    setOpenImportCollection(false);
  };

  const onSearchClicked = () => {
    console.log(
      'name: ' +
        name +
        ', owner: ' +
        owner +
        ', collectionType: ' +
        collectionType,
    );
    let p = JSON.parse(JSON.stringify(params));
    setPage(1);
    p.name = name;
    p.collection_type = collectionType + '';
    p.wallet_address = owner;
    p.page = 1;
    dispatch(actions.fetchCollection(p));
  };

  const handlePageChange = (e: any, page: number) => {
    setPage(page);
    let p = JSON.parse(JSON.stringify(params));
    p.page = page;
    dispatch(actions.fetchCollection(p));
  };

  const handleNameTextChange = (e: any) => {
    setName(e.target.value);
  };

  const handleOwnerTextChange = (e: any) => {
    setOwner(e.target.value);
  };

  const handleCollectionTypeChange = (e: any) => {
    setCollectionType(e.target.value);
  };

  // hard code

  const hanldeEditCollection = collection_address => {
    window.location.replace(
      `http://localhost:3000/collections/${collection_address}?edit=true`,
    );
  };

  const convertWhiteList = async (id, condition) => {
    await apiClient.request({
      method: 'PUT',
      url: '/inventory-svc/api/v1.0.0/collections/is-default',
      params: {
        idCollection: id,
        isDefault: condition,
      },
    });
    let p = JSON.parse(JSON.stringify(params));
    p.page = 0;
    dispatch(actions.fetchCollection(p));
  };

  return (
    <>
      <Helmet>
        <title>Collection Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <OneColumnLayout>
        <Paper
          elevation={3}
          sx={{
            padding: '16px',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <Typography variant="h4">Collections </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ marginTop: '16px' }} display={'flex'} columnGap={'20px'}>
              <TextField
                onChange={handleNameTextChange}
                label="Name"
                value={name}
                inputProps={{ inputMode: 'text' }}
              />

              <Select
                labelId="demo-simple-select-label"
                label="CollectionType"
                id="demo-simple-select-helper"
                value={collectionType}
                onChange={handleCollectionTypeChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0}>Soft</MenuItem>
                <MenuItem value={1}>Hard</MenuItem>
              </Select>

              <TextField
                onChange={handleOwnerTextChange}
                label="Owener Account"
                value={owner}
                inputProps={{ inputMode: 'text' }}
              />

              <Button variant="contained" onClick={() => onSearchClicked()}>
                SEARCH
              </Button>
            </Box>
            <Button
              variant="contained"
              onClick={() => handleImportCollection()}
              sx={{
                mt: '16px',
              }}
            >
              Import Collection
            </Button>
          </Box>

          <Box
            sx={{ marginTop: '16px', borderBottom: 1, borderColor: 'divider' }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Table Collection">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Owner</TableCell>
                    <TableCell align="left">Total NFT</TableCell>
                    <TableCell align="left">isWhiteList</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collections &&
                    collections.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          cursor: 'pointer',
                        }}
                        // onClick={() => {
                        //   hanldeEditCollection(row.collection_address);
                        // }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.collection_name}</TableCell>
                        <TableCell>{row.wallet_address}</TableCell>
                        <TableCell>{row.number_of_item}</TableCell>
                        <TableCell>
                          {row.is_default ? (
                            <DoneIcon fontSize="inherit" />
                          ) : (
                            ''
                          )}
                        </TableCell>

                        <TableCell>
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() =>
                              convertWhiteList(
                                row.collection_id,
                                !row.is_default,
                              )
                            }
                          >
                            {row.is_default ? (
                              <CloseIcon fontSize="inherit" />
                            ) : (
                              <DoneIcon fontSize="inherit" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Pagination
            page={page}
            onChange={handlePageChange}
            sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}
            count={AppUtils.calcTotalPage(size, total)}
            variant="outlined"
            shape="rounded"
          />
        </Paper>
      </OneColumnLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ImportCollection
        isOpen={openImportCollection}
        handleClose={handleClose}
      />
    </>
  );
}
