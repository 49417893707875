import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { OneColumnLayout } from 'app/components/Layout/OneColumnLayout';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPawnShopParams,
  selectPawnShops,
  selectIsLoading,
  selectSize,
  selectTotal,
} from './slice/selectors';
import { usePawnShopSlice } from './slice';
import { PawnShopGetParams } from './slice/types';
import DateTimeHelper from 'utils/date_time_helper';
import AppUtils from 'utils/app_utils';
import apiClient from 'services/apiService';

interface Props {}

export function PawnShopPage(props: Props) {
  const isLoading = useSelector(selectIsLoading);
  const pawnShops = useSelector(selectPawnShops);
  const params: PawnShopGetParams = useSelector(
    selectPawnShopParams,
  ) as PawnShopGetParams;
  const size = useSelector(selectSize);
  const total = useSelector(selectTotal);

  const [page, setPage] = useState<number>(1);
  const [pawnShopType, setPawnShopType] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [owner, setOwner] = useState<string>('');

  const { actions } = usePawnShopSlice();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchPawnShop(params));
  }, []);

  const onSearchClicked = () => {
    console.log(
      'name: ' + name + ', owner: ' + owner + ', pawnShopType: ' + pawnShopType,
    );
    let p = JSON.parse(JSON.stringify(params));
    setPage(0);
    p.name = name;
    p.pawnShop_type = pawnShopType + '';
    p.owner_account = owner;
    p.page = 0;
    dispatch(actions.fetchPawnShop(p));
  };

  const handlePageChange = (e: any, page: number) => {
    setPage(page);
    let p = JSON.parse(JSON.stringify(params));
    p.page = page;
    dispatch(actions.fetchPawnShop(p));
  };

  const handleNameTextChange = (e: any) => {
    setName(e.target.value);
  };

  const handleOwnerTextChange = (e: any) => {
    setOwner(e.target.value);
  };

  const handleCollectionTypeChange = (e: any) => {
    setPawnShopType(e.target.value);
  };

  // hard code

  const hanldeEditCollection = collection_address => {
    window.location.replace(
      `http://localhost:3000/collections/${collection_address}?edit=true`,
    );
  };

  const convertWhiteList = async (id, condition) => {
    await apiClient.request({
      method: 'PUT',
      url: '/defi-pawn-crypto-service/api/v1.0.0/pawn-shop/trusted-partner',
      params: {
        idPackage: id,
        isTrusted: condition,
      },
    });
    let p = JSON.parse(JSON.stringify(params));
    p.page = 0;
    dispatch(actions.fetchPawnShop(p));
  };

  return (
    <>
      <Helmet>
        <title>PawnShop Management</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <OneColumnLayout>
        <Paper
          elevation={3}
          sx={{
            padding: '16px',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <Typography variant="h4">PawnShops </Typography>

          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ marginTop: '16px' }} display={'flex'} columnGap={'20px'}>
              <TextField
                onChange={handleNameTextChange}
                label="Name"
                value={name}
                inputProps={{ inputMode: 'text' }}
              />

              <Select
                labelId="demo-simple-select-label"
                label="CollectionType"
                id="demo-simple-select-helper"
                value={collectionType}
                onChange={handleCollectionTypeChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={0}>Soft</MenuItem>
                <MenuItem value={1}>Hard</MenuItem>
              </Select>

              <TextField
                onChange={handleOwnerTextChange}
                label="Owener Account"
                value={owner}
                inputProps={{ inputMode: 'text' }}
              />

              <Button variant="contained" onClick={() => onSearchClicked()}>
                SEARCH
              </Button>
            </Box>
          </Box> */}

          <Box
            sx={{ marginTop: '16px', borderBottom: 1, borderColor: 'divider' }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Table Collection">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">isTrustedLender</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pawnShops &&
                    pawnShops.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          cursor: 'pointer',
                        }}
                        // onClick={() => {
                        //   hanldeEditCollection(row.collection_address);
                        // }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {row.isTrustedLender ? (
                            <DoneIcon fontSize="inherit" />
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() =>
                              convertWhiteList(row.id, !row.isTrustedLender)
                            }
                          >
                            {row.isTrustedLender ? (
                              <CloseIcon fontSize="inherit" />
                            ) : (
                              <DoneIcon fontSize="inherit" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Pagination
            page={page}
            onChange={handlePageChange}
            sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}
            count={AppUtils.calcTotalPage(size, total)}
            variant="outlined"
            shape="rounded"
          />
        </Paper>
      </OneColumnLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
