import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as Cancell } from '../assets/x-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { DfyAlert } from 'app/components/DfyAlert';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { selectWallet } from 'app/components/Wallet/slice/selectors';
import { useAddNewTokenSlice } from '../../slice';
import { selectAddNewToken } from '../../slice/selectors';

import { useForm } from 'react-hook-form';

interface PropsDialogAddANewToken {
  open: boolean;
  handleClose: Function;
  data?: any;
}

const DialogAddANewToken = (props: PropsDialogAddANewToken) => {
  const dispatch = useDispatch();

  const { actions } = useAddNewTokenSlice();

  const { open, handleClose, data } = props;

  const [priceFrom, setPriceFrom] = React.useState('0');

  const [priceFeedAddress, setPriceFeedAddress] = React.useState<string>('');

  const [oracleID, setOracleID] = React.useState<string>('');

  const [Collateral_Loan_Asset, setCollateral_Loan_Asset] = React.useState([]);

  const [PutNewTokenAs, setPutNewTokenAs] = React.useState([]);

  const [statusPutNewTokenAs, setStatusPutNewTokenAs] =
    React.useState<boolean>(false);

  const Wallet = useSelector(selectWallet);

  const handleChangePriceFrom = event => {
    setPriceFrom(event.target.value.toString());
  };

  const handleChangeCollateral_Loan_Asset = event => {
    if (event.target.checked) {
      const arr: any = [...Collateral_Loan_Asset];
      arr.push(event.target.name);
      setCollateral_Loan_Asset(arr);
    } else {
      const arr: any = [...Collateral_Loan_Asset].filter(
        e => e !== event.target.name,
      );
      setCollateral_Loan_Asset(arr);
    }
  };

  const handlePutNewTokenAs = event => {
    if (event.target.checked) {
      const arr: any = [...PutNewTokenAs];
      arr.push(event.target.name);
      setPutNewTokenAs(arr);
    } else {
      const arr: any = [...PutNewTokenAs].filter(e => e !== event.target.name);
      setPutNewTokenAs(arr);
    }
  };

  const handleClosePopup = () => {
    setPutNewTokenAs([]);
    setStatusPutNewTokenAs(false);
  };

  const onClick = () => {
    const value = {
      data: data,
      whitelist_type: Collateral_Loan_Asset,
      price_from: priceFrom,
      oracle_id: oracleID,
      price_feed_address: priceFeedAddress,
      PutNewTokenAs: PutNewTokenAs,
    };
    console.log('value', value);
    dispatch(actions.addNewTokenList(value));
    handleClosePopup();
    handleClose();
  };

  const selector = useSelector(selectAddNewToken);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleContinue = data => {
    setOracleID(data.ID);
    setPriceFeedAddress(data.address);
    setStatusPutNewTokenAs(true);
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={handleSubmit(handleContinue)}>
          <Box
            sx={{
              padding: '20px',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                Choose type of token you want to put on the Defi website?
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <Cancell style={{ width: '30px', height: '30px' }} />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeCollateral_Loan_Asset}
                      name="0"
                    />
                  }
                  label="Collateral"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeCollateral_Loan_Asset}
                      name="1"
                    />
                  }
                  label="Loan"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeCollateral_Loan_Asset}
                      name="2"
                    />
                  }
                  label="Asset"
                />
              </FormGroup>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>Price from</Box>
              <Box sx={{ width: '300px' }}>
                <FormControl fullWidth>
                  <Select value={priceFrom} onChange={handleChangePriceFrom}>
                    <MenuItem value={0}>Chanlink</MenuItem>
                    <MenuItem value={1}>Pancake</MenuItem>
                    <MenuItem value={2}>Our system (oracle)</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ margin: '20px 0px' }}
            >
              <Box>Price feed address</Box>
              <Box>
                <TextField
                  error={Boolean(errors.address)}
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  sx={{ width: '300px' }}
                  helperText={Boolean(errors.address) && 'Invalid address'}
                  {...register('address', { required: true })}
                />
              </Box>
            </Box>
            {priceFrom === '2' && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>Oracle ID</Box>
                <Box>
                  <TextField
                    error={Boolean(errors.ID)}
                    id="outlined-basic"
                    variant="outlined"
                    autoComplete="off"
                    sx={{ width: '300px' }}
                    {...register('ID', { required: true })}
                    helperText={Boolean(errors.ID) && 'Invalid ID'}
                  />
                </Box>
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="center"
              sx={{ margin: '20px ' }}
            >
              <Button
                variant="contained"
                disabled={
                  Boolean(Wallet.wallet)
                    ? !Boolean(Collateral_Loan_Asset.length > 0)
                    : true
                }
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </Box>
        </form>
      </Dialog>
      <Dialog
        open={selector.ProgressList === null ? false : selector.ProgressList}
      >
        <Box sx={{ padding: '40px' }}>
          <CircularProgress />
        </Box>
      </Dialog>
      <DfyAlert
        type={selector.statusList ? 'success' : 'danger'}
        alertText={
          <Typography sx={{ color: 'black' }}>
            {selector.statusList ? 'Something when wrong' : 'error occurred'}
          </Typography>
        }
        handle={() => {}}
        isOpen={
          selector.alertInforList === null ? false : selector.alertInforList
        }
        onClose={() => dispatch(actions.reset())}
      />
      <Dialog open={statusPutNewTokenAs}>
        <Box sx={{ padding: '20px' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <IconButton onClick={handleClosePopup}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
            <Box sx={{ padding: '0px 10px' }}>Put new token as</Box>
            <Box>
              <IconButton onClick={handleClosePopup}>
                <Cancell style={{ width: '30px', height: '30px' }} />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handlePutNewTokenAs} name="0" />}
                label="Collateral"
              />
              <FormControlLabel
                control={<Checkbox onChange={handlePutNewTokenAs} name="1" />}
                label="Loan"
              />
              <FormControlLabel
                control={<Checkbox onChange={handlePutNewTokenAs} name="2" />}
                label="Asset"
              />
            </FormGroup>
          </Box>
          <Box>
            <Button
              variant="contained"
              disabled={!Boolean(PutNewTokenAs.length > 0)}
              onClick={onClick}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DialogAddANewToken;
