import { styled } from '@mui/system';

export const ButtonEdit = styled('button')({
  backgroundColor: 'transparent',
  color: '#000',
  border: 'none',
  fontSize: '18px',
  lineHeight: '20px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#000',
  },
  cursor: 'pointer',
});
